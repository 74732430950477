<h2 mat-dialog-title>
  <fa-icon icon="pen-fancy"></fa-icon>
  Link Content
</h2>
<form
  id="contentref_form"
  mat-dialog-content
  (ngSubmit)="submit()"
  novalidate
  autocomplete="disabled"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <div [formGroup]="form" xField>
        <ng-container formArrayName="refs">
          <div *ngFor="let control of refs.controls; let index = index">
            <mat-form-field
              appearance="fill"
              [formGroupName]="index"
              *ngIf="control.get('locale')?.value as locale"
            >
              <mat-label>{{ locale }}</mat-label>
              <x-model-autocomplete
                formControlName="ref"
                xToolbarAction
                [dataSource]="datasource"
                [arguments]="{
                  contentType: data.contentType,
                  locale: this.locale
                }"
                [transformer]="transformer"
                [displayWith]="datasource.displayFn"
                placeholder="Search content refs..."
              ></x-model-autocomplete>
            </mat-form-field>
          </div>
        </ng-container>
      </div>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button mat-button type="submit" color="primary" form="contentref_form">Done</button>
</div>
