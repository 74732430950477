import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ParcelOptionItemFragmentDoc } from './parcel-option.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ShipmentParcelDetailFragment = { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined, option?: { __typename: 'ParcelOptionObject', id: number, name: string, code: string } | null | undefined };

export type ShipmentParcelItemFragment = { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined };

export type ShipmentParcelByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ShipmentParcelByIdQuery = { __typename: 'Query', shipmentParcel: { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined, option?: { __typename: 'ParcelOptionObject', id: number, name: string, code: string } | null | undefined } };

export type CreateOrderShipmentParcelMutationVariables = Types.Exact<{
  input: Types.CreateOrderShipmentParcelInput;
}>;


export type CreateOrderShipmentParcelMutation = { __typename: 'Mutation', createOrderShipmentParcel: { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined } };

export type CreateShipmentParcelMutationVariables = Types.Exact<{
  input: Types.CreateShipmentParcelInput;
}>;


export type CreateShipmentParcelMutation = { __typename: 'Mutation', createShipmentParcel: { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined } };

export type UpdateShipmentParcelMutationVariables = Types.Exact<{
  input: Types.UpdateShipmentParcelInput;
}>;


export type UpdateShipmentParcelMutation = { __typename: 'Mutation', updateShipmentParcel: { __typename: 'ShipmentParcelObject', id: number, name?: string | null | undefined, reference?: string | null | undefined, weight?: number | null | undefined, dimensions?: { __typename: 'DimensionsObject', length: number, height: number, width: number } | null | undefined } };

export type RemoveShipmentParcelMutationVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type RemoveShipmentParcelMutation = { __typename: 'Mutation', removeShipmentParcel: number };

export const ShipmentParcelDetailFragmentDoc = gql`
    fragment ShipmentParcelDetail on ShipmentParcelObject {
  id
  name
  reference
  dimensions {
    length
    height
    width
  }
  weight
  option {
    ...ParcelOptionItem
  }
}
    ${ParcelOptionItemFragmentDoc}`;
export const ShipmentParcelItemFragmentDoc = gql`
    fragment ShipmentParcelItem on ShipmentParcelObject {
  id
  name
  reference
  dimensions {
    length
    height
    width
  }
  weight
}
    `;
export const ShipmentParcelByIdDocument = gql`
    query ShipmentParcelById($id: Int!) {
  shipmentParcel(id: $id) {
    ...ShipmentParcelDetail
  }
}
    ${ShipmentParcelDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShipmentParcelByIdGQL extends Apollo.Query<ShipmentParcelByIdQuery, ShipmentParcelByIdQueryVariables> {
    document = ShipmentParcelByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateOrderShipmentParcelDocument = gql`
    mutation CreateOrderShipmentParcel($input: CreateOrderShipmentParcelInput!) {
  createOrderShipmentParcel(input: $input) {
    ...ShipmentParcelItem
  }
}
    ${ShipmentParcelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateOrderShipmentParcelGQL extends Apollo.Mutation<CreateOrderShipmentParcelMutation, CreateOrderShipmentParcelMutationVariables> {
    document = CreateOrderShipmentParcelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShipmentParcelDocument = gql`
    mutation CreateShipmentParcel($input: CreateShipmentParcelInput!) {
  createShipmentParcel(input: $input) {
    ...ShipmentParcelItem
  }
}
    ${ShipmentParcelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShipmentParcelGQL extends Apollo.Mutation<CreateShipmentParcelMutation, CreateShipmentParcelMutationVariables> {
    document = CreateShipmentParcelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShipmentParcelDocument = gql`
    mutation UpdateShipmentParcel($input: UpdateShipmentParcelInput!) {
  updateShipmentParcel(input: $input) {
    ...ShipmentParcelItem
  }
}
    ${ShipmentParcelItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShipmentParcelGQL extends Apollo.Mutation<UpdateShipmentParcelMutation, UpdateShipmentParcelMutationVariables> {
    document = UpdateShipmentParcelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const RemoveShipmentParcelDocument = gql`
    mutation RemoveShipmentParcel($id: Int!) {
  removeShipmentParcel(id: $id)
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class RemoveShipmentParcelGQL extends Apollo.Mutation<RemoveShipmentParcelMutation, RemoveShipmentParcelMutationVariables> {
    document = RemoveShipmentParcelDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }