import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Timezone } from '@x/common/datetime';
import { CommonValidator } from '@x/common/form';
import { ShippingMethodService } from '@x/ecommerce/domain-client';
import {
  ChannelItemCollectionProvider,
  GeoRegionItemCollectionProvider,
  ProductVariantItemCollectionProvider,
  ShippingCategoryItemCollectionProvider,
  WarehouseItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import {
  CreateShippingMethodInput,
  ShippingCategoryComparison,
  StockType,
} from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type ShippingMethodDialogData = {
  shippingMethodId?: number;
};
export type ShippingMethodDialogResult = {
  id: number;
};

@Component({
  selector: 'x-shipping-method-form-dialog',
  templateUrl: './shipping-method-form-dialog.component.html',
  styleUrls: ['./shipping-method-form-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingMethodDialogComponent implements OnDestroy {
  readonly Providers = {
    ShippingCategoryItemCollectionProvider,
    GeoRegionItemCollectionProvider,
    ProductVariantItemCollectionProvider,
    WarehouseItemCollectionProvider,
    ChannelItemCollectionProvider,
  };
  readonly timezones = Timezone.list();
  private _destroy$ = new Subject<void>();

  providers$ = this.shippingMethodService.fetchShippingProviders();
  costCalculators$ = this.shippingMethodService.fetchShippingCostCalculators();
  labelGenerators$ = this.shippingMethodService.fetchShipmentLabelGenerators();

  formGroup = new FormGroup({
    name: new FormControl<string>('', { validators: [Validators.required], nonNullable: true }),
    enabled: new FormControl<boolean>(true, { nonNullable: true }),
    color: new FormControl<string | null>(null),
    displayName: new FormControl<string | null>(null),
    description: new FormControl<string | null>(null),
    channelIds: new FormControl<number[]>([], { nonNullable: true }),
    provider: new FormControl<string>('noop-shipping', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    costCalculator: new FormControl<string>('noop-shipping-cost-calculator', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    labelGenerator: new FormControl<string>('noop-shipment-label-generator', {
      validators: [Validators.required],
      nonNullable: true,
    }),
    providerConfig: new FormControl<string | null>(null, CommonValidator.json()),
    costCalculatorConfig: new FormControl<string | null>(null, CommonValidator.json()),
    labelGeneratorConfig: new FormControl<string | null>(null, CommonValidator.json()),
    warehouseId: new FormControl<number | null>(null, {
      validators: [Validators.required],
    }),
    slotRequired: new FormControl<boolean>(false, { nonNullable: true }),
    collectionRequired: new FormControl<boolean>(false, { nonNullable: true }),
    groupDestination: new FormControl<boolean>(false, { nonNullable: true }),
    stockType: new FormControl<StockType>(StockType.Physical, Validators.required),
    quoteValidityDuration: new FormControl<string | null>(null),
    shippingCategoryIds: new FormControl<number[]>([], { nonNullable: true }),
    regionIds: new FormControl<number[]>([], { nonNullable: true }),
    costProductVariantId: new FormControl<number | null>(null),
    timezone: new FormControl<string>(Intl.DateTimeFormat().resolvedOptions().timeZone),
    priority: new FormControl<number>(0, { nonNullable: true, validators: [Validators.required] }),
    categoryComparison: new FormControl<ShippingCategoryComparison>(
      ShippingCategoryComparison.IncludeOrNone,
      { validators: Validators.required, nonNullable: true },
    ),
  });

  shippingMethodId: number;
  verb: string = 'Create';

  constructor(
    public dialog: MatDialogRef<ShippingMethodDialogComponent>,
    private shippingMethodService: ShippingMethodService,
    @Inject(MAT_DIALOG_DATA) public data: ShippingMethodDialogData,
  ) {
    if (this.data?.shippingMethodId) {
      this.shippingMethodService
        .fetchDetail(this.data?.shippingMethodId)
        .pipe(
          takeUntil(this._destroy$),
          tap((v) => {
            this.shippingMethodId = v.id;
            this.formGroup.patchValue(
              {
                ...v,
                channelIds: v.channels.map((c) => c.id),
                ...(v.providerConfig && { providerConfig: JSON.stringify(v.providerConfig) }),
                ...(v.costCalculatorConfig && {
                  costCalculatorConfig: JSON.stringify(v.costCalculatorConfig),
                }),
                ...(v.labelGeneratorConfig && {
                  labelGeneratorConfig: JSON.stringify(v.labelGeneratorConfig),
                }),
                warehouseId: v.warehouse.id,
                shippingCategoryIds: v.categories.map((c) => c.id),
                regionIds: v.regions.map((r) => r.id),
                costProductVariantId: v.costProductVariant?.id,
                timezone: v.timezone,
              },
              { emitEvent: false },
            );

            this.formGroup.markAsUntouched();
          }),
        )
        .subscribe();
      this.verb = 'Update';
    }
  }

  submit() {
    this.formGroup.updateValueAndValidity();

    const value = this.formGroup.getRawValue();

    if (this.formGroup.invalid) return;

    const input: CreateShippingMethodInput = {
      ...value,
      warehouseId: value.warehouseId ?? 0,
      costCalculatorConfig: value.costCalculatorConfig
        ? JSON.parse(value.costCalculatorConfig)
        : null,
      providerConfig: value.providerConfig ? JSON.parse(value.providerConfig) : null,
      labelGeneratorConfig: value.labelGeneratorConfig
        ? JSON.parse(value.labelGeneratorConfig)
        : null,
    };

    if (this.shippingMethodId) {
      this.shippingMethodService
        .update({ id: this.shippingMethodId, ...input })
        .pipe(
          takeUntil(this._destroy$),
          tap(({ id }) => this.dialog.close({ id })),
        )
        .subscribe();
    } else {
      this.shippingMethodService
        .create(input)
        .pipe(tap(({ id }) => this.dialog.close({ id })))
        .subscribe();
    }
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
