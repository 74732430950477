import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BrowserModule } from '../browser/browser.module';
import { ResponsiveIfDirective } from './responsive.directive';
import { ResponsiveService } from './responsive.service';

@NgModule({
  imports: [CommonModule, BrowserModule],
  providers: [ResponsiveService],
  declarations: [ResponsiveIfDirective],
  exports: [ResponsiveIfDirective],
})
export class ResponsiveModule {}
