import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Directive,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  TemplateRef,
  Type,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl, UntypedFormControl } from '@angular/forms';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import {
  DataSelectOptionObject,
  DataSelectView,
  DataViewFactoryService,
  IDataCollectionProvider,
  IDataProvider,
  IDataSortModel,
} from '@x/common/data';
import { Operation } from '@x/common/operation';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[xDataAutocompleteOptionDef]',
})
export class DataAutocompleteOptionDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Directive({
  selector: '[xDataAutocompleteTriggerDef]',
})
export class DataAutocompleteTriggerDefDirective {
  constructor(public template: TemplateRef<any>) {}
}

@Component({
  selector: 'x-data-autocomplete',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: 'data-autocomplete.component.html',
  styleUrls: ['data-autocomplete.component.scss'],
  providers: [{ provide: MatFormFieldControl, useExisting: DataAutocompleteComponent }],
  host: {
    class: 'x-data-autocomplete',
  },
})
export class DataAutocompleteComponent<T = any, F = any, A = any>
  implements ControlValueAccessor, MatFormFieldControl<T>, OnDestroy, OnInit
{
  static nextId = 0;

  @HostBinding()
  id = `x-data-autocomplete-${DataAutocompleteComponent.nextId++}`;
  controlType = 'x-data-autocomplete';

  @ContentChild(DataAutocompleteOptionDefDirective)
  optionTemplate?: DataAutocompleteOptionDefDirective;

  @ContentChild(DataAutocompleteTriggerDefDirective)
  triggerTemplate?: DataAutocompleteTriggerDefDirective;

  @ViewChild(MatAutocomplete)
  autocomplete: MatAutocomplete;

  @ViewChild('input')
  inputRef: ElementRef<HTMLInputElement> | null;

  @Input()
  get value() {
    return this._value;
  }
  set value(value: any) {
    this.writeValue(value);
  }

  @Input()
  get placeholder() {
    return this._placeholder;
  }
  set placeholder(plh: string) {
    this._placeholder = plh;
    this.stateChanges.next();
  }

  get empty() {
    return Array.isArray(this._value) ? this._value.length === 0 : !this._value;
  }

  @HostBinding('class.floating')
  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  get required() {
    return this._required;
  }
  set required(req) {
    this._required = coerceBooleanProperty(req);
    this.stateChanges.next();
  }

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean) {
    this.setDisabledState(value);
  }

  get errorState(): boolean {
    return this.ngControl?.errors != null;
  }

  @Input('aria-describedby')
  userAriaDescribedBy: string;

  @Input()
  set provider(provider: Type<IDataCollectionProvider<T, F, A> & IDataProvider<T>>) {
    let view = this.dataViewFactory.resolveSelectionView(provider);
    this._dataView = view;
  }

  @Input()
  set filter(filter: F) {
    if (this._dataView) this._dataView.setFilter(filter);
    this._filter = filter;
  }

  @Input()
  set args(args: A) {
    if (this._dataView) this._dataView.setArgs(args);
    this._args = args;
  }

  @Input()
  set sort(sort: IDataSortModel) {
    if (this._dataView) this._dataView.setSort(sort);
    this._sort = sort;
  }

  @Input()
  multiple = false;

  @Input()
  removable = true;

  @Input()
  separatorKeyCodes: number[] = [ENTER, COMMA];

  @Input()
  debounceTime = 300;

  @HostBinding('class.focused')
  focused = false;

  touched = false;
  stateChanges = new Subject<void>();

  control = new UntypedFormControl();

  _dataView: DataSelectView<T, F, A>;
  _filter: F | null = null;
  _args: A | null = null;
  _sort: IDataSortModel | null = null;

  resolveState$?: Observable<Operation<any>>;
  fetchState$: Observable<Operation<any>>;

  trackById = (i: number, op: DataSelectOptionObject<T>) => op.id;
  compareWith = (o1: DataSelectOptionObject<T>, o2: DataSelectOptionObject<T>): boolean => {
    return o1.id === o2.id;
  };
  displayWith = (o: DataSelectOptionObject<T>) => o.display;

  _placeholder: string;

  _disabled = false;
  private _destroy$ = new Subject<void>();
  private _required = false;
  private _value: any;
  private _onChange: any = () => {};
  private _onTouch = () => {};

  constructor(
    private _elementRef: ElementRef,
    @Optional() @Self() public ngControl: NgControl | null,
    private readonly dataViewFactory: DataViewFactoryService,
    private readonly changeRef: ChangeDetectorRef,
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.optionTemplate?.template.elementRef.nativeElement;

    if (!this._dataView) {
      throw new Error('DataView unset on DataSelectComponent');
    }
    if (this._filter) this._dataView.setFilter(this._filter);

    this._dataView.connect();

    const textChanges$ = this.control.valueChanges.pipe(
      debounceTime(this.debounceTime),
      filter((value) => !this._disabled && typeof value === 'string' && value.length > 0),
    );

    textChanges$.subscribe((t) => this._dataView.setSearchText(t));

    this.fetchState$ = this._dataView.fetchState();
    this.resolveState$ = this._dataView.resolveState();

    this._dataView
      .stateChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.changeRef.markForCheck());
  }

  ngOnDestroy() {
    if (this._dataView) {
      this._dataView.disconnect();
    }

    this.stateChanges.next();
    this.stateChanges.complete();

    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
   * Model -> View change
   */
  writeValue(obj: any): void {
    this._value = obj;

    if (Array.isArray(obj) || typeof obj === 'string' || typeof obj === 'number') {
      this._dataView.selectManyById(Array.isArray(obj) ? obj : [obj]);
    } else {
      this._dataView.clearSelection();
    }

    this.stateChanges.next();
  }

  onOptionSelected(value: DataSelectOptionObject) {
    if (this._disabled) return;
    if (this.inputRef) this.inputRef.nativeElement.value = '';

    this._dataView.select(value, this.multiple ? false : true);

    const selected = this._dataView.getSelectedIds();
    this._value = this.multiple ? (selected.length > 0 ? selected : null) : value.id;

    this._onChange(this._value);
    this._onTouch();

    this._dataView.resetResolveState();
    this.stateChanges.next();
  }

  onOptionRemove(value: DataSelectOptionObject) {
    if (this._disabled) return;
    this._dataView.deselect(value);
    const selected = this._dataView.getSelectedIds();
    this._value = this.multiple ? (selected.length > 0 ? selected : null) : null;
    this._onChange(this._value);
    this._onTouch();
    this.focusInput();
    this.stateChanges.next();
  }

  onFocusIn(event: FocusEvent) {
    if (this._disabled) return;
    if (!this.focused) {
      if (this.inputRef) this.inputRef.nativeElement.select();
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (!this._elementRef.nativeElement.contains(event.relatedTarget as Element)) {
      if (this.inputRef) this.inputRef.nativeElement.value = '';
      this.touched = true;
      this.focused = false;
      this._onTouch();
      this.stateChanges.next();
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this._disabled = isDisabled;
    if (this.inputRef) {
      this.inputRef.nativeElement.disabled = isDisabled;
      this.inputRef.nativeElement.readOnly = isDisabled;
    }

    if (isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    this.stateChanges.next();
  }

  setDescribedByIds(ids: string[]) {
    // const controlElement = this._elementRef.nativeElement
    //   .querySelector('.example-tel-input-container')!;
    // controlElement.setAttribute('aria-describedby', ids.join(' '));
  }

  onContainerClick(event: MouseEvent) {
    if (this._disabled) return;
    if ((event.target as Element).tagName.toLowerCase() != 'input') {
      this.focusInput();
    }
  }

  private focusInput() {
    this._elementRef.nativeElement.querySelector('input').focus();
  }
}
