<full-calendar [options]="calendarOptions" [events]="events" #calendar>
  <ng-template #eventContent let-obj>
    @if (getSlotView(obj); as slot) {
      <div
        class="shipping-slot-event"
        [ngClass]="{ disabled: !slot.enabled, unavailable: slot.availability.isAvailable }"
      >
        <xd-shape [color]="slot.method.color ?? 'default'"></xd-shape>
        <div class="event-box" [ngClass]="{ selected: this.view.active === slot.id }">
          <div class="method">
            @if (!slot.enabled) {
              <fa-icon icon="lock"></fa-icon>
            } @else if (slot.overrides?.length) {
              <fa-icon icon="pen"></fa-icon>
            }
            {{ slot.schedule?.name }}
          </div>
          <div class="time">
            <x-datetime
              [ngClass]="{ override: isOverride(slot, 'startAt') }"
              [datetime]="slot.startAt"
              [zone]="slot.method.timezone"
              format="shortTime"
            ></x-datetime>
            <span>-</span>
            <x-datetime
              [ngClass]="{ override: isOverride(slot, 'endAt') }"
              [datetime]="slot.endAt"
              [zone]="slot.method.timezone"
              format="shortTime"
            ></x-datetime>
          </div>
          <div class="availability">
            @if (slot.availability.isAvailable) {
              <fa-icon icon="circle"></fa-icon>
            } @else {
              @switch (slot.availability.unavailableReason) {
                @case ('DISABLED') {
                  <fa-icon icon="lock"></fa-icon>
                }
                @case ('PAST') {
                  <fa-icon icon="clock-rotate-left"></fa-icon>
                }
                @case ('LEAD_TIME') {
                  <fa-icon icon="hourglass-clock"></fa-icon>
                }
                @case ('LAG_TIME') {
                  <fa-icon icon="hourglass-clock"></fa-icon>
                }
                @case ('NO_CAPACITY') {
                  <fa-icon icon="boxes-stacked"></fa-icon>
                }
                @case ('REGION_MISMATCH') {
                  <fa-icon icon="location-xmark"></fa-icon>
                }
              }
            }
            <span class="available-count">
              {{ slot.availability.usedCapacity }}
              /
              <span [ngClass]="{ override: isOverride(slot, 'capacity') }">
                {{ slot.capacity }}
              </span>
            </span>
          </div>
          <div class="state-counts">
            @for (bar of slot.bars; track $index) {
              <div
                class="bar"
                [ngClass]="'x-shipment-state-' + bar.state"
                [style.width]="bar.width"
              ></div>
            }
          </div>
        </div>
      </div>
    } @else {
      <span>Missing slot data</span>
    }
  </ng-template>
</full-calendar>
