import { Injectable } from '@angular/core';
import {
  ChannelPageService,
  ContentNotFoundException,
  IChannelPageDetailContent,
  ILandingPageDetailContent,
  IMembershipPageDetailContent,
  IProductPageDetailContent,
  IShopNavigationItemContent,
  ITaxonPageDetailContent,
  LandingPageService,
  MembershipPageService,
  ProductPageService,
  TaxonPageService,
} from '@x/content/client';
import { PublicationState } from '@x/schemas/content';
import { Observable } from 'rxjs';
import { SubscriptionDetailService } from './subscription-detail.service';
import { ISubscriptionDetailContent } from './types/subscription-detail.types';

interface IContentProviderRefConstruct {
  providerId: string;
  contentRef: string;
}

interface IContentRefConstruct {
  contentType: string;
  id: string;
  status: PublicationState;
}

type ContentRefType =
  | IProductPageDetailContent
  | IChannelPageDetailContent
  | IShopNavigationItemContent[]
  | ITaxonPageDetailContent
  | ILandingPageDetailContent
  | ISubscriptionDetailContent
  | IMembershipPageDetailContent;

@Injectable()
export class ContentRefService {
  constructor(
    private productPageService: ProductPageService,
    private channelPageService: ChannelPageService,
    private taxonPageService: TaxonPageService,
    private landingPageService: LandingPageService,
    private membershipPageService: MembershipPageService,
    private subscriptionDetailService: SubscriptionDetailService,
  ) {}

  fetchRef<T = ContentRefType>(ref: string, locale: string) {
    const { contentRef, providerId } = this.parseRef(ref);
    const { id, contentType, status } = this.deconstructRef(contentRef);

    switch (contentType) {
      case 'product-pages':
        return this.castResult<T>(this.productPageService.fetchDetail(id, locale));
      case 'channel-pages':
        return this.castResult<T>(this.channelPageService.fetchDetail(id, locale));
      case 'taxon-pages':
        return this.castResult<T>(this.taxonPageService.fetchDetail(id, locale));
      case 'landing-pages':
        return this.castResult<T>(this.landingPageService.fetchDetail(id, locale, status));
      case 'membership-pages':
        return this.castResult<T>(this.membershipPageService.fetchDetail(id, locale));
      case 'subscription-pages':
        return this.castResult<T>(this.subscriptionDetailService.fetchDetail(id, locale));
    }

    throw new ContentNotFoundException(
      `ContentRef for provider '${providerId}' with contentType '${contentType}', id '${id}', locale '${locale}' not found.`,
    );
  }

  private castResult<T = ContentRefType>(detail: Observable<ContentRefType>): Observable<T> {
    return detail as Observable<T>;
  }

  private parseRef(ref: string): IContentProviderRefConstruct {
    const split = ref.split(':');
    return {
      providerId: split[0],
      contentRef: split[1],
    };
  }

  deconstructRef(ref: string): IContentRefConstruct {
    const split = ref.split('/');

    return {
      contentType: split[0],
      id: split[1],
      status: split[2] ? (split[2].toUpperCase() as PublicationState) : PublicationState.Live,
    };
  }
}
