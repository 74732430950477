<button mat-icon-button (click)="previous()">
  <fa-icon icon="chevron-left" matTooltip="Previous Month"></fa-icon>
</button>
@if (mode === 'YMD') {
  <mat-select class="select-day" [formControl]="dayControl">
    @for (day of days; track day) {
      <mat-option [value]="day">{{ day }}</mat-option>
    }
  </mat-select>
}
@if (mode === 'YM' || mode === 'YMD') {
  <mat-select class="select-month" [formControl]="monthControl">
    @for (month of months; track month.value) {
      <mat-option [value]="month.value">{{ month.label }}</mat-option>
    }
  </mat-select>
}
<mat-select class="select-year" [formControl]="yearControl">
  @for (year of years; track $index) {
    <mat-option [value]="year">{{ year }}</mat-option>
  }
</mat-select>
<button mat-icon-button xToolbarAction (click)="next()">
  <fa-icon icon="chevron-right" matTooltip="Next Month"></fa-icon>
</button>
