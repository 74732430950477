<mat-tab-group xPanelTab *xDataView="detailView; let view; let slot = data">
  <mat-tab label="Slot">
    <ng-container *matTabContent>
      <x-dashboard-panel [panelLoading]="view.fetchLoading" [panelError]="view.fetchError">
        <div xPanelContent *ngIf="slot">
          <mat-expansion-panel expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <fa-icon size="lg" icon="bullhorn"></fa-icon>
                &nbsp;Slot Info
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <table class="xd-table slot-info-table">
                <tbody>
                  <tr>
                    <th>ID</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'id') }">{{ slot.id }}</td>
                  </tr>
                  <tr>
                    <th>Method</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'name') }">{{ slot.method.name }}</td>
                  </tr>
                  <tr>
                    <th>Schedule</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'schedule') }">
                      {{ slot.schedule?.name }}
                    </td>
                  </tr>
                  <tr>
                    <th>Priority</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'priority') }">{{ slot.priority }}</td>
                  </tr>
                  <tr>
                    <th>Capacity</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'capacity') }">{{ slot.capacity }}</td>
                  </tr>
                  <tr>
                    <th>Start Time</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'startAt') }">
                      <x-datetime
                        [datetime]="slot.startAt"
                        format="timestamp"
                        [zone]="slot.method.timezone"
                      ></x-datetime>
                    </td>
                  </tr>
                  <tr>
                    <th>End Time</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'endAt') }">
                      <x-datetime
                        [datetime]="slot.endAt"
                        format="timestamp"
                        [zone]="slot.method.timezone"
                      ></x-datetime>
                    </td>
                  </tr>
                  <tr>
                    <th>Lead Time</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'leadTime') }">
                      {{ slot.leadTime | duration }}
                    </td>
                  </tr>
                  <tr>
                    <th>Lag Time</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'lagTime') }">
                      {{ slot.lagTime | duration }}
                    </td>
                  </tr>
                  <tr>
                    <th>Enabled</th>
                    <td [ngClass]="{ bold: isOverride(slot, 'enabled') }">
                      {{ slot.enabled ? 'Yes' : 'No' }}
                    </td>
                  </tr>
                  @if (slot.overrides?.length) {
                    <tr>
                      <th>Overrides</th>
                      <td>
                        {{ slot.overrides | join: ',' }}
                      </td>
                    </tr>
                  }
                  <tr>
                    <th>Available From</th>
                    <td>
                      @if (slot.availableFrom) {
                        <x-datetime
                          [datetime]="slot.availableFrom"
                          format="timestamp"
                          [zone]="slot.method.timezone"
                        ></x-datetime>
                      } @else {
                        <span>Any Date</span>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Available To</th>
                    <td>
                      @if (slot.availableTo) {
                        <x-datetime
                          [datetime]="slot.availableTo"
                          format="timestamp"
                          [zone]="slot.method.timezone"
                        ></x-datetime>
                      } @else {
                        <span>Any Date</span>
                      }
                    </td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </mat-expansion-panel>
          <mat-divider></mat-divider>

          <mat-expansion-panel expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <fa-icon size="lg" icon="bullhorn"></fa-icon>
                &nbsp;Shipments
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <table class="xd-table">
                <thead>
                  <tr>
                    <th></th>
                    <th>State</th>
                    <th>Count</th>
                  </tr>
                </thead>
                <tbody>
                  @for (stat of slot.shipmentStateCounts; track $index) {
                    <tr>
                      <td>
                        <xd-shape [ngClass]="'x-shipment-state-' + stat.state"></xd-shape>
                      </td>
                      <td>
                        {{ 'ShipmentState.' + stat.state | l }}
                      </td>
                      <td>
                        {{ stat.count }}
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            </ng-template>
          </mat-expansion-panel>
          <mat-divider></mat-divider>

          <mat-expansion-panel expanded="true" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <fa-icon size="lg" icon="bullhorn"></fa-icon>
                &nbsp;Availability
              </mat-panel-title>
            </mat-expansion-panel-header>

            <ng-template matExpansionPanelContent>
              <table class="xd-table">
                <tbody>
                  <tr>
                    <th>Available</th>
                    <td>{{ slot.availability.isAvailable ? 'Yes' : 'No' }}</td>
                  </tr>
                  @if (!slot.availability.isAvailable) {
                    <tr>
                      <th>Unavailable Reason</th>
                      <td>{{ slot.availability.unavailableReason }}</td>
                    </tr>
                  }
                  <tr>
                    <th>Available Capacity</th>
                    <td>{{ slot.availability.availableCapacity }}</td>
                  </tr>
                  <tr>
                    <th>Used Capacity</th>
                    <td>{{ slot.availability.usedCapacity }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </mat-expansion-panel>
          <mat-divider></mat-divider>
        </div>

        <div xPanelFooter *ngIf="slot">
          <x-action-row>
            <button
              xActionStart
              mat-flat-button
              color="accent"
              (click)="openShippingScheduleUpdateDialog(slot.method.id, slot.schedule?.id)"
            >
              Edit Schedule
            </button>
            <button
              xActionStart
              mat-flat-button
              color="primary"
              (click)="openShippingSlotUpdateDialog(slot)"
            >
              Edit Slot
            </button>
          </x-action-row>
        </div>

        <mat-progress-bar mode="indeterminate" *ngIf="view.fetchLoading"></mat-progress-bar>
      </x-dashboard-panel>
    </ng-container>
  </mat-tab>
  <mat-tab label="Shipments">
    <ng-container *matTabContent>
      <x-dashboard-panel
        [panelLoading]="ordersView.fetchLoading"
        [panelError]="ordersView.fetchError"
      >
        <x-data-table [view]="ordersView" [selectable]="false">
          <div
            *xDataColumnDef="'number'; let row"
            [routerLink]="['/admin/orders/', row.id, 'detail']"
          >
            {{ row.number ?? 'ID' + row.id }}
          </div>
          <div *xDataColumnDef="'state'; let row">
            {{ 'ShipmentState.' + row.shipment.state | l }}
          </div>
        </x-data-table>

        <div xPanelFooter>
          <x-data-table-controls
            [view]="ordersView"
            [columns]="false"
            [sort]="false"
          ></x-data-table-controls>
        </div>
      </x-dashboard-panel>
    </ng-container>
  </mat-tab>
</mat-tab-group>
