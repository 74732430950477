import { NgModule } from '@angular/core';
import { DashboardMenuModule } from '@x/dashboard/menu';

@NgModule({
  imports: [
    DashboardMenuModule.forChild([
      {
        id: 'logistics',
        title: 'Logistics',
        icon: 'truck-fast',
        position: 4,
      },
      {
        id: 'logistics.shipments',
        title: 'Shipments',
        route: '/admin/logistics/shipments',
        icon: 'boxes-stacked',
        parentId: 'logistics',
        permissions: ['logistics_read'],
      },
      {
        id: 'logistics.shipping_slots',
        title: 'Shipping Calendar',
        route: '/admin/logistics/shipping-slots',
        icon: 'calendar',
        parentId: 'logistics',
        permissions: ['logistics_read'],
      },
      {
        id: 'logistics.shipping_methods',
        title: 'Shipping Methods',
        route: '/admin/logistics/shipping-methods',
        icon: 'truck-fast',
        parentId: 'logistics',
        permissions: ['logistics_read'],
      },
      {
        id: 'logistics.shipping_categories',
        title: 'Shipping Categories',
        route: '/admin/logistics/shipping-categories',
        icon: 'truck-plane',
        parentId: 'logistics',
        permissions: ['logistics_read'],
      },
      {
        id: 'logistics.parcel_options',
        title: 'Parcel Options',
        route: '/admin/logistics/parcel-options',
        icon: 'box-open',
        parentId: 'logistics',
        permissions: ['logistics_read'],
      },
    ]),
  ],
})
export class LogisticsMenuModule {}
