<div class="pill-icon">
  <x-country-flag *ngIf="address?.country as code; else defaultIcon" [code]="code"></x-country-flag>
  <ng-template #defaultIcon>
    <fa-icon icon="map-location"></fa-icon>
  </ng-template>
</div>
<div class="pill-body" *ngIf="address; else unassigned">
  @if (address.complex || address.businessName) {
    <div class="pill-line">
      {{ [address.businessName, address.complex] | join }}
    </div>
  }
  @if (address.street || address.suburb) {
    <div class="pill-line">
      {{ [address.street, address.suburb] | join }}
    </div>
  }
  @if (address.postalCode || address.city) {
    <div class="pill-line">
      {{ [address.postalCode, address.city] | join }}
    </div>
  }
  <div class="pill-line">
    {{ [address.province, address.country] | join }}
  </div>
  @if (address.firstName || address.lastName) {
    <div class="pill-line">
      <fa-icon class="pill-line-icon" icon="person"></fa-icon>
      <span>{{ address.firstName }} {{ address.lastName }}</span>
    </div>
  }
  @if (address.phoneNumber) {
    <div class="pill-line">
      <fa-icon class="pill-line-icon" icon="phone"></fa-icon>
      <span>{{ address.phoneNumber }}</span>
    </div>
  }
  @if (region) {
    <div class="pill-line">
      <fa-icon class="pill-line-icon" icon="map-location-dot"></fa-icon>
      <span>{{ region.name }}</span>
    </div>
  }
  <!-- <div class="pill-line">
    <span *ngIf="address.email">{{ address.email }}</span>
  </div> -->
</div>
<ng-template #unassigned>
  <div class="pill-body">Unassigned</div>
</ng-template>
