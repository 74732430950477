<x-data-table [view]="view" [selectable]="false">
  <ng-template xDataColumnDef="id" let-row>
    <div class="mat-body-2">{{ row.id }}</div>
    <div class="mat-caption">{{ row.schedule?.name ?? 'Custom' }}</div>
  </ng-template>

  <ng-template xDataColumnDef="reference" let-row>
    <div class="mat-body-2">
      {{ row.method.name }}
    </div>
    <div class="mat-caption">{{ row.schedule?.name ?? '' }}</div>
  </ng-template>

  <ng-template xDataColumnDef="capacity" let-row>
    <div class="mat-body-2">{{ row.availability.usedCapacity }} / {{ row.capacity }}</div>
  </ng-template>

  <ng-template xDataColumnDef="regions" let-row>
    <x-pill-group *ngIf="row.regions?.length">
      <x-geo-region-pill *ngFor="let region of row.regions" [region]="region"></x-geo-region-pill>
    </x-pill-group>
    <span *ngIf="!row.regions?.length">* All regions</span>
  </ng-template>

  <ng-template xDataColumnDef="isAvailable" let-row>
    <x-pill [color]="row.availability.isAvailable ? 'primary' : 'warn'">
      {{ row.availability.isAvailable ? '&check;' : '&cross;' }}
      {{ row.availability.isAvailable ? '' : (row.availability.unavailableReason | titlecase) }}
    </x-pill>
  </ng-template>

  <ng-template xDataColumnDef="priority" let-row>
    <div class="mat-body-2">{{ row.priority }}</div>
  </ng-template>

  <ng-template xDataColumnDef="leadTime" let-row>
    <div class="mat-body-2">{{ row.leadTime | duration }}</div>
  </ng-template>

  <ng-template xDataColumnDef="interval" let-row>
    <div class="mat-body-2">
      {{ row.startAt | datetime: 'EEE, MMM d, y' : row.method.timezone }},
      {{ row.startAt | datetime: 'T' : row.method.timezone }}
      -
      {{ row.endAt | datetime: 'T' : row.method.timezone }}
    </div>
    <div class="mat-caption" *ngIf="row.leadTime">
      <span>GMT{{ row.startAt | datetime: 'ZZZ' : row.method.timezone }}&nbsp;</span>
      <span>| {{ row.leadTime | duration }} lead time</span>
    </div>
  </ng-template>

  <button
    x-data-button
    label="Edit"
    icon="clipboard-list"
    matTooltip="Edit"
    *xDataAction="'order-detail'; let row; primary: true"
    (click)="openShippingSlotUpdateDialog(row)"
  ></button>
</x-data-table>
