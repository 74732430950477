import { LanguageDeclaration } from '@x/common/locale';

export const SHIPPING_METHOD_LANGUAGE: LanguageDeclaration = {
  shippingMethod: {
    index: {
      title: 'Shipping Methods',
      subtitle: '',
      column: {
        name: 'Name',
        channels: 'Channels',
        provider: 'Shipping Provider',
        costCalculator: 'Shipping Cost Calculator',
        labelGenerator: 'Label generator',
        timestamps: 'Timestamps',
      },
      sort: {
        name: 'Shipping Method Name',
        code: 'Shipping Method Code',
        provider: 'Shipping Provider',
        costCalculator: 'Shipping Cost Calculator',
        labelGenerator: 'Label generator',
        createdAt: 'Shipping Method Created Date',
        updatedAt: 'Shipping Method Updated Date',
      },
      action: {
        edit: 'Edit Shipping Method',
        enableChannel: 'Enabled Channel',
        disableChannel: 'Disable Channel',
      },
    },
    detail: {
      prompt: {
        noupdate: {
          title: 'Nothing to update',
          message: '',
          okay: 'Okay',
        },
        update: {
          title: 'Success',
          message: 'Warehouse has been successfully updated.',
          okay: 'Okay',
        },
      },
    },
  },
};
