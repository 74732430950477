<div class="toolbar-header">
  @if (icon) {
    <fa-icon class="toolbar-icon" [icon]="icon" size="2x"></fa-icon>
  }
  @if (header) {
    <h2 class="toolbar-title">{{ header }}</h2>
  }
  <div *ngIf="(icon || header) && subheader" class="toolbar-title-divider"></div>
  @if (subheader) {
    <h3 *ngIf="subheader" class="toolbar-subtitle">{{ subheader }}</h3>
  }
  <ng-content select="[xToolbarHeading]"></ng-content>
</div>
<ng-content></ng-content>
<div class="toolbar-actions">
  <ng-content select="[xToolbarAction]"></ng-content>
</div>
