import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, ModuleWithProviders, NgModule } from '@angular/core';
import { DEFAULT_COOKIE_OPTIONS, IBrowserModuleConfig } from '@x/common/browser/browser.config';
import { CookieService } from '@x/common/browser/cookie.service';
import { ScriptInjectorService } from '@x/common/browser/script-injector.service';
import { DownloadService } from './download.service';
import { WindowRef } from './window.service';

@NgModule({
  imports: [CommonModule],
  providers: [WindowRef, DownloadService, CookieService, ScriptInjectorService],
})
export class BrowserModule {
  static forRoot(config: IBrowserModuleConfig): ModuleWithProviders<BrowserModule> {
    return {
      ngModule: BrowserModule,
      providers: [
        {
          provide: DEFAULT_COOKIE_OPTIONS,
          useValue: config.cookies,
        },
        {
          provide: APP_INITIALIZER,
          useFactory: (scriptInjector: ScriptInjectorService) => () =>
            scriptInjector.loadScripts(config.scripts),
          deps: [ScriptInjectorService],
          multi: true,
        },
      ],
    };
  }
}
