import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DatetimeFormatter } from '@x/common/datetime';
import { DateTime } from 'luxon';
import { Subscription } from 'rxjs';

@Component({
  selector: 'x-date-rotor',
  templateUrl: './date-rotor.component.html',
  styleUrl: './date-rotor.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'x-date-rotor',
  },
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateRotorComponent,
    },
  ],
})
export class DateRotorComponent implements OnInit, ControlValueAccessor {
  now = DateTime.now();

  yearControl = new FormControl<number>(this.now.year);
  monthControl = new FormControl<number | null>(this.now.month);
  dayControl = new FormControl<number | null>(this.now.day);

  private formGroup = new FormGroup({
    year: this.yearControl,
    month: this.monthControl,
    day: this.dayControl,
  });

  @Input()
  mode: 'YMD' | 'YM' | 'Y' = 'YMD';

  readonly months = this.dateFormatter.getMonthList();
  readonly years = this.dateFormatter.getRelativeYearList(-10, 10);
  days = this.dateFormatter.getDaysInMonth(this.now.year, this.now.month);

  @HostBinding()
  colorClass: string;

  private changeSubscription: Subscription;
  private _onChange: (_: any) => void = () => {};
  private _onTouch: (_: any) => void = () => {};

  constructor(
    private changeRef: ChangeDetectorRef,
    private dateFormatter: DatetimeFormatter,
  ) {}

  writeValue(obj: any): void {
    if (typeof obj === 'string') {
      this.patchFromDateTime(DateTime.fromISO(obj), false);
    } else {
      this.patchFromDateTime(this.now, false);
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.formGroup.disable({ emitEvent: false });
    else this.formGroup.enable({ emitEvent: false });
  }

  ngOnInit() {
    this.changeSubscription = this.formGroup.valueChanges.subscribe(() => {
      const dt = this.getDateTime();
      let formatted = '';
      switch (this.mode) {
        case 'YMD':
          formatted = dt.toFormat('yyyy-MM-dd');
          break;
        case 'YM':
          formatted = dt.toFormat('yyyy-MM');
          break;
        case 'Y':
          formatted = dt.toFormat('yyyy');
          break;
      }

      this._onChange(formatted);
    });
  }

  next() {
    let date = this.getDateTime();

    switch (this.mode) {
      case 'YMD':
        date = date.plus({ day: 1 });
        break;
      case 'YM':
        date = date.plus({ month: 1 });
        break;
      case 'Y':
        date = date.plus({ year: 1 });
        break;
    }

    this.patchFromDateTime(date);
  }

  previous() {
    let date = this.getDateTime();

    switch (this.mode) {
      case 'YMD':
        date = date.minus({ day: 1 });
        break;
      case 'YM':
        date = date.minus({ month: 1 });
        break;
      case 'Y':
        date = date.minus({ year: 1 });
        break;
    }

    this.patchFromDateTime(date);
  }

  private getDateTime(): DateTime<true> {
    const year = this.yearControl.value ?? this.now.year;
    const month = this.monthControl.value ?? this.now.month;
    const day = this.dayControl.value ?? this.now.day;

    const date = DateTime.fromObject({ year, month, day });

    if (!date.isValid) {
      throw new Error(`${year}-${month}-${day} is not a valid date`);
    }

    return date;
  }

  private patchFromDateTime(date: DateTime, emitEvent = true) {
    const { year, month, day } = date;

    switch (this.mode) {
      case 'YMD':
        this.days = this.dateFormatter.getDaysInMonth(year, month);
        this.formGroup.patchValue(
          {
            year,
            month,
            day,
          },
          { emitEvent },
        );
        break;
      case 'YM':
        this.formGroup.patchValue(
          {
            year,
            month,
            day: null,
          },
          { emitEvent },
        );
        break;
      case 'Y':
        this.formGroup.patchValue(
          {
            year,
            month: null,
            day: null,
          },
          { emitEvent },
        );
        break;
    }
  }
}
