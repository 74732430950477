import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataTableView, DataView } from '@x/common/data';
import { IOrderRowObject, IShippingSlotDetailObject } from '@x/ecommerce/domain-client';
import { OrderFilterInput } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { ShippingScheduleDialogService } from '../../services/shipping-schedule-dialog.service';
import { ShippingSlotDialogService } from '../../services/shipping-slot-dialog.service';

@Component({
  selector: 'x-shipping-slot-quick-view',
  templateUrl: './shipping-slot-quick-view.component.html',
  styleUrls: ['./shipping-slot-quick-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingSlotQuickViewComponent {
  @Input()
  detailView: DataView<IShippingSlotDetailObject, string>;

  @Input()
  ordersView: DataTableView<IOrderRowObject, OrderFilterInput, any, number>;

  @Output()
  updated = new EventEmitter<void>();

  private destroy$ = new Subject<void>();

  constructor(
    private scheduleDialogService: ShippingScheduleDialogService,
    private slotDialogService: ShippingSlotDialogService,
  ) {}

  openShippingScheduleUpdateDialog(shippingMethodId: number, shippingScheduleId?: number) {
    this.scheduleDialogService
      .openShippingScheduleDialog({ shippingScheduleId, shippingMethodId })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        this.detailView.refresh();
        this.updated.emit();
      });
  }

  openShippingSlotUpdateDialog(slot: IShippingSlotDetailObject) {
    this.slotDialogService
      .openShippingSlotDialog({ shippingSlotId: slot.id, timezone: slot.method.timezone })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        this.detailView.refresh();
        this.updated.emit();
      });
  }

  isOverride(slot: IShippingSlotDetailObject, key: string): boolean {
    return slot.overrides?.includes(key) ?? false;
  }
}
