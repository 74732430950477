import { inject, Inject, Injectable } from '@angular/core';
import { InjectLogger, Logger } from '@x/common/log';
import { Observable, OperatorFunction } from 'rxjs';
import { tap } from 'rxjs/operators';
import {
  DEFAULT_ERROR_TRANSLATOR,
  DEFAULT_TRANSLATED_ERROR,
  ERROR_TRANSLATOR,
  ErrorTranslator,
  TranslatedError,
} from './error-translation';

export function tapTranslatedError<A>(
  callback: (error: TranslatedError) => void,
): OperatorFunction<A, A> {
  const service = inject(ErrorTranslatorService);

  return function <T>(source: Observable<T>): Observable<T> {
    return source.pipe(
      tap({
        error: (error) => {
          callback(service.translateError(error));
        },
      }),
    );
  };
}

@Injectable({ providedIn: 'root' })
export class ErrorTranslatorService {
  @InjectLogger()
  log: Logger;

  constructor(
    @Inject(ERROR_TRANSLATOR)
    private translators: ErrorTranslator[],
    @Inject(DEFAULT_ERROR_TRANSLATOR)
    private defaultTranslator: ErrorTranslator,
  ) {}

  translateError(error: any): TranslatedError {
    try {
      const translator = this.getTranslationProviderForError(error);
      const translated = translator.translateError(error);
      this.log.debug('Translated error %o', translated);

      if (translated.error === undefined) translated.error = error;
      if (translated.detail === undefined) {
        try {
          translated.detail = JSON.stringify(error);
        } catch (e) {
          translated.detail = `Unable to JSON.stringify error detail for error: ${error}`;
        }
      }

      return translated;
    } catch (e) {
      this.log.error('Error translating error', e);
    }
    return DEFAULT_TRANSLATED_ERROR;
  }

  getTranslationProviderForError(error: any): ErrorTranslator {
    return this.translators.find((t) => t.canTranslate(error)) || this.defaultTranslator;
  }
}
