import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { DataTableView } from '@x/common/data';
import { IShippingSlotRowObject } from '@x/ecommerce/domain-client';
import { ShippingSlotFilterInput } from '@x/schemas/ecommerce';
import { ShippingSlotDialogService } from '../../services/shipping-slot-dialog.service';

@Component({
  selector: 'x-shipping-slot-table',
  templateUrl: './shipping-slot-table.component.html',
  styleUrls: ['./shipping-slot-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingSlotTableComponent implements OnChanges {
  @Input()
  view: DataTableView<IShippingSlotRowObject, ShippingSlotFilterInput, any, string>;

  @Input()
  timezone = 'UTC';

  @Output()
  valueUpdated = new EventEmitter<boolean>();

  constructor(private readonly shippingSlotDialogService: ShippingSlotDialogService) {}

  ngOnChanges() {
    if (this.view) {
      this.view.refresh();
    }
  }

  async openShippingSlotUpdateDialog(slot: IShippingSlotRowObject) {
    this.shippingSlotDialogService
      .openShippingSlotDialog({ shippingSlotId: slot.id, timezone: slot.method.timezone })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        this.view.refresh();
        this.valueUpdated.emit(true);
      });
  }
}
