import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import { CreateShippingSlotInput, UpdateShippingSlotInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateShippingSlotGQL,
  ShippingSlotDetailGQL,
  ShippingSlotItemGQL,
  ShippingSlotItemsGQL,
  ShippingSlotItemsQueryVariables,
  ShippingSlotRowGQL,
  ShippingSlotRowsGQL,
  ShippingSlotRowsQueryVariables,
  UpdateShippingSlotGQL,
} from './gql/shipping-slot.gql.generated';
import {
  IShippingSlotDetailObject,
  IShippingSlotItem,
  IShippingSlotRowObject,
} from './types/shipping-slot';

@Injectable()
export class ShippingSlotService {
  constructor(
    private readonly shippingSlotItemGQL: ShippingSlotItemGQL,
    private readonly shippingSlotItemsGQL: ShippingSlotItemsGQL,
    private readonly shippingSlotRowsGQL: ShippingSlotRowsGQL,
    private readonly shippingSlotRowGQL: ShippingSlotRowGQL,
    private readonly shippingSlotDetailGQL: ShippingSlotDetailGQL,
    private updateGQL: UpdateShippingSlotGQL,
    private createGQL: CreateShippingSlotGQL,
  ) {}

  fetchItem(id: string): Observable<IShippingSlotItem> {
    return this.shippingSlotItemGQL.fetch({ id }).pipe(map((d) => d.data.shippingSlot));
  }

  fetchItems(query: ShippingSlotItemsQueryVariables): Observable<IShippingSlotItem[]> {
    return this.shippingSlotItemsGQL.fetch(query).pipe(map((r) => r.data.shippingSlots));
  }

  fetchRow(id: string): Observable<IShippingSlotRowObject> {
    return this.shippingSlotRowGQL.fetch({ id }).pipe(map((r) => r.data.shippingSlot));
  }

  fetchRows(query: ShippingSlotRowsQueryVariables): Observable<IShippingSlotRowObject[]> {
    return this.shippingSlotRowsGQL.fetch(query).pipe(map((r) => r.data.shippingSlots));
  }

  fetchDetail(id: string): Observable<IShippingSlotDetailObject> {
    return this.shippingSlotDetailGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.shippingSlot));
  }

  update(input: UpdateShippingSlotInput) {
    return this.updateGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShippingSlot));
  }

  create(input: CreateShippingSlotInput) {
    return this.createGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createShippingSlot));
  }
}
