import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormCommonModule } from '@x/common/form/form-common.module';
import { IconModule } from '@x/common/icon';
import { OperationModule } from '@x/common/operation';
import { CalendarDateControlComponent } from '@x/dashboard/form/components/calendar-date-control/calendar-date-control.component';
import { DashboardCoreModule } from '../core';
import { CurrencyInputControlComponent } from './components/currency-input-control/currency-input-control.component';
import {
  DashboardFormLayoutComponent,
  FormPanelDefDirective,
} from './components/dashboard-form-layout/dashboard-form-layout.component';
import {
  DataAutocompleteComponent,
  DataAutocompleteOptionDefDirective,
  DataAutocompleteTriggerDefDirective,
} from './components/data-autocomplete/data-autocomplete.component';
import {
  DataSelectComponent,
  DataSelectOptionDefDirective,
} from './components/data-select/data-select.component';
import { DurationInputControlComponent } from './components/duration-input-control/duration-input-control.component';
import { EnumSelectComponent } from './components/enum-select/enum-select.component';
import { FormPanelComponent } from './components/form-panel/form-panel.component';
import { FormStatusLabelComponent } from './components/form-status-label/form-status-label.component';
import { FormValidityPillComponent } from './components/form-validity-pill/form-validity-pill.component';
import { InputLengthCounterComponent } from './components/input-length-counter/input-length-counter.component';
import { ModelAutocompleteChipsComponent } from './components/model-autocomplete/model-autocomplete-chips.component';
import { ModelAutocompleteOptionDefDirective } from './components/model-autocomplete/model-autocomplete-option-def.directive';
import { ModelAutocompleteTriggerDefDirective } from './components/model-autocomplete/model-autocomplete-trigger-def.directive';
import { ModelAutocompleteComponent } from './components/model-autocomplete/model-autocomplete.component';
import { NumberInputControlComponent } from './components/number-input-control/number-input-control.component';
import { SearchFieldComponent } from './components/search-field/search-field.component';
import { FormGuard } from './form-guard';
import { FileUploadSevice } from './services/file-upload.service';

@NgModule({
  imports: [
    CommonModule,
    DashboardCoreModule,
    MatFormFieldModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatChipsModule,
    ReactiveFormsModule,
    FormsModule,
    FormCommonModule,
    IconModule,
    OperationModule,
    MatTooltipModule,
  ],
  declarations: [
    DashboardFormLayoutComponent,
    FormPanelComponent,
    SearchFieldComponent,
    ModelAutocompleteComponent,
    DurationInputControlComponent,
    CurrencyInputControlComponent,
    NumberInputControlComponent,
    ModelAutocompleteChipsComponent,
    FormPanelDefDirective,
    ModelAutocompleteOptionDefDirective,
    FormStatusLabelComponent,
    DataSelectComponent,
    DataSelectOptionDefDirective,
    DataAutocompleteComponent,
    DataAutocompleteOptionDefDirective,
    EnumSelectComponent,
    DataAutocompleteTriggerDefDirective,
    ModelAutocompleteTriggerDefDirective,
    InputLengthCounterComponent,
    FormValidityPillComponent,
    CalendarDateControlComponent,
  ],
  exports: [
    DashboardFormLayoutComponent,
    FormPanelComponent,
    SearchFieldComponent,
    ModelAutocompleteComponent,
    ModelAutocompleteChipsComponent,
    DurationInputControlComponent,
    CurrencyInputControlComponent,
    NumberInputControlComponent,
    FormPanelDefDirective,
    ModelAutocompleteOptionDefDirective,
    ReactiveFormsModule,
    FormsModule,
    FormStatusLabelComponent,
    FormCommonModule,
    DataSelectComponent,
    DataSelectOptionDefDirective,
    DataAutocompleteComponent,
    DataAutocompleteOptionDefDirective,
    EnumSelectComponent,
    DataAutocompleteTriggerDefDirective,
    ModelAutocompleteTriggerDefDirective,
    InputLengthCounterComponent,
    FormValidityPillComponent,
    CalendarDateControlComponent,
  ],
  providers: [FormGuard, FileUploadSevice],
})
export class DashboardFormModule {}
