import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PromptDialogService } from '@x/dashboard/dialog';
import {
  CollectionPointItemCollectionProvider,
  OrderTagItemCollectionProvider,
  UserItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { OrderCheckoutRequirementsObject } from '@x/schemas/ecommerce';
import { AddressItemCollectionProvider } from 'libs/ecommerce/src/domain-data/providers/address.data-provider';
import {
  BulkOrderCreateDialogComponent,
  BulkOrderCreateDialogData,
  BulkOrderCreateDialogResult,
} from '../components/bulk-order-create-dialog/bulk-order-create-dialog.component';
import {
  CartAdjustmentDialogComponent,
  CartAdjustmentDialogData,
  CartAdjustmentDialogResult,
} from '../components/cart-adjustment-dialog/cart-adjustment-dialog.component';
import {
  OrderCreateDialogComponent,
  OrderCreateDialogData,
  OrderCreateDialogResult,
} from '../components/order-create-dialog/order-create-dialog.component';
import {
  OrderRecreateDialogComponent,
  OrderRecreateDialogData,
  OrderRecreateDialogResult,
} from '../components/order-recreate-dialog/order-recreate-dialog.component';

import {
  IOrderCreateParcelDialogData,
  IOrderCreateParcelDialogResult,
  OrderCreateParcelDialogComponent,
} from '../components/order-create-parcel-dialog/order-create-parcel-dialog.component';
import {
  OrderFulfilmentIntervalDialogComponent,
  OrderFulfilmentIntervalDialogData,
  OrderFulfilmentIntervalDialogResult,
} from '../components/order-fulfilment-interval-dialog/order-fulfilment-interval-dialog.component';
import {
  OrderGiftOptionsDialogComponent,
  OrderGiftOptionsDialogData,
  OrderGiftOptionsDialogResult,
} from '../components/order-gift-options-dialog/order-gift-options-dialog.component';
import {
  OrderInstructionsDialogComponent,
  OrderInstructionsDialogData,
  OrderInstructionsDialogResult,
} from '../components/order-instructions-dialog/order-instructions-dialog.component';
import {
  IOrderParcelFormDialogData,
  IOrderParcelFormDialogResult,
  OrderParcelFormDialogComponent,
} from '../components/order-parcel-form-dialog/order-parcel-form-dialog.component';
import {
  OrderPaymentDialog2Component,
  OrderPaymentDialogData,
} from '../components/order-payment-dialog/order-payment-dialog.component';
import {
  OrderShippingFeeDialogComponent,
  OrderShippingFeeDialogData,
  OrderShippingFeeDialogResult,
} from '../components/order-shipping-fee-dialog/order-shipping-fee-dialog.component';
import {
  OrderTemplateFormDialogComponent,
  OrderTemplateFormDialogData,
  OrderTemplateFormDialogResult,
} from '../components/order-template-form-dialog/order-template-form-dialog.component';

@Injectable()
export class OrderDialogService {
  constructor(
    private dialogs: MatDialog,
    private prompts: PromptDialogService,
  ) {}

  openCartRecreateDialog(data?: OrderRecreateDialogData) {
    return this.dialogs.open<
      OrderRecreateDialogComponent,
      OrderRecreateDialogData,
      OrderRecreateDialogResult
    >(OrderRecreateDialogComponent, { data: data ?? {} });
  }

  openCartCreateDialog(data?: OrderCreateDialogData) {
    return this.dialogs.open<
      OrderCreateDialogComponent,
      OrderCreateDialogData,
      OrderCreateDialogResult
    >(OrderCreateDialogComponent, { data: data ?? {} });
  }

  openOrderCreateParcelDialog() {
    return this.dialogs.open<
      OrderCreateParcelDialogComponent,
      IOrderCreateParcelDialogData,
      IOrderCreateParcelDialogResult
    >(OrderCreateParcelDialogComponent, {
      data: { ...{}, actionText: 'Add' },
      width: '500px',
    });
  }

  openOrderUpdateParcelDialog(parcelData: IOrderCreateParcelDialogData) {
    return this.dialogs.open<
      OrderCreateParcelDialogComponent,
      IOrderCreateParcelDialogData,
      IOrderCreateParcelDialogResult
    >(OrderCreateParcelDialogComponent, {
      data: { ...parcelData, actionText: 'Update' },
      width: '500px',
    });
  }

  openParcelRemoveConfirmation(data: IOrderCreateParcelDialogData) {
    return this.prompts.confirm({
      title: `Remove "${data.name}" Parcel`,
      message: `Are you sure you want to remove this parcel?`,
    });
  }

  openOrderParcelFormDialog(data?: IOrderParcelFormDialogData) {
    return this.dialogs.open<
      OrderParcelFormDialogComponent,
      IOrderParcelFormDialogData,
      IOrderParcelFormDialogResult
    >(OrderParcelFormDialogComponent, {
      data,
    });
  }

  openAssignCustomerDialog(customerId?: number) {
    return this.prompts.autocomplete({
      title: 'Assign Customer',
      message: 'Select a customer to assign.',
      cancelLabel: 'Cancel',
      okLabel: 'Assign',
      provider: UserItemCollectionProvider,
      value: customerId,
    });
  }

  openAssignCustomerAddressDialog(customerId: number, addressId?: number) {
    return this.prompts.select({
      title: 'Assign Customer Address',
      message: 'Select an address to assign.',
      cancelLabel: 'Cancel',
      okLabel: 'Assign',
      provider: AddressItemCollectionProvider,
      value: addressId,
      filter: { userIds: [customerId] },
    });
  }

  openAssignCollectionPointDialog() {
    return this.prompts.autocomplete({
      title: 'Assign Collection Point',
      message: 'Select a collection point to assign.',
      cancelLabel: 'Cancel',
      okLabel: 'Assign',
      provider: CollectionPointItemCollectionProvider,
    });
  }

  openAssignCouponCodeDialog(couponCode?: string | null) {
    return this.prompts.enterText({
      title: 'Assign Coupon Code',
      cancelLabel: 'Cancel',
      okLabel: 'Assign',
      placeholder: 'Coupon code...',
      value: couponCode,
    });
  }

  openAssignReferrerCodeDialog(referrerCode?: string | null) {
    return this.prompts.enterText({
      title: 'Assign Referrer Code',
      cancelLabel: 'Cancel',
      okLabel: 'Assign',
      placeholder: 'Referrer code...',
      value: referrerCode,
    });
  }

  openGiftOptionsDialog(data: OrderGiftOptionsDialogData) {
    return this.dialogs.open<
      OrderGiftOptionsDialogComponent,
      OrderGiftOptionsDialogData,
      OrderGiftOptionsDialogResult
    >(OrderGiftOptionsDialogComponent, { data, width: '500px' });
  }

  openInstructionsDialog(data: OrderInstructionsDialogData) {
    return this.dialogs.open<
      OrderInstructionsDialogComponent,
      OrderInstructionsDialogData,
      OrderInstructionsDialogResult
    >(OrderInstructionsDialogComponent, { data, width: '500px' });
  }

  openFulfilmentIntervalDialog(data: OrderFulfilmentIntervalDialogData) {
    return this.dialogs.open<
      OrderFulfilmentIntervalDialogComponent,
      OrderFulfilmentIntervalDialogData,
      OrderFulfilmentIntervalDialogResult
    >(OrderFulfilmentIntervalDialogComponent, { data, width: '500px' });
  }

  openCheckoutRequirementsWarning(requirements: OrderCheckoutRequirementsObject) {
    let messages = requirements.requirements
      .filter((r) => !r.isValid)
      .map((r) => ` - ${r.message} [${r.code}]`)
      .join('\n');

    return this.prompts.deny({
      title: 'Checkout Requirements Not Met',
      message: `This order does not meet the checkout requirements:\n\n${messages}`,
    });
  }

  openCheckoutConfirmation() {
    return this.prompts.confirmAndCheckbox({
      title: 'Checkout',
      message: `Are you sure you want to checkout / quote this order?
      No further changes of order items and promotions can be applied.`,
      checkboxLabel: 'Order(s) never expire.',
      checked: true,
    });
  }

  openProcessConfirmation() {
    return this.prompts.confirm({
      title: 'Process Order',
      message: `Are you sure you want to attempt processing this order?`,
    });
  }

  openOrderPaymentDialog(data: OrderPaymentDialogData) {
    return this.dialogs.open<OrderPaymentDialog2Component, OrderPaymentDialogData>(
      OrderPaymentDialog2Component,
      { data },
    );
  }

  openAdjustmentDialog(data: CartAdjustmentDialogData) {
    return this.dialogs.open<
      CartAdjustmentDialogComponent,
      CartAdjustmentDialogData,
      CartAdjustmentDialogResult
    >(CartAdjustmentDialogComponent, { data });
  }

  openOrderTagDialog(add = true) {
    return this.prompts.select<number[]>({
      provider: OrderTagItemCollectionProvider,
      okLabel: add ? 'Add' : 'Remove',
      title: add ? 'Add Tags' : 'Remove Tags',
      message: add ? 'Select tags to add' : 'Select tags to remove',
      placeholder: 'Select tags...',
      multiple: true,
    });
  }

  openOrderNotifyConfirmationDialog() {
    return this.prompts.confirm({
      title: 'Send',
      message: 'are you sure you want to send an notification',
    });
  }

  openOrderNoteDialog() {
    return this.prompts.enterText({
      title: 'Add Order Note',
      placeholder: 'Enter message...',
      okLabel: 'Add',
      long: true,
    });
  }

  openOrderShipConfirmation() {
    return this.prompts.prompt<{ sendNotification: boolean } | null>({
      title: 'Ship Order',
      message: 'Would you like to send a shipment notification?',
      actions: [
        { name: 'Cancel', result: null },
        { name: 'Ship Only', result: { sendNotification: false }, color: 'accent' },
        { name: 'Send Notification', result: { sendNotification: true }, color: 'primary' },
      ],
    });
  }

  openOrderShipmentCancelConfirmation({ multiple = true } = {}) {
    return this.prompts.prompt<boolean | null>({
      title: `Cancel Shipment${multiple ? 's' : ''}`,
      message: `Are you sure you want to cancel shipment${multiple ? 's' : ''}?`,
      actions: [
        { name: 'Back', result: null, color: 'accent' },
        { name: `Cancel Shipment${multiple ? 's' : ''}`, result: true, color: 'warn' },
      ],
    });
  }

  openOrderDeliverConfirmation() {
    return this.prompts.confirm({
      title: 'Deliver Order',
      message: 'Would you like deliver the shipment?',
    });
  }

  openOrderShippingFeeDialog(data: OrderShippingFeeDialogData) {
    return this.dialogs.open<
      OrderShippingFeeDialogComponent,
      OrderShippingFeeDialogData,
      OrderShippingFeeDialogResult
    >(OrderShippingFeeDialogComponent, { data });
  }

  openReconConfirmationDialog() {
    return this.prompts.confirm({
      title: 'Recon Orders',
      message: `Are you sure you want to attempt to recon the selected orders?`,
    });
  }

  openBulkOrderCreateDialog(data?: BulkOrderCreateDialogData) {
    return this.dialogs.open<
      BulkOrderCreateDialogComponent,
      BulkOrderCreateDialogData,
      BulkOrderCreateDialogResult
    >(BulkOrderCreateDialogComponent, { data, maxWidth: '600px' });
  }

  openOrderTemplateFormDialog(data?: OrderTemplateFormDialogData) {
    return this.dialogs.open<
      OrderTemplateFormDialogComponent,
      OrderTemplateFormDialogData,
      OrderTemplateFormDialogResult
    >(OrderTemplateFormDialogComponent, { data, maxWidth: '600px' });
  }

  openOrderCancelDialog() {
    return this.prompts.prompt({
      title: 'Cancel order',
      message: 'Are you sure you want to cancel this order?',
      actions: [
        { name: 'No', result: false },
        { name: 'Cancel Order', result: true, color: 'warn' },
      ],
    });
  }
  openOrderProcessConfirmation() {
    return this.prompts.prompt({
      title: 'Process order(s)',
      message: 'Are you sure you want to process the selected order(s)?',
      actions: [
        { name: 'No', result: false },
        { name: 'Process', result: true, color: 'warn' },
      ],
    });
  }
}
