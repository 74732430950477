import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
} from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataView } from '@x/common/data';
import { Operation } from '@x/common/operation';
import { OrderDialogService } from '@x/ecommerce-admin/app/orders/services/order-dialog.service';
import { ShipmentService } from '@x/ecommerce/domain-client';
import { ShipmentDetailFragment } from 'libs/ecommerce/src/domain-client/services/gql/shipment.gql.generated';
// import { ShipmentDetailFragment } from 'libs/ecommerce/src/domain-client/services/gql/shipment.gql.generated';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'x-shipment-quick-view',
  templateUrl: './shipment-quick-view.component.html',
  styleUrls: ['./shipment-quick-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentQuickViewComponent implements AfterViewInit {
  @Input()
  view: DataView<ShipmentDetailFragment, number>;

  constructor(
    private changeRef: ChangeDetectorRef,
    private shipmentService: ShipmentService,
    private snackbar: MatSnackBar,
    private orderDialogService: OrderDialogService,
  ) {}

  ngAfterViewInit() {
    this.view.stateChanges().subscribe(() => {
      this.changeRef.markForCheck();
    });
  }

  // Parcel actions
  async removeParcel(parcel: any) {
    const result = await firstValueFrom(
      this.orderDialogService.openParcelRemoveConfirmation(parcel),
    );

    if (!result) return;

    this.view
      .observeMutation(() => this.shipmentService.removeParcel(parcel.id))
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel removed'));
  }

  async editParcel(parcel: any) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderUpdateParcelDialog(parcel).afterClosed(),
    );
    if (!result) return;

    this.view
      .observeMutation(() => this.shipmentService.updateParcel({ id: parcel.id, ...result }))
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel updated'));
  }

  async openParcelDialog(shipmentId: number) {
    const result = await firstValueFrom(
      this.orderDialogService.openOrderCreateParcelDialog().afterClosed(),
    );

    if (!result) return;

    this.view
      .observeSubMutation(shipmentId, (id) =>
        this.shipmentService.createShipmentParcel({ shipmentId: Number(id), ...result }),
      )
      .subscribe((operation) => this.handleOperationResult(operation, 'Shipment parcel created'));
  }

  private handleOperationResult(operation: Operation, successMessage?: string) {
    if (operation.isSuccessState() && successMessage) {
      this.snackbar.open(successMessage);
    }
    if (operation.isErrorState()) {
      this.snackbar.open(operation.state.error.message);
    }
  }
}
