import { ChangeDetectionStrategy, Component, Inject, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DashboardSnackbarService } from '@x/dashboard/core';
import { ShippingSlotService } from '@x/ecommerce/domain-client';
import {
  GeoRegionItemCollectionProvider,
  ShippingMethodItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { DateTime } from 'luxon';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

export type ShippingSlotDialogData = {
  timezone?: string;
  shippingSlotId?: string;
};

// export type ShippingSlotCreateDialogData = {};

export type ShippingSlotDialogResult = {
  id: number;
};

@Component({
  selector: 'x-shipping-slot-dialog',
  templateUrl: './shipping-slot-dialog.component.html',
  styleUrls: ['./shipping-slot-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingSlotDialogComponent implements OnDestroy {
  Providers = {
    GeoRegionItemCollectionProvider,
    ShippingMethodItemCollectionProvider,
  };
  private _destroy$ = new Subject<void>();

  formGroup = new FormGroup({
    id: new FormControl(),
    methodId: new FormControl<number>(0, { validators: Validators.required, nonNullable: true }),
    priority: new FormControl<number>(0, { nonNullable: true }),
    capacity: new FormControl<number>(0, { validators: Validators.required, nonNullable: true }),
    leadTime: new FormControl<string | null>(null),
    lagTime: new FormControl<string | null>(null),
    startDate: new FormControl<string | null>({
      value: this.data?.shippingSlotId ?? null,
      disabled: !!this.data?.shippingSlotId,
    }),
    startTime: new FormControl<string>('08:00', Validators.required),
    endDate: new FormControl<string>(DateTime.now().toISODate(), Validators.required),
    endTime: new FormControl<string>('17:00', Validators.required),
    regionIds: new FormControl<any[]>([]),
    enabled: new FormControl<boolean>(true, { validators: Validators.required, nonNullable: true }),
  });

  shippingSlotId: string;
  methodId: number;
  timezone?: string;
  dialogState: string = 'Update';

  constructor(
    public dialog: MatDialogRef<ShippingSlotDialogComponent>,
    private shippingSlotService: ShippingSlotService,
    private snackbarService: DashboardSnackbarService,
    @Inject(MAT_DIALOG_DATA)
    public data: ShippingSlotDialogData,
  ) {
    this.timezone = data?.timezone;

    if (data?.shippingSlotId) {
      this.dialogState = 'Update';

      this.shippingSlotService
        .fetchItem(data.shippingSlotId)
        .pipe(
          takeUntil(this._destroy$),
          tap(({ startAt, endAt, ...v }) => {
            this.shippingSlotId = v.id;
            this.timezone = v.method.timezone ?? 'UTC';
            console.log('TimeZone', this.timezone);
            console.log('START DATETIME', startAt);
            this.formGroup.patchValue(
              {
                ...v,
                startDate: DateTime.fromISO(startAt, { zone: this.timezone, setZone: true }) //, { zone: this.timezone, setZone: true }
                  .startOf('day')
                  .toISODate(),
                startTime: DateTime.fromISO(startAt, {
                  zone: this.timezone,
                  setZone: true,
                }).toISOTime({
                  includeOffset: false,
                  suppressSeconds: true,
                  suppressMilliseconds: true,
                }),
                endDate: DateTime.fromISO(endAt, { zone: this.timezone, setZone: true })
                  .startOf('day')
                  .toISODate(),
                endTime: DateTime.fromISO(endAt, { zone: this.timezone, setZone: true }).toISOTime({
                  includeOffset: false,
                  suppressSeconds: true,
                  suppressMilliseconds: true,
                }),
                regionIds: v.regions ? v.regions?.map((r: any) => r.id) : null,
              },
              { emitEvent: true },
            );
            this.formGroup.markAsUntouched();
          }),
        )
        .subscribe();
    } else {
      this.dialogState = 'Create';
    }
  }

  async update(shippingSlotId: string) {
    this.formGroup.updateValueAndValidity();
    if (this.formGroup.invalid) return;

    const {
      priority,
      capacity,
      enabled,
      leadTime,
      lagTime,
      regionIds,
      startDate,
      startTime,
      endDate,
      endTime,
      ...input
    } = this.formGroup.getRawValue();

    if (endDate === null) return;

    return this.shippingSlotService
      .update({
        id: shippingSlotId,
        capacity,
        enabled,
        lagTime,
        leadTime,
        priority,
        regionIds,
        startAt: DateTime.fromISO(`${startDate}T${startTime}`),
        endAt: DateTime.fromISO(`${endDate}T${endTime}`),
      })
      .subscribe();
  }

  create() {
    this.formGroup.updateValueAndValidity();

    const { id, methodId, priority, capacity, enabled, leadTime, lagTime, regionIds, ...input } =
      this.formGroup.getRawValue();

    if (input.startDate === null || input.endDate === null) return;

    return this.shippingSlotService
      .create({
        methodId,
        priority,
        capacity,
        enabled,
        leadTime,
        lagTime,
        regionIds,
        startAt: DateTime.fromISO(`${input.startDate}T${input.startTime}`, {
          zone: this.timezone,
          setZone: true,
        }),
        endAt: DateTime.fromISO(`${input.endDate}T${input.endTime}`, {
          zone: this.timezone,
          setZone: true,
        }),
      })
      .subscribe();
  }

  async submit() {
    let result;
    if (this.data?.shippingSlotId) {
      this.dialogState = 'Update';
      result = await this.update(this.data.shippingSlotId);
    } else {
      this.dialogState = 'Create';
      result = this.create();
    }

    this.dialog.close(result);
  }

  state(dialogState: 'Update' | 'Create') {
    return this.dialogState === dialogState;
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
