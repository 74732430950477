import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ErrorTranslatorService } from '@x/common/error';
import { Operation } from '@x/common/operation';
import { Observable } from 'rxjs';
import {
  OperationSnackbarComponent,
  OperationSnackbarData,
} from '../components/operation-snackbar/operation-snackbar.component';

@Injectable()
export class DashboardSnackbarService {
  constructor(
    private readonly matSnackbar: MatSnackBar,
    private readonly errorTranslator: ErrorTranslatorService,
  ) {}

  openOperation(operation$: Observable<Operation>) {
    const data: OperationSnackbarData = { operation$ };

    return this.matSnackbar.openFromComponent(OperationSnackbarComponent, { data });
  }

  openError(error: any) {
    const tError = this.errorTranslator.translateError(error);

    return this.matSnackbar.open(`${tError.title} - ${tError.message}`, 'Ok', {
      duration: 5000,
    });
  }
}
