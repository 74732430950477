<mat-form-field>
  <mat-label>{{ label }}</mat-label>
  <fa-icon icon="list" matPrefix></fa-icon>
  <x-model-autocomplete
    [formControl]="control"
    xToolbarAction
    [dataSource]="stockAutocompleteDatasource"
    [displayWith]="stockAutocompleteDatasource.displayFn"
    [transformer]="stockCategoryIdTransformer"
    placeholder="Search stock category..."
  ></x-model-autocomplete>
</mat-form-field>
