<x-data-table [view]="view" [selectable]="false" [isDisabled]="rowDisabledPredicate">
  <ng-template xDataColumnDef="id" let-row>
    {{ row.id }}
  </ng-template>

  <ng-template xDataColumnDef="name" let-row>
    <div class="mat-body-2">
      <xd-shape [color]="row.color"></xd-shape>
      {{ row.name }}
    </div>
    <div class="mat-caption">{{ row.code }}</div>
  </ng-template>

  <ng-template xDataColumnDef="provider" let-row>
    <div class="mat-body-2">{{ row.provider }}</div>
  </ng-template>

  <ng-template xDataColumnDef="costCalculator" let-row>
    <div class="mat-body-2">{{ row.costCalculator }}</div>
  </ng-template>

  <ng-template xDataColumnDef="labelGenerator" let-row>
    <div class="mat-body-2">{{ row.labelGenerator }}</div>
  </ng-template>

  <ng-template xDataColumnDef="priority" let-row>
    <div class="mat-body-2">{{ row.priority }}</div>
  </ng-template>

  <ng-template xDataColumnDef="channels" let-row>
    <ng-container *ngFor="let channel of row.channels">
      <x-pill [text]="channel.code" [color]="'primary'" icon="store"></x-pill>
    </ng-container>
  </ng-template>

  <ng-template xDataColumnDef="regions" let-row>
    <x-pill-group>
      <x-geo-region-pill *ngFor="let region of row.regions" [region]="region"></x-geo-region-pill>
    </x-pill-group>
  </ng-template>
  <ng-template xDataColumnDef="categories" let-row>
    <x-pill-group>
      <ng-container *ngFor="let category of row.categories">
        <x-pill [text]="category.name" [color]="'default'" icon="truck-plane"></x-pill>
      </ng-container>
    </x-pill-group>
  </ng-template>
  <ng-template xDataColumnDef="schedules" let-row>
    <x-pill-group>
      <x-pill
        *ngFor="let schedule of row.schedules"
        [text]="schedule.name"
        [color]="'default'"
        icon="calendar-day"
      ></x-pill>
    </x-pill-group>
  </ng-template>
  <ng-template xDataColumnDef="warehouse" let-row>
    <div class="mat-body-2">{{ row.warehouse.name }}</div>
  </ng-template>

  <ng-template xDataColumnDef="quoteValidityDuration" let-row>
    <div class="mat-body-2">{{ row.quoteValidityDuration | duration }}</div>
  </ng-template>

  <ng-template xDataColumnDef="timestamps" let-row>
    <x-timestamp-column [row]="row"></x-timestamp-column>
  </ng-template>

  <a
    x-data-button
    matTooltip="View Detail"
    label="View Detail"
    icon="magnifying-glass"
    *xDataAction="'detail'; let row; primary: true"
    [routerLink]="['./detail', row.id]"
  ></a>

  <button
    x-data-button
    label=" Edit "
    icon="clipboard-list"
    *xDataAction="'order-detail'; let row"
    (click)="openShippingMethodUpdateDialog(row.id)"
  ></button>
</x-data-table>
