import { Injectable } from '@angular/core';
import { PublicationState } from '@x/schemas/content';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ContentNotFoundException } from './exceptions/content-not-found.exception';
import { LandingPageDetailByIdGQL } from './gql/landing-page.gql.generated';
import { mapEntity } from './types/common.maps';
import { mapLandingPageDetailContent } from './types/landing-page.maps';
import { ILandingPageDetailContent } from './types/landing-page.types';

@Injectable()
export class LandingPageService {
  constructor(private LandingPageDetailByIdGQL: LandingPageDetailByIdGQL) {}

  fetchDetail(
    id: string,
    locale: string,
    status: PublicationState = PublicationState.Live,
  ): Observable<ILandingPageDetailContent> {
    return this.LandingPageDetailByIdGQL.fetch({ id, locale, status }).pipe(
      map((r) => {
        const page = r.data.landingPages?.data[0];

        if (!page) {
          throw new ContentNotFoundException(
            `LandingPage with id '${id}', locale '${locale}' not found.`,
          );
        }

        return mapEntity(page);
      }),
      map(mapLandingPageDetailContent),
    );
  }
}
