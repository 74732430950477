import { TranslatedError } from '@x/common/error';
import {
  AnyOperationState,
  IOperationOptions,
  Operation,
  OperationErrorState,
  OperationObserverService,
  OperationSuccessState,
} from '@x/common/operation';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  isObservable,
  merge,
  of,
  timer,
} from 'rxjs';
import {
  debounceTime,
  filter,
  finalize,
  first,
  map,
  skip,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { OperationQueue } from '../../operation/operation-queue';
import { IDataProvider } from '../types/data-provider';

export interface IDataViewOptions<I = string | number> {
  id?: I | null;
  idSubject?: Observable<I | null>;
  autoRefresh?: { everyMillis: number };
  entityName?: string;
}

export interface IDataMutationOptions extends IOperationOptions {
  refresh?: boolean;
}

export class DataView<T = any, I = string | number> {
  get id() {
    return this._id;
  }
  set id(id: I | null) {
    this.setId(id);
  }

  get data(): T | null {
    return this._data$.getValue();
  }

  get fetchState() {
    return this._fetchState$;
  }

  get fetchLoading() {
    return this._fetchState$.getValue().status === 'loading';
  }

  get fetchError(): TranslatedError | undefined | null {
    return this._fetchState$.getValue().error;
  }

  get mutationLoading() {
    return this._mutationQueue.isLoading;
  }

  get currentMutation() {
    return this._mutationQueue.currentJob;
  }

  get lastMutation() {
    return this._mutationQueue.lastJob;
  }

  protected _refresh$ = new Subject<boolean>();

  protected _data$ = new BehaviorSubject<T | null>(null);
  protected _fetchState$ = Operation.createBehaviourSubject<T>();

  protected _observables: Observable<any>[] = [];
  protected _subscriptions: Subscription[] = [];

  protected _id: I | null;
  protected _connected = false;

  protected _debounceTime: number = 300;
  protected _mutationHistory: number = 20;
  protected _mutationStates = new Map<string, AnyOperationState<T>>();

  protected _mutationQueue: OperationQueue;

  protected _entityName?: string;

  constructor(
    public readonly provider: IDataProvider<T, I>,
    protected readonly operationService: OperationObserverService,
    protected readonly options?: IDataViewOptions<I>,
  ) {
    this._mutationQueue = operationService.createQueue();

    this._id = options?.id ?? null;
    this._entityName = options?.entityName;

    this.bindObservable(this.observeFetch());
    this.bindObservable(this._mutationQueue.observe());

    if (options?.autoRefresh) {
      this.bindObservable(
        timer(options.autoRefresh.everyMillis, options.autoRefresh.everyMillis).pipe(
          tap(() => this.refresh()),
        ),
      );
    }

    if (options?.idSubject) {
      this.bindObservable(options.idSubject.pipe(tap((id) => this.setId(id))));
    }
  }

  connect() {
    if (!this._connected) {
      this.subscribeAll();
    }

    this._connected = true;

    if (this._id) {
      this.refresh();
    }

    return this._data$;
  }

  disconnect(): void {
    if (this._connected) {
      this.unsubscribeAll();
    }
    this._connected = false;
  }

  setId(id: I | null) {
    if (this._id !== id) {
      this._data$.next(null);
      this._id = id;
      this._refresh$.next(true);
    }
  }

  refresh() {
    this._refresh$.next(true);
  }

  observeMutation<T = any>(
    mutation: (id: NonNullable<I>) => Observable<T>,
    options: IDataMutationOptions = {
      refresh: true,
      label: 'Mutation',
    },
  ): Observable<Operation<T>> {
    if (!this._id) {
      throw new Error('DataView id not defined');
    }

    return this._mutationQueue.queue(mutation(this._id), options).pipe(
      finalize(() => {
        if (options.refresh ?? true) {
          this._refresh$.next(true);
        }
      }),
    );
  }

  observeSubMutation<T = any>(
    subId: NonNullable<I>,
    mutation: (id: NonNullable<I>) => Observable<T>,
    options: IDataMutationOptions = {
      refresh: true,
      label: 'Mutation',
    },
  ): Observable<Operation<T>> {
    if (!this._id) throw new Error('DataView id not defined');
    if (!subId) throw new Error('No Sub endtity id defined');

    return this._mutationQueue.queue(mutation(subId), options).pipe(
      finalize(() => {
        if (options.refresh ?? true) {
          this._refresh$.next(true);
        }
      }),
    );
  }

  stateChanges(): Observable<void> {
    return merge(this._data$, this._fetchState$, this._mutationQueue.statusChanges()).pipe(
      map((a) => {
        return;
      }),
    );
  }

  dataChanges(): Observable<T | null> {
    return this._data$.asObservable();
  }

  protected bindObservable(observable: Observable<any>) {
    this._observables.push(observable);
    if (this._connected) {
      this._subscriptions.push(observable.subscribe());
    }
  }

  private observeFetch() {
    // required to not debounce the first emit
    const initialRefresh$ = this._refresh$.pipe(first(), startWith(true));
    const debouncedRefresh$ = this._refresh$.pipe(skip(1), debounceTime(this._debounceTime));

    return merge(initialRefresh$, debouncedRefresh$).pipe(
      map((refresh) => [this._id, refresh] as [I, boolean]),
      // tap(() => this._fetchState$.next(new OperationInitialState())),
      filter(([id, refresh]) => typeof id === 'number' || typeof id === 'string'),
      switchMap(([id, refresh]) => {
        const fetch = this.provider.fetchSingle(id);

        const operationOptions: IOperationOptions = {
          label: `Fetch ${this._entityName ?? 'Detail'}`,
        };

        if (isObservable(fetch)) {
          return this.operationService.observe(fetch, operationOptions);
        } else {
          return this.operationService.observe(of(fetch), operationOptions);
        }
      }),
      tap((op) => {
        if (op instanceof OperationSuccessState) {
          this._data$.next(op.data);
        } else if (op instanceof OperationErrorState) {
          this._data$.next(null);
        }
        this._fetchState$.next(op);
      }),
    );
  }

  private subscribeAll() {
    this._subscriptions = this._observables.map((o) => o.subscribe());
  }

  private unsubscribeAll() {
    this._subscriptions.forEach((s) => (s.closed ? s.unsubscribe() : null));
    this._subscriptions = [];
  }
}
