import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { ShipmentObject } from '@x/ecommerce/domain-client';

@Component({
  selector: 'x-shipment-pill',
  templateUrl: 'shipment-pill.component.html',
  styleUrls: ['shipment-pill.component.scss'],
  host: {
    class: 'x-shipment-pill x-pill-base',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShipmentPillComponent {
  @Input()
  shipment: Partial<ShipmentObject>;

  @HostBinding('class')
  get stateClass() {
    return this.shipment?.state
      ? `x-shipment-state-${this.shipment.state}`
      : 'x-shipment-state-default';
  }
}
