import { ChangeDetectionStrategy, Component, computed, input } from '@angular/core';

@Component({
  selector: 'xd-shape',
  template: '',
  host: {
    '[class]': '_class()',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
})
export class ShapeComponent {
  shape = input('circle');
  color = input<string | null | undefined>(null);

  _class = computed(() => {
    return [`xd-shape`, `shape-${this.shape()}`, `x-color-${this.color() ?? 'default'}`];
  });
}
