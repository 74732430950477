import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { CloudinaryModule } from '@x/common/cloudinary';
import { CloudImagePipe } from '@x/common/cloudinary/cloud-image.pipe';
import { LoaderModule } from '@x/common/loader';
import { Html5PlayerComponent } from '@x/common/media/html5-player/html5-player.component';
import { VimeoPlayerComponent } from './vimeo-player/vimeo-player.component';
import { YoutubePlayerComponent } from './youtube-player/youtube-player.component';

@NgModule({
  imports: [CommonModule, YouTubePlayerModule, CloudinaryModule, LoaderModule, CloudImagePipe],
  providers: [],
  declarations: [YoutubePlayerComponent, VimeoPlayerComponent, Html5PlayerComponent],
  exports: [YoutubePlayerComponent, VimeoPlayerComponent, Html5PlayerComponent],
})
export class MediaModule {}
