import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import { ShipmentObject, ShipmentRowObject, ShipmentService } from '@x/ecommerce/domain-client';
import { ShipmentFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShipmentRowCollectionProvider
  implements
    IDataCollectionProvider<ShipmentRowObject, ShipmentFilterInput, any, number>,
    IDataProvider<ShipmentRowObject>
{
  constructor(private shipmentService: ShipmentService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ShipmentRowObject>> {
    return this.shipmentService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<ShipmentRowObject> {
    return this.shipmentService.fetchRow(Number(id));
  }

  toId(model: ShipmentRowObject): number {
    return model.id;
  }

  toString(model: ShipmentRowObject): string {
    return model.description ?? '';
  }
}

@Injectable({ providedIn: 'root' })
export class ShipmentDetailProvider implements IDataProvider<ShipmentObject, number> {
  constructor(private shipmentService: ShipmentService) {}

  fetchSingle(id: number): Observable<ShipmentObject> {
    return this.shipmentService.fetchDetail(id);
  }

  toId(model: ShipmentObject): number {
    return model.id;
  }

  toString(model: ShipmentObject): string {
    return model.driver ?? '';
  }
}
