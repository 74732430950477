import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Injectable, NgZone } from '@angular/core';
import { Observable, of } from 'rxjs';
import { debounceTime, map, shareReplay } from 'rxjs/operators';
import {
  BreakpointInfo,
  Breakpoints,
  DeviceOrientationMediaQueries,
  DeviceOrientations,
  DeviceSizeMediaQueries,
  DeviceSizes,
  ResponsiveInfo,
} from './responsive.types';

@Injectable()
export class ResponsiveService {
  get currentSize(): DeviceSizes | null {
    const currentSize: DeviceSizes | undefined = Object.keys(DeviceSizeMediaQueries)
      .map((key) => key as DeviceSizes)
      .find((key) => this.breakpointObserver.isMatched(DeviceSizeMediaQueries[key]));

    return currentSize ? currentSize : null;
  }

  get currentOrientation(): DeviceOrientations | null {
    const currentOrientation: DeviceOrientations | undefined = Object.keys(
      DeviceOrientationMediaQueries,
    )
      .map((key) => key as DeviceOrientations)
      .find((key) => this.breakpointObserver.isMatched(DeviceOrientationMediaQueries[key]));

    return currentOrientation ? currentOrientation : null;
  }

  info$: Observable<ResponsiveInfo>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private ngZone: NgZone,
  ) {
    this.info$ = breakpointObserver
      .observe([
        ...Object.values(DeviceSizeMediaQueries),
        ...Object.values(DeviceOrientationMediaQueries),
      ])
      .pipe(
        map((breakpointState: BreakpointState) => {
          const size: DeviceSizes | undefined = Object.entries<string>(DeviceSizeMediaQueries).find(
            ([key, val]) => breakpointState.breakpoints[val],
          )?.[0] as DeviceSizes;

          const orientation: DeviceOrientations | undefined = Object.entries<string>(
            DeviceOrientationMediaQueries,
          ).find(([key, val]) => breakpointState.breakpoints[val])?.[0] as DeviceOrientations;

          return {
            size,
            orientation,
          };
        }),
        shareReplay(),
      );
  }

  // -------------------------------------------------------------------
  // Breakpoint Observer Functionality
  // -------------------------------------------------------------------

  isBreakpoint(info: BreakpointInfo): boolean {
    const mediaQuery = this.getMediaQuery(info);
    if (!mediaQuery) return false;
    return this.breakpointObserver.isMatched(mediaQuery);
  }

  observeBreakpoints(): Observable<ResponsiveInfo> {
    return this.info$;
  }

  observeIsBreakpoint(info: BreakpointInfo): Observable<boolean> {
    const mediaQuery = this.getMediaQuery(info);

    if (!mediaQuery) return of(false);

    return this.breakpointObserver
      .observe(mediaQuery)
      .pipe(map((breakpointState) => breakpointState.matches));
  }

  getMediaQuery(info: BreakpointInfo): string[] | null {
    const { min, max, breakpoints } = info;

    if (breakpoints) {
      return breakpoints.map((point) => {
        return DeviceSizeMediaQueries[point];
      });
    }

    const minWidthQuery =
      min && Breakpoints[min] ? `(min-width: ${Breakpoints[min]}px)` : undefined;
    const maxWidthQuery =
      max && Breakpoints[max] ? `(max-width: ${Breakpoints[max] - 0.2}px)` : undefined;

    if (max && min && max > min) {
      return [`${minWidthQuery} and ${maxWidthQuery}`];
    }

    return minWidthQuery ? [minWidthQuery] : maxWidthQuery ? [maxWidthQuery] : null;
  }

  // -------------------------------------------------------------------
  // Resize Observer Functionality
  // -------------------------------------------------------------------

  /**
   * Observes an element for resize events using ResizeObserver, with an optional debounce.
   */
  observeElementResize(element: Element, debounceMillis = 50): Observable<void> {
    return new Observable<void>((subscriber) => {
      const observer = new ResizeObserver(() => {
        subscriber.next();
      });

      observer.observe(element);

      return () => {
        observer.disconnect();
      };
    }).pipe(debounceTime(debounceMillis));
  }
}
