import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FullCalendarComponent } from '@fullcalendar/angular/full-calendar.component';
import { CalendarOptions, EventInput } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import luxonPlugin from '@fullcalendar/luxon3';
import { DataTableView } from '@x/common/data';
import { DatetimeFormatter } from '@x/common/datetime';
import { ResponsiveService } from '@x/common/responsive';
import { coerceDateTime } from '@x/common/utils';
import { IShippingSlotRowObject } from '@x/ecommerce/domain-client';
import { ShipmentState, ShippingSlotFilterInput } from '@x/schemas/ecommerce';
import { DateTime } from 'luxon';
import { Subject, takeUntil } from 'rxjs';

interface IShippingSlotEventView extends IShippingSlotRowObject {
  cancelledCount: number;
  bars: {
    state: string;
    width: string;
  }[];
}

@Component({
  selector: 'x-shipping-slot-calendar',
  templateUrl: './shipping-slot-calendar.component.html',
  styleUrl: './shipping-slot-calendar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  preserveWhitespaces: false,
  host: {
    class: 'x-shipping-slot-calendar',
  },
})
export class ShippingSlotCalendarComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input()
  view: DataTableView<IShippingSlotRowObject, ShippingSlotFilterInput, {}, string>;

  @Input()
  set timezone(timezone: string) {
    this.calendarOptions = {
      ...this.calendarOptions,
      timeZone: timezone,
    };
  }

  @ViewChild('calendar')
  calendar: FullCalendarComponent;

  events: EventInput[] = [];

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin, interactionPlugin, luxonPlugin],
    initialView: 'dayGridMonth',
    weekends: true,
    handleWindowResize: true,
    showNonCurrentDates: false,
    fixedWeekCount: false,
    firstDay: 1,
    height: '100%',
    headerToolbar: false,
    // headerToolbar: {
    //   left: 'prev,next',
    //   center: 'title',
    //   right: 'dayGridMonth,dayGridWeek,dayGridDay', // user can switch between the two
    // },
    eventTimeFormat: {
      hour: 'numeric',
      minute: '2-digit',
      meridiem: false,
      hour12: false,
    },
    eventOrder: 'title',
    timeZone: this.dtFormatter.getTimezone(),
    displayEventEnd: true,
    eventClick: (arg) => this.selectSlot(arg.event.extendedProps.slot),
  };

  private _destroy$ = new Subject<void>();

  constructor(
    private changeRef: ChangeDetectorRef,
    private dtFormatter: DatetimeFormatter,
    private responsiveService: ResponsiveService,
    private el: ElementRef,
  ) {}

  ngOnInit() {
    this.calendarOptions.initialDate = this.getCurrentFilterDate();
    this.calendarOptions.initialView = this.view.displayOptions?.calenderViewType;
  }

  ngAfterViewInit() {
    this.view
      .connect()
      .pipe(takeUntil(this._destroy$))
      .subscribe((data) => {
        const events = data.map((d) => this.transformSlotToEvent(d));
        this.events = events;
        this.changeRef.markForCheck();
        this.calendar?.getApi()?.render();
      });

    this.view
      .filterChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => this.updateCalenderFromView());

    this.responsiveService
      .observeElementResize(this.el.nativeElement.parentElement)
      .pipe(takeUntil(this._destroy$))
      .subscribe(() => {
        this.calendar?.getApi()?.render();
      });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  selectSlot(slot: IShippingSlotRowObject) {
    this.view.setActive(slot.id);
    this.calendar.getApi().render();
  }

  getSlotView(eventObj: any): IShippingSlotEventView | undefined {
    return eventObj.event.extendedProps?.slot;
  }

  private updateCalenderFromView() {
    const cal = this.calendar.getApi();
    if (!cal) return;
    const { calenderViewType } = this.view.displayOptions;

    cal.changeView(calenderViewType ?? 'dayGridMonth', this.getCurrentFilterDate());
  }

  private transformSlotToEvent(slot: IShippingSlotRowObject) {
    // used for sorting
    // sort by method and then start date
    const title = `${slot.startAt}/${slot.schedule?.name}`;

    return {
      id: slot.id,
      start: slot.startAt,
      end: slot.endAt,
      title,
      textColor: `var(--x-${slot.method.color}-500-contrast)`,
      borderColor: `var(--x-${slot.method.color}-50)`,
      backgroundColor: `var(--x-${slot.method.color}-500)`,
      extendedProps: { slot: this.makeSlotView(slot) },
    } satisfies EventInput;
  }

  private makeSlotView(slot: IShippingSlotRowObject): IShippingSlotEventView {
    return {
      ...slot,
      cancelledCount: slot.shipmentStateCounts.reduce(
        (sum, { state, count }) => (state === 'CANCELLED' ? sum + count : sum),
        0,
      ),
      bars: slot.shipmentStateCounts
        .filter((stat) => ![ShipmentState.New, ShipmentState.Cancelled].includes(stat.state))
        .map((stat) => ({
          state: stat.state,
          width: `${(stat.count / slot.capacity) * 100}%`,
        })),
    };
  }

  getCurrentFilterDate() {
    const { startAtRange } = this.view.filter;
    return (coerceDateTime(startAtRange?.date) ?? DateTime.now()).toISODate();
  }

  isOverride(slot: IShippingSlotRowObject, key: string): boolean {
    return slot.overrides?.includes(key) ?? false;
  }
}
