import { Injectable } from '@angular/core';
import { IDataCollection, IDataCollectionProvider, IDataQuery } from '@x/common/data';
import { GeoRegionService, IGeoRegionItemObject } from '@x/ecommerce/domain-client';
import { GeoRegionFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class GeoRegionItemCollectionProvider
  implements IDataCollectionProvider<IGeoRegionItemObject, GeoRegionFilterInput>
{
  defaultPage = { index: 0, size: 1000 };

  constructor(private geoRegionService: GeoRegionService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<GeoRegionFilterInput, any>>): Observable<
    IDataCollection<IGeoRegionItemObject>
  > {
    return this.geoRegionService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(id: string | number): Observable<IGeoRegionItemObject> {
    return this.geoRegionService.fetchItem(Number(id));
  }

  toId(model: IGeoRegionItemObject): string | number {
    return model.id;
  }

  toString(model: IGeoRegionItemObject): string {
    return `${model.name}`;
  }
}
