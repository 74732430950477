import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  IShippingSlotDetailObject,
  IShippingSlotItem,
  IShippingSlotRowObject,
  ShippingSlotService,
} from '@x/ecommerce/domain-client';
import { ShippingSlotFilterInput } from '@x/schemas/ecommerce';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ShippingSlotItemCollectionProvider
  implements
    IDataCollectionProvider<IShippingSlotItem, ShippingSlotFilterInput, any, string>,
    IDataProvider<IShippingSlotItem, string>
{
  constructor(private service: ShippingSlotService) {}

  fetchCollection({
    filter,
    page,
    sort,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IShippingSlotItem>> {
    return this.service
      .fetchItems({ filter, page, sort })
      .pipe(map((items) => ({ items, totalItemsCount: items.length })));
  }

  fetchSingle(id: string): IShippingSlotItem | Observable<IShippingSlotItem> {
    return this.service.fetchItem(String(id));
  }

  toId(model: IShippingSlotItem): string {
    return model.id;
  }

  toString(model: IShippingSlotItem): string {
    return `
    ${DateTime.fromISO(model.startAt).toFormat('DDDD T')}
     -
     ${DateTime.fromISO(model.endAt).toFormat('T')}
     `;
  }
}

@Injectable({ providedIn: 'root' })
export class ShippingSlotRowCollectionProvider
  implements
    IDataCollectionProvider<IShippingSlotRowObject, ShippingSlotFilterInput, {}, string>,
    IDataProvider<IShippingSlotRowObject, string>
{
  constructor(private service: ShippingSlotService) {}

  fetchCollection({
    filter,
    page,
    sort,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IShippingSlotRowObject>> {
    return this.service
      .fetchRows({ filter, page, sort })
      .pipe(map((items) => ({ items, totalItemsCount: items.length })));
  }

  fetchSingle(id: string): IShippingSlotRowObject | Observable<IShippingSlotRowObject> {
    return this.service.fetchRow(id);
  }

  toId(model: IShippingSlotRowObject): string {
    return model.id;
  }

  toString(model: IShippingSlotRowObject): string {
    return `
    ${DateTime.fromISO(model.startAt).toFormat('DDDD T')}
     -
     ${DateTime.fromISO(model.endAt).toFormat('T')}
     `;
  }
}

@Injectable({ providedIn: 'root' })
export class ShippingSlotDetailProvider
  implements IDataProvider<IShippingSlotDetailObject, string>
{
  constructor(private service: ShippingSlotService) {}

  fetchSingle(id: string): Observable<IShippingSlotDetailObject> {
    return this.service.fetchDetail(id);
  }

  toId(model: IShippingSlotDetailObject): string {
    return model.id;
  }

  toString(model: IShippingSlotDetailObject): string {
    return `${model.id ? '#' : 'ID'}${model.id}`;
  }
}
