import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ShippingScheduleService } from '@x/ecommerce/domain-client';
import {
  GeoRegionItemCollectionProvider,
  ShippingMethodItemCollectionProvider,
} from '@x/ecommerce/domain-data';
import { CreateShippingScheduleInput, UpdateShippingScheduleInput } from '@x/schemas/ecommerce';
import { DateTime } from 'luxon';
import { tap } from 'rxjs/operators';

export type ShippingScheduleDialogData = {
  shippingScheduleId?: number;
  shippingMethodId?: number;
};
export type ShippingScheduleDialogResult = {
  id: number;
};

@Component({
  selector: 'x-shipping-schedule-dialog',
  templateUrl: './shipping-schedule-dialog.component.html',
  styleUrls: ['./shipping-schedule-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingScheduleDialogComponent implements OnInit {
  readonly Providers = {
    ShippingMethodItemCollectionProvider,
    GeoRegionItemCollectionProvider,
  };

  enabledControl = new FormControl<boolean>(false, { nonNullable: true });

  formGroup = new FormGroup({
    name: new FormControl<string>('', {
      validators: Validators.required,
      nonNullable: true,
    }),
    methodId: new FormControl<number | null>(
      { value: this.data.shippingMethodId ?? null, disabled: !!this.data.shippingMethodId },
      {
        validators: [Validators.required],
      },
    ),
    startTime: new FormControl('08:00', { validators: Validators.required, nonNullable: true }),
    duration: new FormControl<string>('PT9H', {
      validators: Validators.required,
      nonNullable: true,
    }),
    capacity: new FormControl<number>(100, { validators: Validators.required, nonNullable: true }),
    priority: new FormControl<number>(0, { validators: Validators.required, nonNullable: true }),
    leadTime: new FormControl<string | null>(null),
    lagTime: new FormControl<string | null>(null),
    startAt: new FormControl<DateTime | null>(null),
    endAt: new FormControl<DateTime | null>(null),
    enabled: this.enabledControl,
    regionIds: new FormControl<number[]>([]),
    daysOfWeek: new FormControl<number[]>([1, 2, 3, 4, 5]),
  });

  shippingScheduleId: number;
  methodId?: number;
  verb: string = 'Create';

  constructor(
    public dialog: MatDialogRef<ShippingScheduleDialogComponent>,
    private shippingScheduleService: ShippingScheduleService,
    @Inject(MAT_DIALOG_DATA) public data: ShippingScheduleDialogData,
  ) {}

  ngOnInit() {
    console.log({ data: this.data });

    if (this.data?.shippingScheduleId) {
      this.shippingScheduleService
        .fetchById(this.data?.shippingScheduleId)
        .pipe(
          tap((v) => {
            this.shippingScheduleId = v.id;
            this.formGroup.patchValue(
              {
                ...v,
                daysOfWeek: v.daysOfWeek?.split('').map(Number) ?? [],
              },
              { emitEvent: false },
            );
            this.formGroup.markAsUntouched();
          }),
        )
        .subscribe();
      this.verb = 'Update';
    }

    this.methodId = this.data.shippingMethodId;
    this.formGroup.valueChanges.subscribe(console.log);
  }

  submit() {
    this.formGroup.updateValueAndValidity();
    const { methodId, ...value } = this.formGroup.getRawValue();

    if (this.formGroup.invalid) return;

    const startAtDateTime = DateTime.isDateTime(value.startAt)
      ? value.startAt
      : DateTime.fromISO(value.startAt ?? '');
    const endAtDateTime = DateTime.isDateTime(value.endAt)
      ? value.endAt
      : DateTime.fromISO(value.endAt ?? '');

    const daysOfWeek = value.daysOfWeek?.join('') ?? null,
      startAt = value.startAt ? startAtDateTime.toISODate({ format: 'basic' }) : null,
      endAt = value.endAt ? endAtDateTime.toISODate({ format: 'basic' }) : null;
    if (this.shippingScheduleId) {
      const input: UpdateShippingScheduleInput = {
        ...value,
        id: this.shippingScheduleId,
        capacity: value.capacity ?? 0,
        daysOfWeek,
        startAt,
        endAt,
      };
      this.shippingScheduleService
        .update(input)
        .pipe(tap(({ id }) => this.dialog.close({ id })))
        .subscribe();
    } else {
      const input: CreateShippingScheduleInput = {
        ...value,
        methodId: methodId ?? 0,
        capacity: value.capacity ?? 0,
        daysOfWeek,
        startAt,
        endAt,
      };
      this.shippingScheduleService
        .create(input)
        .pipe(tap(({ id }) => this.dialog.close({ id })))
        .subscribe();
    }
  }
}
