import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { NavigationError } from '@angular/router';
import { ApolloError } from '@apollo/client/core';
import { InjectLogger, Logger } from '@x/common/log';
import { ErrorResponseService } from './error-response.service';

@Injectable({ providedIn: 'root' })
export class RootErrorHandler implements ErrorHandler {
  @InjectLogger()
  log: Logger;

  constructor(private errorResponse: ErrorResponseService) {}

  handleError(error: any): void {
    // navigation error, Cannot match any routes.
    if (error instanceof NavigationError) {
      return this.handleRouteNotFoundError(error);
    }

    if (error instanceof HttpErrorResponse) {
      return this.handleHttpErrorResponse(error);
    }

    // uncaught promise error
    if (error.promise && error.rejection) {
      error = error.rejection;

      return this.handleError(error);
    }

    if (error instanceof ApolloError) {
      return this.handleApolloError(error);
    }

    this.log.warn('No error handler for error');
    console.error(error);

    // update response code
    this.errorResponse.setCode(500);
  }

  handleHttpErrorResponse(error: HttpErrorResponse) {
    const status = error.status;
    const body = error.error;
    const contentType = error.headers.get('content-type');

    this.log.warn(`Caught HttpErrorResponse`, {
      status,
      body,
      contentType,
    });

    if (status > 0) {
      this.errorResponse.setCode(status);
    }
  }

  handleApolloError(error: ApolloError) {
    if (Array.isArray(error.graphQLErrors) && error.graphQLErrors.length) {
      for (let i = 0; i < error.graphQLErrors.length; i++) {
        const graphError = error.graphQLErrors[i];
        try {
          this.log.warn(
            `GraphQL call ${graphError.path?.join('|')} error [${graphError.extensions.code}] ${graphError.extensions.message}`,
            graphError,
          );
        } catch {
          this.log.warn(`Graphql error [failed to parse]`, graphError);
        }
      }
    } else if (error.networkError && error.networkError instanceof HttpErrorResponse) {
      return this.handleHttpErrorResponse(error.networkError);
    }
  }

  handleRouteNotFoundError(error: NavigationError) {
    this.log.warn(`route not found: ${error.url}`);
    this.errorResponse.setCode(404);
  }
}
