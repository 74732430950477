import { InjectionToken } from '@angular/core';
import { CookieOptions } from './cookie.service';

export const DEFAULT_COOKIE_OPTIONS = new InjectionToken<CookieOptions>('DEFAULT_COOKIE_OPTIONS');

export interface IBrowserScript {
  src: string[] | string;
  async: boolean;
  defer: boolean;
  name: string;
}

export interface IBrowserModuleConfig {
  cookies: CookieOptions;
  scripts?: IBrowserScript[];
}
