import { CommonModule } from '@angular/common';
import { InjectionToken, ModuleWithProviders, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { DatetimeInputComponent } from '@x/common/datetime/components/datetime-input/datetime-input.component';
import { DateTimeComponent } from '@x/common/datetime/components/datetime/datetime.component';
import { TimeInputComponent } from '@x/common/datetime/components/time-input/time-input.component';
import { DatetimeFormatter } from '@x/common/datetime/datetime-formatter';
import { DateRangePipe } from './pipes/date-range.pipe';
import { DateTimeRelativityPipe } from './pipes/datetime-relativity.pipe';
import { DateTimePipe } from './pipes/datetime.pipe';
import { DurationPipe } from './pipes/duration.pipe';

const COMPONENTS = [DatetimeInputComponent, TimeInputComponent, DateTimeComponent];
const PIPES = [DateTimeRelativityPipe, DateRangePipe, DurationPipe, DateTimePipe];
const BASE_DATETIME_FORMAT = new InjectionToken<DatetimeFormatter>('BASE_DATETIME_FORMAT');
const TIMEZONE = new InjectionToken<string>('TIMEZONE');

export interface IDateTimeModuleConfig {
  timezone: string;
  format: string;
}

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatButtonModule,
  ],
  declarations: [...PIPES, ...COMPONENTS],
  providers: [],
  exports: [...PIPES, ...COMPONENTS],
})
export class DateTimeModule {
  static forRoot(config: IDateTimeModuleConfig): ModuleWithProviders<DateTimeModule> {
    const baseFormatter = new DatetimeFormatter(config);

    return {
      ngModule: DateTimeModule,
      providers: [
        // base formatter
        {
          provide: DatetimeFormatter,
          useValue: baseFormatter,
        },
        // base formatter from token
        {
          provide: BASE_DATETIME_FORMAT,
          useValue: baseFormatter,
        },
        {
          provide: TIMEZONE,
          useValue: config.timezone,
        },
      ],
    };
  }
}
