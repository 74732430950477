import { InjectionToken } from '@angular/core';
import { ApolloCache, ApolloLink, DefaultOptions } from '@apollo/client/core';
import { RetryLinkOptions } from './links/retry-link';

export interface GraphClientConfig {
  uri: string;
  retry?: RetryLinkOptions;
  defaultOptions?: DefaultOptions;
}
export interface GraphNamedClientConfig extends GraphClientConfig {
  name: string;
}

export interface GraphModuleConfig {
  client?: GraphClientConfig;
  clients?: GraphNamedClientConfig[];
}

/**
 * Provide an ApolloLink to be added to all graph clients
 */
export const GRAPH_GLOBAL_LINK = new InjectionToken<ApolloLink>('GraphGlobalLink');
export const GRAPH_CLIENT = new InjectionToken<GraphNamedClientConfig>('GraphClient');
export const GRAPH_DEFAULT_CLIENT = new InjectionToken<GraphNamedClientConfig>(
  'GraphDefaultClient',
);
export const GRAPH_CACHE = new InjectionToken<ApolloCache<any>>('GraphCache');
