import { Injectable } from '@angular/core';
import { mapFetchResultData } from '@x/common/graph';
import {
  CreateShipmentParcelInput,
  ShipmentFilterInput,
  StockItemFilterInput,
  TransitionShipmentInput,
  UpdateShipmentParcelInput,
} from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  CreateShipmentParcelGQL,
  RemoveShipmentParcelGQL,
  ShipmentParcelByIdGQL,
  UpdateShipmentParcelGQL,
} from './gql/shipment-parcel.gql.generated';
import {
  OrderShipmentsGQL,
  PrintShipmentLabelsGQL,
  PrintShipmentOrderManifestGQL,
  PrintShipmentOrderMessagesGQL,
  ShipmentDetailGQL,
  ShipmentPrintRouteManifestGQL,
  ShipmentPrintRouteSummaryGQL,
  ShipmentPrintRoyalMailReportGQL,
  ShipmentRowGQL,
  ShipmentRowsGQL,
  ShipmentRowsQueryVariables,
  TransitionShipmentGQL,
} from './gql/shipment.gql.generated';
import { ShipmentObject, ShipmentParcelObject, ShipmentRowObject } from './types/shipment';

@Injectable()
export class ShipmentService {
  constructor(
    private orderShipmentsGQL: OrderShipmentsGQL,
    private createShipmentParcelGQL: CreateShipmentParcelGQL,
    private updateShipmentParcelGQL: UpdateShipmentParcelGQL,
    private removeShipmentParcelGQL: RemoveShipmentParcelGQL,
    private shipmentParcelByIdGQL: ShipmentParcelByIdGQL,
    private shipmentRowsGQL: ShipmentRowsGQL,
    private shipmentRowGQL: ShipmentRowGQL,
    private shipmentDetailGQL: ShipmentDetailGQL,
    private transitionShipmentGQL: TransitionShipmentGQL,
    private printShipmentOrderManifestGQL: PrintShipmentOrderManifestGQL,
    private printShipmentOrderGiftMessageGQL: PrintShipmentOrderMessagesGQL,
    private printShipmentLabelsGQL: PrintShipmentLabelsGQL,
    private shipmentPrintRouteManifestGQL: ShipmentPrintRouteManifestGQL,
    private shipmentPrintRouteSummaryGQL: ShipmentPrintRouteSummaryGQL,
    private shipmentPrintRoyalMailReportGQL: ShipmentPrintRoyalMailReportGQL,
  ) {}

  fetchRow(id: number) {
    return this.shipmentRowGQL.fetch({ id }).pipe(mapFetchResultData((r) => r.shipment));
  }

  fetchDetail(id: number): Observable<ShipmentObject> {
    return this.shipmentDetailGQL.fetch({ id }).pipe(map((r) => r.data.shipment));
  }

  fetchByOrderId(id: number): Observable<ShipmentObject | null | undefined> {
    return this.orderShipmentsGQL.fetch({ id }).pipe(map((r) => r.data.order.shipment));
  }

  createShipmentParcel(input: CreateShipmentParcelInput): Observable<ShipmentParcelObject> {
    return this.createShipmentParcelGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createShipmentParcel));
  }

  createParcel(input: CreateShipmentParcelInput): Observable<number> {
    return this.createShipmentParcelGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.createShipmentParcel.id));
  }

  updateParcel(input: UpdateShipmentParcelInput): Observable<number> {
    return this.updateShipmentParcelGQL
      .mutate({ input })
      .pipe(mapFetchResultData((data) => data.updateShipmentParcel.id));
  }

  removeParcel(id: number): Observable<number> {
    return this.removeShipmentParcelGQL
      .mutate({ id })
      .pipe(mapFetchResultData((data) => data.removeShipmentParcel));
  }

  shipmentParcelById(id: number): Observable<ShipmentParcelObject> {
    return this.shipmentParcelByIdGQL
      .fetch({ id })
      .pipe(mapFetchResultData((data) => data.shipmentParcel));
  }

  printOrderManifest(filter: ShipmentFilterInput) {
    return this.printShipmentOrderManifestGQL
      .mutate({ filter })
      .pipe(mapFetchResultData((d) => d.printShipmentOrderManifest));
  }

  printShipmentOrderGiftMessages(filter: ShipmentFilterInput) {
    return this.printShipmentOrderGiftMessageGQL
      .mutate({ filter })
      .pipe(mapFetchResultData((d) => d.printShipmentOrderGiftMessage));
  }

  printShipmentLabels(filter: ShipmentFilterInput) {
    return this.printShipmentLabelsGQL
      .mutate({ filter })
      .pipe(mapFetchResultData((d) => d.printShipmentLabels));
  }

  printRouteManifest(shipmentFilter: ShipmentFilterInput, stockItemFilter: StockItemFilterInput) {
    return this.shipmentPrintRouteManifestGQL
      .mutate({ shipmentFilter, stockItemFilter })
      .pipe(mapFetchResultData((d) => d.shipmentsPrintRouteManifest));
  }

  printRouteSummary(shipmentFilter: ShipmentFilterInput, stockItemFilter: StockItemFilterInput) {
    return this.shipmentPrintRouteSummaryGQL
      .mutate({ shipmentFilter, stockItemFilter })
      .pipe(mapFetchResultData((d) => d.shipmentsPrintRouteSummary));
  }

  // Exports
  printRoyalMailSpreadsheet(shipmentFilter: ShipmentFilterInput, format?: string) {
    return this.shipmentPrintRoyalMailReportGQL
      .mutate({ shipmentFilter, format })
      .pipe(mapFetchResultData((d) => d.shipmentPrintRoyalMailReport));
  }

  // submitShipmentWaybill(orderId: number) {
  //   return this.submitOrderShipmentWaybillGQL
  //     .mutate({ orderId })
  //     .pipe(mapFetchResultData((r) => r.submitOrderShipmentWaybill));
  // }

  // shipShipment(orderId: number, sendNotification?: boolean) {
  //   return this.shipOrderShipmentGQL
  //     .mutate({ orderId, sendNotification })
  //     .pipe(mapFetchResultData((r) => r.shipOrderShipment));
  // }

  // deliverShipment(orderId: number, sendNotification?: boolean) {
  //   return this.deliverOrderShipmentGql
  //     .mutate({ orderId })
  //     .pipe(mapFetchResultData((r) => r.deliverOrderShipment));
  // }

  // cancelShipment(orderId: number) {
  //   return this.cancelOrderShipmentGql
  //     .mutate({ orderId })
  //     .pipe(mapFetchResultData((r) => r.cancelOrderShipment));
  // }

  transitionShipment(input: TransitionShipmentInput) {
    return this.transitionShipmentGQL
      .mutate({ input })
      .pipe(mapFetchResultData((r) => r.transitionShipment));
  }

  fetchRows(
    query: ShipmentRowsQueryVariables,
  ): Observable<{ items: ShipmentRowObject[]; totalItemsCount: number }> {
    return this.shipmentRowsGQL.fetch(query).pipe(map((r) => r.data));
  }
}
