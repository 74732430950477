import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { ShippingMethodItemFragmentDoc } from './shipping-method.gql.generated';
import { ShippingScheduleItemFragmentDoc } from './shipping-schedule.gql.generated';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ShippingSlotItemFragment = { __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, availableFrom?: any | null | undefined, availableTo?: any | null | undefined, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined };

export type ShippingSlotRowFragment = { __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, overrides?: Array<string> | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, shipmentStateCounts: Array<{ __typename: 'ShipmentStateCountObject', state: Types.ShipmentState, count: number }>, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined };

export type ShippingSlotDetailFragment = { __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, availableFrom?: any | null | undefined, availableTo?: any | null | undefined, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, overrides?: Array<string> | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, shipmentStateCounts: Array<{ __typename: 'ShipmentStateCountObject', state: Types.ShipmentState, count: number }>, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined };

export type ShippingSlotItemQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ShippingSlotItemQuery = { __typename: 'Query', shippingSlot: { __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, availableFrom?: any | null | undefined, availableTo?: any | null | undefined, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined } };

export type ShippingSlotItemsQueryVariables = Types.Exact<{
  filter: Types.ShippingSlotFilterInput;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ShippingSlotItemsQuery = { __typename: 'Query', shippingSlots: Array<{ __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, availableFrom?: any | null | undefined, availableTo?: any | null | undefined, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined }> };

export type ShippingSlotRowQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ShippingSlotRowQuery = { __typename: 'Query', shippingSlot: { __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, overrides?: Array<string> | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, shipmentStateCounts: Array<{ __typename: 'ShipmentStateCountObject', state: Types.ShipmentState, count: number }>, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined } };

export type ShippingSlotRowsQueryVariables = Types.Exact<{
  filter: Types.ShippingSlotFilterInput;
  page?: Types.InputMaybe<Types.PageInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ShippingSlotRowsQuery = { __typename: 'Query', shippingSlots: Array<{ __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, overrides?: Array<string> | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, shipmentStateCounts: Array<{ __typename: 'ShipmentStateCountObject', state: Types.ShipmentState, count: number }>, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined }> };

export type ShippingSlotDetailQueryVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
}>;


export type ShippingSlotDetailQuery = { __typename: 'Query', shippingSlot: { __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, availableFrom?: any | null | undefined, availableTo?: any | null | undefined, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, overrides?: Array<string> | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, shipmentStateCounts: Array<{ __typename: 'ShipmentStateCountObject', state: Types.ShipmentState, count: number }>, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined } };

export type UpdateShippingSlotMutationVariables = Types.Exact<{
  input: Types.UpdateShippingSlotInput;
}>;


export type UpdateShippingSlotMutation = { __typename: 'Mutation', updateShippingSlot: { __typename: 'ShippingSlotObject', id: string } };

export type CreateShippingSlotMutationVariables = Types.Exact<{
  input: Types.CreateShippingSlotInput;
}>;


export type CreateShippingSlotMutation = { __typename: 'Mutation', createShippingSlot: { __typename: 'ShippingSlotObject', id: string, priority: number, capacity: number, startAt: any, endAt: any, availableFrom?: any | null | undefined, availableTo?: any | null | undefined, leadTime?: any | null | undefined, lagTime?: any | null | undefined, createdAt: any, updatedAt: any, enabled: boolean, method: { __typename: 'ShippingMethodObject', id: number, name: string, code: string, slotRequired: boolean, collectionRequired: boolean, provider: string, requiresWaybill: boolean, timezone: string, color?: string | null | undefined, enabled: boolean }, schedule?: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } | null | undefined, availability: { __typename: 'ShippingSlotAvailabilityObject', isAvailable: boolean, unavailableReason?: string | null | undefined, usedCapacity: number, availableCapacity: number }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, label?: string | null | undefined, name: string }> | null | undefined } };

export const ShippingSlotItemFragmentDoc = gql`
    fragment ShippingSlotItem on ShippingSlotObject {
  id
  method {
    ...ShippingMethodItem
  }
  schedule {
    ...ShippingScheduleItem
  }
  priority
  capacity
  availability {
    isAvailable
    unavailableReason
    usedCapacity
    availableCapacity
  }
  startAt
  endAt
  availableFrom
  availableTo
  leadTime
  lagTime
  createdAt
  updatedAt
  enabled
  regions {
    id
    label
    name
  }
}
    ${ShippingMethodItemFragmentDoc}
${ShippingScheduleItemFragmentDoc}`;
export const ShippingSlotRowFragmentDoc = gql`
    fragment ShippingSlotRow on ShippingSlotObject {
  id
  method {
    ...ShippingMethodItem
  }
  schedule {
    ...ShippingScheduleItem
  }
  priority
  capacity
  availability {
    isAvailable
    unavailableReason
    usedCapacity
    availableCapacity
  }
  shipmentStateCounts {
    state
    count
  }
  startAt
  endAt
  leadTime
  lagTime
  createdAt
  updatedAt
  enabled
  overrides
  regions {
    id
    label
    name
  }
}
    ${ShippingMethodItemFragmentDoc}
${ShippingScheduleItemFragmentDoc}`;
export const ShippingSlotDetailFragmentDoc = gql`
    fragment ShippingSlotDetail on ShippingSlotObject {
  id
  method {
    ...ShippingMethodItem
  }
  schedule {
    ...ShippingScheduleItem
  }
  priority
  capacity
  availability {
    isAvailable
    unavailableReason
    usedCapacity
    availableCapacity
  }
  shipmentStateCounts {
    state
    count
  }
  startAt
  endAt
  availableFrom
  availableTo
  leadTime
  lagTime
  createdAt
  updatedAt
  enabled
  overrides
  regions {
    id
    label
    name
  }
}
    ${ShippingMethodItemFragmentDoc}
${ShippingScheduleItemFragmentDoc}`;
export const ShippingSlotItemDocument = gql`
    query ShippingSlotItem($id: String!) {
  shippingSlot(id: $id) {
    ...ShippingSlotItem
  }
}
    ${ShippingSlotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingSlotItemGQL extends Apollo.Query<ShippingSlotItemQuery, ShippingSlotItemQueryVariables> {
    document = ShippingSlotItemDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingSlotItemsDocument = gql`
    query ShippingSlotItems($filter: ShippingSlotFilterInput!, $page: PageInput, $sort: SortInput) {
  shippingSlots(filter: $filter, page: $page, sort: $sort) {
    ...ShippingSlotItem
  }
}
    ${ShippingSlotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingSlotItemsGQL extends Apollo.Query<ShippingSlotItemsQuery, ShippingSlotItemsQueryVariables> {
    document = ShippingSlotItemsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingSlotRowDocument = gql`
    query ShippingSlotRow($id: String!) {
  shippingSlot(id: $id) {
    ...ShippingSlotRow
  }
}
    ${ShippingSlotRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingSlotRowGQL extends Apollo.Query<ShippingSlotRowQuery, ShippingSlotRowQueryVariables> {
    document = ShippingSlotRowDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingSlotRowsDocument = gql`
    query ShippingSlotRows($filter: ShippingSlotFilterInput!, $page: PageInput, $sort: SortInput) {
  shippingSlots(filter: $filter, page: $page, sort: $sort) {
    ...ShippingSlotRow
  }
}
    ${ShippingSlotRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingSlotRowsGQL extends Apollo.Query<ShippingSlotRowsQuery, ShippingSlotRowsQueryVariables> {
    document = ShippingSlotRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingSlotDetailDocument = gql`
    query ShippingSlotDetail($id: String!) {
  shippingSlot(id: $id) {
    ...ShippingSlotDetail
  }
}
    ${ShippingSlotDetailFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingSlotDetailGQL extends Apollo.Query<ShippingSlotDetailQuery, ShippingSlotDetailQueryVariables> {
    document = ShippingSlotDetailDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShippingSlotDocument = gql`
    mutation UpdateShippingSlot($input: UpdateShippingSlotInput!) {
  updateShippingSlot(input: $input) {
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShippingSlotGQL extends Apollo.Mutation<UpdateShippingSlotMutation, UpdateShippingSlotMutationVariables> {
    document = UpdateShippingSlotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShippingSlotDocument = gql`
    mutation CreateShippingSlot($input: CreateShippingSlotInput!) {
  createShippingSlot(input: $input) {
    ...ShippingSlotItem
  }
}
    ${ShippingSlotItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShippingSlotGQL extends Apollo.Mutation<CreateShippingSlotMutation, CreateShippingSlotMutationVariables> {
    document = CreateShippingSlotDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }