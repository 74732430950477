import * as Types from '@x/schemas/ecommerce';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type ShippingScheduleItemFragment = { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined };

export type ShippingScheduleRowFragment = { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined };

export type ShippingScheduleByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['Int']['input'];
}>;


export type ShippingScheduleByIdQuery = { __typename: 'Query', shippingSchedule: { __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number, name: string }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined } };

export type ShippingScheduleRowsQueryVariables = Types.Exact<{
  page?: Types.InputMaybe<Types.PageInput>;
  filter?: Types.InputMaybe<Types.ShippingScheduleFilterInput>;
  sort?: Types.InputMaybe<Types.SortInput>;
}>;


export type ShippingScheduleRowsQuery = { __typename: 'Query', totalItemsCount: number, items: Array<{ __typename: 'ShippingScheduleObject', id: number, name: string, code: string, daysOfWeek?: string | null | undefined, startTime: string, duration: any, capacity: number, priority: number, leadTime?: any | null | undefined, lagTime?: any | null | undefined, startAt?: any | null | undefined, endAt?: any | null | undefined, enabled: boolean, regionIds?: any | null | undefined, method: { __typename: 'ShippingMethodObject', id: number }, regions?: Array<{ __typename: 'GeoRegionObject', id: number, name: string, code: string }> | null | undefined }> };

export type CreateShippingScheduleMutationVariables = Types.Exact<{
  input: Types.CreateShippingScheduleInput;
}>;


export type CreateShippingScheduleMutation = { __typename: 'Mutation', createShippingSchedule: { __typename: 'ShippingScheduleObject', id: number, code: string, name: string } };

export type UpdateShippingScheduleMutationVariables = Types.Exact<{
  input: Types.UpdateShippingScheduleInput;
}>;


export type UpdateShippingScheduleMutation = { __typename: 'Mutation', updateShippingSchedule: { __typename: 'ShippingScheduleObject', id: number, code: string, name: string } };

export const ShippingScheduleItemFragmentDoc = gql`
    fragment ShippingScheduleItem on ShippingScheduleObject {
  id
  name
  code
  daysOfWeek
  startTime
  duration
  capacity
  priority
  leadTime
  lagTime
  startAt
  endAt
  enabled
  method {
    id
    name
  }
  regionIds
  regions {
    id
    name
    code
  }
}
    `;
export const ShippingScheduleRowFragmentDoc = gql`
    fragment ShippingScheduleRow on ShippingScheduleObject {
  id
  name
  code
  daysOfWeek
  startTime
  duration
  capacity
  priority
  leadTime
  lagTime
  startAt
  endAt
  enabled
  method {
    id
  }
  regionIds
  regions {
    id
    name
    code
  }
}
    `;
export const ShippingScheduleByIdDocument = gql`
    query ShippingScheduleById($id: Int!) {
  shippingSchedule(id: $id) {
    ...ShippingScheduleItem
  }
}
    ${ShippingScheduleItemFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingScheduleByIdGQL extends Apollo.Query<ShippingScheduleByIdQuery, ShippingScheduleByIdQueryVariables> {
    document = ShippingScheduleByIdDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ShippingScheduleRowsDocument = gql`
    query ShippingScheduleRows($page: PageInput, $filter: ShippingScheduleFilterInput, $sort: SortInput) {
  items: shippingSchedules(page: $page, filter: $filter, sort: $sort) {
    ...ShippingScheduleRow
  }
  totalItemsCount: shippingSchedulesCount(filter: $filter)
}
    ${ShippingScheduleRowFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class ShippingScheduleRowsGQL extends Apollo.Query<ShippingScheduleRowsQuery, ShippingScheduleRowsQueryVariables> {
    document = ShippingScheduleRowsDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const CreateShippingScheduleDocument = gql`
    mutation CreateShippingSchedule($input: CreateShippingScheduleInput!) {
  createShippingSchedule(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateShippingScheduleGQL extends Apollo.Mutation<CreateShippingScheduleMutation, CreateShippingScheduleMutationVariables> {
    document = CreateShippingScheduleDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const UpdateShippingScheduleDocument = gql`
    mutation UpdateShippingSchedule($input: UpdateShippingScheduleInput!) {
  updateShippingSchedule(input: $input) {
    id
    code
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class UpdateShippingScheduleGQL extends Apollo.Mutation<UpdateShippingScheduleMutation, UpdateShippingScheduleMutationVariables> {
    document = UpdateShippingScheduleDocument;
    client = 'ecommerce';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }