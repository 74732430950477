import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {
  ShippingSlotDialogComponent,
  ShippingSlotDialogData,
  ShippingSlotDialogResult,
} from '../components/shipping-slot-dialog/shipping-slot-dialog.component';

@Injectable()
export class ShippingSlotDialogService {
  constructor(private dialogs: MatDialog) {}

  openShippingSlotDialog(data?: ShippingSlotDialogData) {
    return this.dialogs.open<
      ShippingSlotDialogComponent,
      ShippingSlotDialogData,
      ShippingSlotDialogResult
    >(ShippingSlotDialogComponent, {
      data,
      width: '500px',
    });
  }
}
