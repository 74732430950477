export type Maybe<T> = T | null | undefined;
export type InputMaybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  BlogIndexHeaderDynamicZoneInput: { input: any; output: any; }
  BrochureHomeBodyDynamicZoneInput: { input: any; output: any; }
  BrochurePageBodyDynamicZoneInput: { input: any; output: any; }
  ChannelPageBodyDynamicZoneInput: { input: any; output: any; }
  ChannelPageChannelFooterDynamicZoneInput: { input: any; output: any; }
  DateTime: { input: any; output: any; }
  EventIndexHeaderDynamicZoneInput: { input: any; output: any; }
  I18NLocaleCode: { input: any; output: any; }
  JSON: { input: any; output: any; }
  LandingPageBodyDynamicZoneInput: { input: any; output: any; }
  MembershipPageMembershipFooterDynamicZoneInput: { input: any; output: any; }
  MembershipPageMembershipHeaderDynamicZoneInput: { input: any; output: any; }
  ProductPageProductFooterDynamicZoneInput: { input: any; output: any; }
  ProductPageProductHeaderDynamicZoneInput: { input: any; output: any; }
  SubscriptionPageSubscriptionFooterDynamicZoneInput: { input: any; output: any; }
  SubscriptionPageSubscriptionHeaderDynamicZoneInput: { input: any; output: any; }
  TaxonPageTaxonFooterDynamicZoneInput: { input: any; output: any; }
  TaxonPageTaxonHeaderDynamicZoneInput: { input: any; output: any; }
  Upload: { input: any; output: any; }
}

export enum BackgroundColor {
  Accent = 'accent',
  Light = 'light',
  Primary = 'primary',
  Transparent = 'transparent',
  Warn = 'warn'
}

export interface BlogIndex {
  __typename: 'BlogIndex';
  collectionFilter: ComponentCommonCollectionFilter;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  header?: Maybe<Array<Maybe<BlogIndexHeaderDynamicZone>>>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<BlogIndexRelationResponseCollection>;
  metaData: ComponentCommonMetaData;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface BlogIndexEntity {
  __typename: 'BlogIndexEntity';
  attributes?: Maybe<BlogIndex>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface BlogIndexEntityResponse {
  __typename: 'BlogIndexEntityResponse';
  data?: Maybe<BlogIndexEntity>;
}

export type BlogIndexHeaderDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureBanner | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureFaq | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | Error;

export interface BlogIndexInput {
  collectionFilter?: InputMaybe<ComponentCommonCollectionFilterInput>;
  header?: InputMaybe<Array<Scalars['BlogIndexHeaderDynamicZoneInput']['input']>>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface BlogIndexRelationResponseCollection {
  __typename: 'BlogIndexRelationResponseCollection';
  data: Array<BlogIndexEntity>;
}

export interface BlogPage {
  __typename: 'BlogPage';
  body: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  image?: Maybe<UploadFileEntityResponse>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<BlogPageRelationResponseCollection>;
  metaData: ComponentCommonMetaData;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface BlogPageLocalizationsArgs {
  filters?: InputMaybe<BlogPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface BlogPageEntity {
  __typename: 'BlogPageEntity';
  attributes?: Maybe<BlogPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface BlogPageEntityResponse {
  __typename: 'BlogPageEntityResponse';
  data?: Maybe<BlogPageEntity>;
}

export interface BlogPageEntityResponseCollection {
  __typename: 'BlogPageEntityResponseCollection';
  data: Array<BlogPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface BlogPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<BlogPageFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<BlogPageFiltersInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  not?: InputMaybe<BlogPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BlogPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface BlogPageInput {
  body?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface BlogPageRelationResponseCollection {
  __typename: 'BlogPageRelationResponseCollection';
  data: Array<BlogPageEntity>;
}

export interface BooleanFilterInput {
  and?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  contains?: InputMaybe<Scalars['Boolean']['input']>;
  containsi?: InputMaybe<Scalars['Boolean']['input']>;
  endsWith?: InputMaybe<Scalars['Boolean']['input']>;
  eq?: InputMaybe<Scalars['Boolean']['input']>;
  eqi?: InputMaybe<Scalars['Boolean']['input']>;
  gt?: InputMaybe<Scalars['Boolean']['input']>;
  gte?: InputMaybe<Scalars['Boolean']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  lt?: InputMaybe<Scalars['Boolean']['input']>;
  lte?: InputMaybe<Scalars['Boolean']['input']>;
  ne?: InputMaybe<Scalars['Boolean']['input']>;
  not?: InputMaybe<BooleanFilterInput>;
  notContains?: InputMaybe<Scalars['Boolean']['input']>;
  notContainsi?: InputMaybe<Scalars['Boolean']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Boolean']['input']>>>;
  startsWith?: InputMaybe<Scalars['Boolean']['input']>;
}

export enum BrochureBannerLayout {
  Full = 'full',
  Narrow = 'narrow',
  Padded = 'padded'
}

export enum BrochureButtonAlignment {
  Center = 'center',
  End = 'end',
  Start = 'start'
}

export enum BrochureButtonAppearance {
  Brand = 'brand',
  Fill = 'fill',
  Link = 'link',
  Outline = 'outline'
}

export enum BrochureButtonColor {
  Accent = 'accent',
  Default = 'default',
  Primary = 'primary',
  Warn = 'warn'
}

export enum BrochureCarouselLayout {
  Full = 'full',
  Half = 'half',
  Sidebar = 'sidebar'
}

export enum BrochureCarouselType {
  Banner = 'banner',
  Default = 'default',
  Tickertape = 'tickertape'
}

export interface BrochureHome {
  __typename: 'BrochureHome';
  body: Array<Maybe<BrochureHomeBodyDynamicZone>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<BrochureHomeRelationResponseCollection>;
  metaData: ComponentCommonMetaData;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export type BrochureHomeBodyDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | Error;

export interface BrochureHomeEntity {
  __typename: 'BrochureHomeEntity';
  attributes?: Maybe<BrochureHome>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface BrochureHomeEntityResponse {
  __typename: 'BrochureHomeEntityResponse';
  data?: Maybe<BrochureHomeEntity>;
}

export interface BrochureHomeInput {
  body?: InputMaybe<Array<Scalars['BrochureHomeBodyDynamicZoneInput']['input']>>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface BrochureHomeRelationResponseCollection {
  __typename: 'BrochureHomeRelationResponseCollection';
  data: Array<BrochureHomeEntity>;
}

export interface BrochureNavCategory {
  __typename: 'BrochureNavCategory';
  brochureNavItems?: Maybe<BrochureNavItemRelationResponseCollection>;
  categoryName: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface BrochureNavCategoryBrochureNavItemsArgs {
  filters?: InputMaybe<BrochureNavItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface BrochureNavCategoryEntity {
  __typename: 'BrochureNavCategoryEntity';
  attributes?: Maybe<BrochureNavCategory>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface BrochureNavCategoryEntityResponse {
  __typename: 'BrochureNavCategoryEntityResponse';
  data?: Maybe<BrochureNavCategoryEntity>;
}

export interface BrochureNavCategoryEntityResponseCollection {
  __typename: 'BrochureNavCategoryEntityResponseCollection';
  data: Array<BrochureNavCategoryEntity>;
  meta: ResponseCollectionMeta;
}

export interface BrochureNavCategoryFiltersInput {
  and?: InputMaybe<Array<InputMaybe<BrochureNavCategoryFiltersInput>>>;
  brochureNavItems?: InputMaybe<BrochureNavItemFiltersInput>;
  categoryName?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<BrochureNavCategoryFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BrochureNavCategoryFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface BrochureNavCategoryInput {
  brochureNavItems?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  categoryName?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface BrochureNavItem {
  __typename: 'BrochureNavItem';
  brochureNavCategory?: Maybe<BrochureNavCategoryEntityResponse>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  linkPosition: Enum_Brochurenavitem_Linkposition;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  text?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface BrochureNavItemEntity {
  __typename: 'BrochureNavItemEntity';
  attributes?: Maybe<BrochureNavItem>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface BrochureNavItemEntityResponse {
  __typename: 'BrochureNavItemEntityResponse';
  data?: Maybe<BrochureNavItemEntity>;
}

export interface BrochureNavItemEntityResponseCollection {
  __typename: 'BrochureNavItemEntityResponseCollection';
  data: Array<BrochureNavItemEntity>;
  meta: ResponseCollectionMeta;
}

export interface BrochureNavItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<BrochureNavItemFiltersInput>>>;
  brochureNavCategory?: InputMaybe<BrochureNavCategoryFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  linkPosition?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<BrochureNavItemFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<BrochureNavItemFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
}

export interface BrochureNavItemInput {
  brochureNavCategory?: InputMaybe<Scalars['ID']['input']>;
  linkPosition?: InputMaybe<Enum_Brochurenavitem_Linkposition>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface BrochureNavItemRelationResponseCollection {
  __typename: 'BrochureNavItemRelationResponseCollection';
  data: Array<BrochureNavItemEntity>;
}

export interface BrochurePage {
  __typename: 'BrochurePage';
  body: Array<Maybe<BrochurePageBodyDynamicZone>>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<BrochurePageRelationResponseCollection>;
  metaData: ComponentCommonMetaData;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface BrochurePageLocalizationsArgs {
  filters?: InputMaybe<BrochurePageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export type BrochurePageBodyDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | Error;

export interface BrochurePageEntity {
  __typename: 'BrochurePageEntity';
  attributes?: Maybe<BrochurePage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface BrochurePageEntityResponse {
  __typename: 'BrochurePageEntityResponse';
  data?: Maybe<BrochurePageEntity>;
}

export interface BrochurePageEntityResponseCollection {
  __typename: 'BrochurePageEntityResponseCollection';
  data: Array<BrochurePageEntity>;
  meta: ResponseCollectionMeta;
}

export interface BrochurePageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<BrochurePageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<BrochurePageFiltersInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  not?: InputMaybe<BrochurePageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<BrochurePageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface BrochurePageInput {
  body?: InputMaybe<Array<Scalars['BrochurePageBodyDynamicZoneInput']['input']>>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface BrochurePageRelationResponseCollection {
  __typename: 'BrochurePageRelationResponseCollection';
  data: Array<BrochurePageEntity>;
}

export enum BrochureSectionTextPosition {
  End = 'end',
  Start = 'start'
}

export enum BrochureSectionWithImageLayout {
  Half = 'half',
  Sidebar = 'sidebar'
}

export enum BrochureVerticalSpace {
  Size_10px = 'size_10px',
  Size_30px = 'size_30px',
  Size_50px = 'size_50px',
  Size_100px = 'size_100px'
}

export interface ChannelPage {
  __typename: 'ChannelPage';
  body?: Maybe<Array<Maybe<ChannelPageBodyDynamicZone>>>;
  channelFooter?: Maybe<Array<Maybe<ChannelPageChannelFooterDynamicZone>>>;
  channelNotification?: Maybe<ComponentShopChannelNotification>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  livestream?: Maybe<ComponentCommonLivestreamControl>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ChannelPageRelationResponseCollection>;
  metaData?: Maybe<ComponentCommonMetaData>;
  name?: Maybe<Scalars['String']['output']>;
  navItems?: Maybe<Array<Maybe<ComponentShopNavigationItem>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface ChannelPageLocalizationsArgs {
  filters?: InputMaybe<ChannelPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface ChannelPageNavItemsArgs {
  filters?: InputMaybe<ComponentShopNavigationItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export type ChannelPageBodyDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export type ChannelPageChannelFooterDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopFooterLinkComponent | ComponentShopProductListing | Error;

export interface ChannelPageEntity {
  __typename: 'ChannelPageEntity';
  attributes?: Maybe<ChannelPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface ChannelPageEntityResponse {
  __typename: 'ChannelPageEntityResponse';
  data?: Maybe<ChannelPageEntity>;
}

export interface ChannelPageEntityResponseCollection {
  __typename: 'ChannelPageEntityResponseCollection';
  data: Array<ChannelPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface ChannelPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ChannelPageFiltersInput>>>;
  channelNotification?: InputMaybe<ComponentShopChannelNotificationFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  livestream?: InputMaybe<ComponentCommonLivestreamControlFiltersInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ChannelPageFiltersInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  navItems?: InputMaybe<ComponentShopNavigationItemFiltersInput>;
  not?: InputMaybe<ChannelPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ChannelPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface ChannelPageInput {
  body?: InputMaybe<Array<Scalars['ChannelPageBodyDynamicZoneInput']['input']>>;
  channelFooter?: InputMaybe<Array<Scalars['ChannelPageChannelFooterDynamicZoneInput']['input']>>;
  channelNotification?: InputMaybe<ComponentShopChannelNotificationInput>;
  livestream?: InputMaybe<ComponentCommonLivestreamControlInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  navItems?: InputMaybe<Array<InputMaybe<ComponentShopNavigationItemInput>>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export interface ChannelPageRelationResponseCollection {
  __typename: 'ChannelPageRelationResponseCollection';
  data: Array<ChannelPageEntity>;
}

export enum CollectionSortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export interface ComponentBrochureAccordion {
  __typename: 'ComponentBrochureAccordion';
  description?: Maybe<Scalars['String']['output']>;
  expansionPanel?: Maybe<Array<Maybe<ComponentBrochureExpansionPanel>>>;
  id: Scalars['ID']['output'];
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureAccordionExpansionPanelArgs {
  filters?: InputMaybe<ComponentBrochureExpansionPanelFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureActionBanner {
  __typename: 'ComponentBrochureActionBanner';
  actionBannerItems: Array<Maybe<ComponentBrochureActionBannerItem>>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureActionBannerActionBannerItemsArgs {
  filters?: InputMaybe<ComponentBrochureActionBannerItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureActionBannerItem {
  __typename: 'ComponentBrochureActionBannerItem';
  id: Scalars['ID']['output'];
  media?: Maybe<UploadFileEntityResponse>;
  openInNewTab: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureActionBannerItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureActionBannerItemFiltersInput>>>;
  not?: InputMaybe<ComponentBrochureActionBannerItemFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureActionBannerItemFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureActionGrid {
  __typename: 'ComponentBrochureActionGrid';
  actionGridItems: Array<Maybe<ComponentBrochureActionGridItem>>;
  background?: Maybe<ComponentThemeBackground>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureActionGridActionGridItemsArgs {
  filters?: InputMaybe<ComponentBrochureActionGridItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureActionGridItem {
  __typename: 'ComponentBrochureActionGridItem';
  emphasize: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  media: UploadFileEntityResponse;
  openInNewTab: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureActionGridItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureActionGridItemFiltersInput>>>;
  emphasize?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentBrochureActionGridItemFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureActionGridItemFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureActionList {
  __typename: 'ComponentBrochureActionList';
  actionListItems: Array<Maybe<ComponentBrochureActionListItem>>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureActionListActionListItemsArgs {
  filters?: InputMaybe<ComponentBrochureActionListItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureActionListItem {
  __typename: 'ComponentBrochureActionListItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<UploadFileEntityResponse>;
  openInNewTab: Scalars['Boolean']['output'];
  text?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureActionListItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureActionListItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentBrochureActionListItemFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureActionListItemFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureAnchor {
  __typename: 'ComponentBrochureAnchor';
  anchorId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
}

export interface ComponentBrochureBanner {
  __typename: 'ComponentBrochureBanner';
  background?: Maybe<ComponentThemeBackground>;
  bannerHeight: Enum_Componentbrochurebanner_Bannerheight;
  bannerLayout: BrochureBannerLayout;
  bannerType?: Maybe<Enum_Componentbrochurebanner_Bannertype>;
  buttons?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  media: UploadFileEntityResponse;
  secondaryMedia?: Maybe<UploadFileEntityResponse>;
  title?: Maybe<Scalars['String']['output']>;
  useSecondaryMediaForMobile?: Maybe<Scalars['Boolean']['output']>;
  videoConfig?: Maybe<ComponentCommonVideoConfig>;
  whiteText?: Maybe<Scalars['Boolean']['output']>;
}


export interface ComponentBrochureBannerButtonsArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureBlogListing {
  __typename: 'ComponentBrochureBlogListing';
  background?: Maybe<ComponentThemeBackground>;
  collectionFilter: ComponentCommonCollectionFilter;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureButton {
  __typename: 'ComponentBrochureButton';
  alignment: BrochureButtonAlignment;
  appearance: BrochureButtonAppearance;
  color: BrochureButtonColor;
  id: Scalars['ID']['output'];
  openInNewTab: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface ComponentBrochureButtonFiltersInput {
  alignment?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureButtonFiltersInput>>>;
  appearance?: InputMaybe<StringFilterInput>;
  color?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureButtonFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureCardGrid {
  __typename: 'ComponentBrochureCardGrid';
  background?: Maybe<ComponentThemeBackground>;
  cardGridType?: Maybe<Enum_Componentbrochurecardgrid_Cardgridtype>;
  cardItems: Array<Maybe<ComponentBrochureCardGridItem>>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureCardGridCardItemsArgs {
  filters?: InputMaybe<ComponentBrochureCardGridItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureCardGridItem {
  __typename: 'ComponentBrochureCardGridItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  image?: Maybe<UploadFileEntityResponse>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureCardGridItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureCardGridItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentBrochureCardGridItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureCardGridItemFiltersInput>>>;
  url?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureCarousel {
  __typename: 'ComponentBrochureCarousel';
  background?: Maybe<ComponentThemeBackground>;
  carouselItems: Array<Maybe<ComponentBrochureCarouselItem>>;
  carouselLayout?: Maybe<BrochureCarouselLayout>;
  carouselType: BrochureCarouselType;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureCarouselCarouselItemsArgs {
  filters?: InputMaybe<ComponentBrochureCarouselItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureCarouselItem {
  __typename: 'ComponentBrochureCarouselItem';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media: UploadFileEntityResponse;
  title?: Maybe<Scalars['String']['output']>;
  url?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureCarouselItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureCarouselItemFiltersInput>>>;
  description?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentBrochureCarouselItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureCarouselItemFiltersInput>>>;
  title?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureEventListing {
  __typename: 'ComponentBrochureEventListing';
  background?: Maybe<ComponentThemeBackground>;
  collectionFilter: ComponentCommonCollectionFilter;
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureExpansionPanel {
  __typename: 'ComponentBrochureExpansionPanel';
  id: Scalars['ID']['output'];
  text?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
}

export interface ComponentBrochureExpansionPanelFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureExpansionPanelFiltersInput>>>;
  not?: InputMaybe<ComponentBrochureExpansionPanelFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureExpansionPanelFiltersInput>>>;
  text?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureFaq {
  __typename: 'ComponentBrochureFaq';
  background: ComponentThemeBackground;
  description?: Maybe<Scalars['String']['output']>;
  faqItems: Array<Maybe<ComponentBrochureFaqItem>>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureFaqFaqItemsArgs {
  filters?: InputMaybe<ComponentBrochureFaqItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureFaqItem {
  __typename: 'ComponentBrochureFaqItem';
  answer: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  question: Scalars['String']['output'];
}

export interface ComponentBrochureFaqItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentBrochureFaqItemFiltersInput>>>;
  answer?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentBrochureFaqItemFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentBrochureFaqItemFiltersInput>>>;
  question?: InputMaybe<StringFilterInput>;
}

export interface ComponentBrochureHtmlContainer {
  __typename: 'ComponentBrochureHtmlContainer';
  content?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
}

export interface ComponentBrochureNavigationItem {
  __typename: 'ComponentBrochureNavigationItem';
  id: Scalars['ID']['output'];
  openInNewTab: Scalars['Boolean']['output'];
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface ComponentBrochureSectionOne {
  __typename: 'ComponentBrochureSectionOne';
  background?: Maybe<ComponentThemeBackground>;
  buttons?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureSectionOneButtonsArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureSectionThree {
  __typename: 'ComponentBrochureSectionThree';
  background?: Maybe<ComponentThemeBackground>;
  buttonOne?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  buttonThree?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  buttonTwo?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  descriptionOne?: Maybe<Scalars['String']['output']>;
  descriptionThree?: Maybe<Scalars['String']['output']>;
  descriptionTwo?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureSectionThreeButtonOneArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface ComponentBrochureSectionThreeButtonThreeArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface ComponentBrochureSectionThreeButtonTwoArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureSectionTwo {
  __typename: 'ComponentBrochureSectionTwo';
  background?: Maybe<ComponentThemeBackground>;
  buttonOne?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  buttonTwo?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  descriptionOne?: Maybe<Scalars['String']['output']>;
  descriptionTwo?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentBrochureSectionTwoButtonOneArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface ComponentBrochureSectionTwoButtonTwoArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureSectionWithImage {
  __typename: 'ComponentBrochureSectionWithImage';
  background?: Maybe<ComponentThemeBackground>;
  buttons?: Maybe<Array<Maybe<ComponentBrochureButton>>>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  height: Enum_Componentbrochuresectionwithimage_Height;
  id: Scalars['ID']['output'];
  isNarrow: Scalars['Boolean']['output'];
  layout: ComponentThemeLayout;
  media: UploadFileEntityResponse;
  secondaryMedia?: Maybe<UploadFileEntityResponse>;
  sectionWithImageLayout: BrochureSectionTextPosition;
  sectionWithImageType?: Maybe<BrochureSectionWithImageLayout>;
  title?: Maybe<Scalars['String']['output']>;
  useSecondaryMediaForMobile?: Maybe<Scalars['Boolean']['output']>;
  videoConfig?: Maybe<ComponentCommonVideoConfig>;
}


export interface ComponentBrochureSectionWithImageButtonsArgs {
  filters?: InputMaybe<ComponentBrochureButtonFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentBrochureSpacer {
  __typename: 'ComponentBrochureSpacer';
  background?: Maybe<ComponentThemeBackground>;
  id: Scalars['ID']['output'];
  verticalSpace: BrochureVerticalSpace;
}

export interface ComponentCommonCollectionFilter {
  __typename: 'ComponentCommonCollectionFilter';
  filter?: Maybe<Scalars['JSON']['output']>;
  id: Scalars['ID']['output'];
  pageSize: Scalars['Int']['output'];
  sortColumn: Scalars['String']['output'];
  sortOrder: CollectionSortOrder;
}

export interface ComponentCommonCollectionFilterInput {
  filter?: InputMaybe<Scalars['JSON']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  sortColumn?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<CollectionSortOrder>;
}

export interface ComponentCommonDisplayControl {
  __typename: 'ComponentCommonDisplayControl';
  id: Scalars['ID']['output'];
  showOnDevices?: Maybe<Scalars['JSON']['output']>;
}

export interface ComponentCommonLivestreamControl {
  __typename: 'ComponentCommonLivestreamControl';
  channel: Scalars['String']['output'];
  floatingPlayer: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  playlist: Scalars['String']['output'];
}

export interface ComponentCommonLivestreamControlFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentCommonLivestreamControlFiltersInput>>>;
  channel?: InputMaybe<StringFilterInput>;
  floatingPlayer?: InputMaybe<BooleanFilterInput>;
  not?: InputMaybe<ComponentCommonLivestreamControlFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentCommonLivestreamControlFiltersInput>>>;
  playlist?: InputMaybe<StringFilterInput>;
}

export interface ComponentCommonLivestreamControlInput {
  channel?: InputMaybe<Scalars['String']['input']>;
  floatingPlayer?: InputMaybe<Scalars['Boolean']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  playlist?: InputMaybe<Scalars['String']['input']>;
}

export interface ComponentCommonMetaData {
  __typename: 'ComponentCommonMetaData';
  id: Scalars['ID']['output'];
  metaDescription?: Maybe<Scalars['String']['output']>;
  metaImage?: Maybe<UploadFileEntityResponse>;
  metaKeywords?: Maybe<Scalars['String']['output']>;
  metaTitle: Scalars['String']['output'];
}

export interface ComponentCommonMetaDataFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentCommonMetaDataFiltersInput>>>;
  metaDescription?: InputMaybe<StringFilterInput>;
  metaKeywords?: InputMaybe<StringFilterInput>;
  metaTitle?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentCommonMetaDataFiltersInput>>>;
}

export interface ComponentCommonMetaDataInput {
  id?: InputMaybe<Scalars['ID']['input']>;
  metaDescription?: InputMaybe<Scalars['String']['input']>;
  metaImage?: InputMaybe<Scalars['ID']['input']>;
  metaKeywords?: InputMaybe<Scalars['String']['input']>;
  metaTitle?: InputMaybe<Scalars['String']['input']>;
}

export interface ComponentCommonVideoConfig {
  __typename: 'ComponentCommonVideoConfig';
  id: Scalars['ID']['output'];
  mode?: Maybe<Enum_Componentcommonvideoconfig_Mode>;
}

export interface ComponentShopCatalogReel {
  __typename: 'ComponentShopCatalogReel';
  background?: Maybe<ComponentThemeBackground>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  media: UploadFileEntityResponse;
  onlyShow4Products?: Maybe<Scalars['Boolean']['output']>;
  productCollectionFilter: ComponentShopProductCollectionFilter;
  products?: Maybe<Array<Maybe<ComponentShopProduct>>>;
  videoConfig?: Maybe<ComponentCommonVideoConfig>;
}


export interface ComponentShopCatalogReelProductsArgs {
  filters?: InputMaybe<ComponentShopProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentShopChannelNotification {
  __typename: 'ComponentShopChannelNotification';
  background?: Maybe<ComponentThemeBackground>;
  id: Scalars['ID']['output'];
  notificationText: Scalars['String']['output'];
}

export interface ComponentShopChannelNotificationFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentShopChannelNotificationFiltersInput>>>;
  background?: InputMaybe<ComponentThemeBackgroundFiltersInput>;
  not?: InputMaybe<ComponentShopChannelNotificationFiltersInput>;
  notificationText?: InputMaybe<StringFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentShopChannelNotificationFiltersInput>>>;
}

export interface ComponentShopChannelNotificationInput {
  background?: InputMaybe<ComponentThemeBackgroundInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  notificationText?: InputMaybe<Scalars['String']['input']>;
}

export interface ComponentShopFooterLinkComponent {
  __typename: 'ComponentShopFooterLinkComponent';
  id: Scalars['ID']['output'];
  labelFour?: Maybe<Scalars['String']['output']>;
  labelOne?: Maybe<Scalars['String']['output']>;
  labelThree?: Maybe<Scalars['String']['output']>;
  labelTwo?: Maybe<Scalars['String']['output']>;
  linkCategoryFour?: Maybe<Array<Maybe<ComponentShopNavigationItem>>>;
  linkCategoryOne?: Maybe<Array<Maybe<ComponentShopNavigationItem>>>;
  linkCategoryThree?: Maybe<Array<Maybe<ComponentShopNavigationItem>>>;
  linkCategoryTwo?: Maybe<Array<Maybe<ComponentShopNavigationItem>>>;
}


export interface ComponentShopFooterLinkComponentLinkCategoryFourArgs {
  filters?: InputMaybe<ComponentShopNavigationItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface ComponentShopFooterLinkComponentLinkCategoryOneArgs {
  filters?: InputMaybe<ComponentShopNavigationItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface ComponentShopFooterLinkComponentLinkCategoryThreeArgs {
  filters?: InputMaybe<ComponentShopNavigationItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface ComponentShopFooterLinkComponentLinkCategoryTwoArgs {
  filters?: InputMaybe<ComponentShopNavigationItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentShopNavigationItem {
  __typename: 'ComponentShopNavigationItem';
  icon?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  media?: Maybe<UploadFileEntityResponse>;
  openInNewTab?: Maybe<Scalars['Boolean']['output']>;
  position?: Maybe<Scalars['Int']['output']>;
  taxonId?: Maybe<Scalars['Int']['output']>;
  text: Scalars['String']['output'];
  url: Scalars['String']['output'];
}

export interface ComponentShopNavigationItemFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentShopNavigationItemFiltersInput>>>;
  icon?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentShopNavigationItemFiltersInput>;
  openInNewTab?: InputMaybe<BooleanFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentShopNavigationItemFiltersInput>>>;
  position?: InputMaybe<IntFilterInput>;
  taxonId?: InputMaybe<IntFilterInput>;
  text?: InputMaybe<StringFilterInput>;
  url?: InputMaybe<StringFilterInput>;
}

export interface ComponentShopNavigationItemInput {
  icon?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<Scalars['ID']['input']>;
  openInNewTab?: InputMaybe<Scalars['Boolean']['input']>;
  position?: InputMaybe<Scalars['Int']['input']>;
  taxonId?: InputMaybe<Scalars['Int']['input']>;
  text?: InputMaybe<Scalars['String']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
}

export interface ComponentShopProduct {
  __typename: 'ComponentShopProduct';
  id: Scalars['ID']['output'];
  productId: Scalars['Int']['output'];
}

export interface ComponentShopProductCollectionFilter {
  __typename: 'ComponentShopProductCollectionFilter';
  id: Scalars['ID']['output'];
  productCount: Scalars['Int']['output'];
  sortColumn?: Maybe<Enum_Componentshopproductcollectionfilter_Sortcolumn>;
  sortOrder: ProductCollectionSortOrder;
  taxonId?: Maybe<Scalars['Int']['output']>;
}

export interface ComponentShopProductFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentShopProductFiltersInput>>>;
  not?: InputMaybe<ComponentShopProductFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentShopProductFiltersInput>>>;
  productId?: InputMaybe<IntFilterInput>;
}

export interface ComponentShopProductListing {
  __typename: 'ComponentShopProductListing';
  background?: Maybe<ComponentThemeBackground>;
  description?: Maybe<Scalars['String']['output']>;
  displayControl?: Maybe<ComponentCommonDisplayControl>;
  id: Scalars['ID']['output'];
  layout: ComponentThemeLayout;
  productCollectionFilter: ComponentShopProductCollectionFilter;
  productListingType: Enum_Componentshopproductlisting_Productlistingtype;
  products?: Maybe<Array<Maybe<ComponentShopProduct>>>;
  title?: Maybe<Scalars['String']['output']>;
}


export interface ComponentShopProductListingProductsArgs {
  filters?: InputMaybe<ComponentShopProductFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ComponentThemeBackground {
  __typename: 'ComponentThemeBackground';
  color: BackgroundColor;
  id: Scalars['ID']['output'];
}

export interface ComponentThemeBackgroundFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ComponentThemeBackgroundFiltersInput>>>;
  color?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ComponentThemeBackgroundFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ComponentThemeBackgroundFiltersInput>>>;
}

export interface ComponentThemeBackgroundInput {
  color?: InputMaybe<BackgroundColor>;
  id?: InputMaybe<Scalars['ID']['input']>;
}

export interface ComponentThemeLayout {
  __typename: 'ComponentThemeLayout';
  container?: Maybe<LayoutContainer>;
  id: Scalars['ID']['output'];
  textAlignment?: Maybe<LayoutTextAlignment>;
  textPositionHorizontal?: Maybe<LayoutTextPosition>;
  textPositionVertical?: Maybe<Enum_Componentthemelayout_Textpositionvertical>;
}

export interface DateTimeFilterInput {
  and?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  contains?: InputMaybe<Scalars['DateTime']['input']>;
  containsi?: InputMaybe<Scalars['DateTime']['input']>;
  endsWith?: InputMaybe<Scalars['DateTime']['input']>;
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  eqi?: InputMaybe<Scalars['DateTime']['input']>;
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  ne?: InputMaybe<Scalars['DateTime']['input']>;
  not?: InputMaybe<DateTimeFilterInput>;
  notContains?: InputMaybe<Scalars['DateTime']['input']>;
  notContainsi?: InputMaybe<Scalars['DateTime']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['DateTime']['input']>>>;
  startsWith?: InputMaybe<Scalars['DateTime']['input']>;
}

export enum Enum_Brochurenavitem_Linkposition {
  Sidebar = 'Sidebar',
  SidebarFooter = 'Sidebar_Footer',
  Toolbar = 'Toolbar'
}

export enum Enum_Componentbrochurebanner_Bannerheight {
  Full = 'full',
  Half = 'half',
  Narrow = 'narrow'
}

export enum Enum_Componentbrochurebanner_Bannertype {
  Default = 'default',
  Portrait = 'portrait'
}

export enum Enum_Componentbrochurecardgrid_Cardgridtype {
  Boxed = 'boxed',
  Default = 'default'
}

export enum Enum_Componentbrochuresectionwithimage_Height {
  Full = 'full',
  Half = 'half',
  Narrow = 'narrow'
}

export enum Enum_Componentcommonvideoconfig_Mode {
  AutoplayNoControls = 'Autoplay_No_Controls',
  AutoplayWithControls = 'Autoplay_With_Controls',
  ManualPlay = 'Manual_Play'
}

export enum Enum_Componentshopproductcollectionfilter_Sortcolumn {
  Position = 'position',
  ProductIds = 'productIds'
}

export enum Enum_Componentshopproductlisting_Productlistingtype {
  Carousel = 'carousel',
  List = 'list'
}

export enum Enum_Componentthemelayout_Textpositionvertical {
  Bottom = 'bottom',
  Center = 'center',
  Top = 'top'
}

export interface Error {
  __typename: 'Error';
  code: Scalars['String']['output'];
  message?: Maybe<Scalars['String']['output']>;
}

export interface EventIndex {
  __typename: 'EventIndex';
  collectionFilter: ComponentCommonCollectionFilter;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  header?: Maybe<Array<Maybe<EventIndexHeaderDynamicZone>>>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<EventIndexRelationResponseCollection>;
  metaData: ComponentCommonMetaData;
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface EventIndexEntity {
  __typename: 'EventIndexEntity';
  attributes?: Maybe<EventIndex>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface EventIndexEntityResponse {
  __typename: 'EventIndexEntityResponse';
  data?: Maybe<EventIndexEntity>;
}

export type EventIndexHeaderDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureBanner | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureFaq | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | Error;

export interface EventIndexInput {
  collectionFilter?: InputMaybe<ComponentCommonCollectionFilterInput>;
  header?: InputMaybe<Array<Scalars['EventIndexHeaderDynamicZoneInput']['input']>>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface EventIndexRelationResponseCollection {
  __typename: 'EventIndexRelationResponseCollection';
  data: Array<EventIndexEntity>;
}

export interface EventPage {
  __typename: 'EventPage';
  banner?: Maybe<UploadFileEntityResponse>;
  body: Scalars['String']['output'];
  bookingCtaText: Scalars['String']['output'];
  bookingUrl: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateTimeDescription?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<UploadFileEntityResponse>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<EventPageRelationResponseCollection>;
  metaData: ComponentCommonMetaData;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface EventPageLocalizationsArgs {
  filters?: InputMaybe<EventPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface EventPageEntity {
  __typename: 'EventPageEntity';
  attributes?: Maybe<EventPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface EventPageEntityResponse {
  __typename: 'EventPageEntityResponse';
  data?: Maybe<EventPageEntity>;
}

export interface EventPageEntityResponseCollection {
  __typename: 'EventPageEntityResponseCollection';
  data: Array<EventPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface EventPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<EventPageFiltersInput>>>;
  body?: InputMaybe<StringFilterInput>;
  bookingCtaText?: InputMaybe<StringFilterInput>;
  bookingUrl?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  dateTimeDescription?: InputMaybe<StringFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<EventPageFiltersInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  not?: InputMaybe<EventPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<EventPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface EventPageInput {
  banner?: InputMaybe<Scalars['ID']['input']>;
  body?: InputMaybe<Scalars['String']['input']>;
  bookingCtaText?: InputMaybe<Scalars['String']['input']>;
  bookingUrl?: InputMaybe<Scalars['String']['input']>;
  dateTimeDescription?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  image?: InputMaybe<Scalars['ID']['input']>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface EventPageRelationResponseCollection {
  __typename: 'EventPageRelationResponseCollection';
  data: Array<EventPageEntity>;
}

export interface FileInfoInput {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
}

export interface FloatFilterInput {
  and?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  contains?: InputMaybe<Scalars['Float']['input']>;
  containsi?: InputMaybe<Scalars['Float']['input']>;
  endsWith?: InputMaybe<Scalars['Float']['input']>;
  eq?: InputMaybe<Scalars['Float']['input']>;
  eqi?: InputMaybe<Scalars['Float']['input']>;
  gt?: InputMaybe<Scalars['Float']['input']>;
  gte?: InputMaybe<Scalars['Float']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  lt?: InputMaybe<Scalars['Float']['input']>;
  lte?: InputMaybe<Scalars['Float']['input']>;
  ne?: InputMaybe<Scalars['Float']['input']>;
  not?: InputMaybe<FloatFilterInput>;
  notContains?: InputMaybe<Scalars['Float']['input']>;
  notContainsi?: InputMaybe<Scalars['Float']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Float']['input']>>>;
  startsWith?: InputMaybe<Scalars['Float']['input']>;
}

export type GenericMorph = BlogIndex | BlogPage | BrochureHome | BrochureNavCategory | BrochureNavItem | BrochurePage | ChannelPage | ComponentBrochureAccordion | ComponentBrochureActionBanner | ComponentBrochureActionBannerItem | ComponentBrochureActionGrid | ComponentBrochureActionGridItem | ComponentBrochureActionList | ComponentBrochureActionListItem | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureButton | ComponentBrochureCardGrid | ComponentBrochureCardGridItem | ComponentBrochureCarousel | ComponentBrochureCarouselItem | ComponentBrochureEventListing | ComponentBrochureExpansionPanel | ComponentBrochureFaq | ComponentBrochureFaqItem | ComponentBrochureHtmlContainer | ComponentBrochureNavigationItem | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentCommonCollectionFilter | ComponentCommonDisplayControl | ComponentCommonLivestreamControl | ComponentCommonMetaData | ComponentCommonVideoConfig | ComponentShopCatalogReel | ComponentShopChannelNotification | ComponentShopFooterLinkComponent | ComponentShopNavigationItem | ComponentShopProduct | ComponentShopProductCollectionFilter | ComponentShopProductListing | ComponentThemeBackground | ComponentThemeLayout | EventIndex | EventPage | I18NLocale | LandingPage | MembershipPage | Minute | ProductPage | SubscriptionPage | TaxonPage | UploadFile | UploadFolder | UsersPermissionsPermission | UsersPermissionsRole | UsersPermissionsUser;

export interface I18NLocale {
  __typename: 'I18NLocale';
  code?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface I18NLocaleEntity {
  __typename: 'I18NLocaleEntity';
  attributes?: Maybe<I18NLocale>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface I18NLocaleEntityResponse {
  __typename: 'I18NLocaleEntityResponse';
  data?: Maybe<I18NLocaleEntity>;
}

export interface I18NLocaleEntityResponseCollection {
  __typename: 'I18NLocaleEntityResponseCollection';
  data: Array<I18NLocaleEntity>;
  meta: ResponseCollectionMeta;
}

export interface I18NLocaleFiltersInput {
  and?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  code?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<I18NLocaleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<I18NLocaleFiltersInput>>>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface IdFilterInput {
  and?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  contains?: InputMaybe<Scalars['ID']['input']>;
  containsi?: InputMaybe<Scalars['ID']['input']>;
  endsWith?: InputMaybe<Scalars['ID']['input']>;
  eq?: InputMaybe<Scalars['ID']['input']>;
  eqi?: InputMaybe<Scalars['ID']['input']>;
  gt?: InputMaybe<Scalars['ID']['input']>;
  gte?: InputMaybe<Scalars['ID']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  lt?: InputMaybe<Scalars['ID']['input']>;
  lte?: InputMaybe<Scalars['ID']['input']>;
  ne?: InputMaybe<Scalars['ID']['input']>;
  not?: InputMaybe<IdFilterInput>;
  notContains?: InputMaybe<Scalars['ID']['input']>;
  notContainsi?: InputMaybe<Scalars['ID']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  startsWith?: InputMaybe<Scalars['ID']['input']>;
}

export interface IntFilterInput {
  and?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  contains?: InputMaybe<Scalars['Int']['input']>;
  containsi?: InputMaybe<Scalars['Int']['input']>;
  endsWith?: InputMaybe<Scalars['Int']['input']>;
  eq?: InputMaybe<Scalars['Int']['input']>;
  eqi?: InputMaybe<Scalars['Int']['input']>;
  gt?: InputMaybe<Scalars['Int']['input']>;
  gte?: InputMaybe<Scalars['Int']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  lt?: InputMaybe<Scalars['Int']['input']>;
  lte?: InputMaybe<Scalars['Int']['input']>;
  ne?: InputMaybe<Scalars['Int']['input']>;
  not?: InputMaybe<IntFilterInput>;
  notContains?: InputMaybe<Scalars['Int']['input']>;
  notContainsi?: InputMaybe<Scalars['Int']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['Int']['input']>>>;
  startsWith?: InputMaybe<Scalars['Int']['input']>;
}

export interface JsonFilterInput {
  and?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  contains?: InputMaybe<Scalars['JSON']['input']>;
  containsi?: InputMaybe<Scalars['JSON']['input']>;
  endsWith?: InputMaybe<Scalars['JSON']['input']>;
  eq?: InputMaybe<Scalars['JSON']['input']>;
  eqi?: InputMaybe<Scalars['JSON']['input']>;
  gt?: InputMaybe<Scalars['JSON']['input']>;
  gte?: InputMaybe<Scalars['JSON']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  lt?: InputMaybe<Scalars['JSON']['input']>;
  lte?: InputMaybe<Scalars['JSON']['input']>;
  ne?: InputMaybe<Scalars['JSON']['input']>;
  not?: InputMaybe<JsonFilterInput>;
  notContains?: InputMaybe<Scalars['JSON']['input']>;
  notContainsi?: InputMaybe<Scalars['JSON']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['JSON']['input']>>>;
  startsWith?: InputMaybe<Scalars['JSON']['input']>;
}

export interface LandingPage {
  __typename: 'LandingPage';
  body?: Maybe<Array<Maybe<LandingPageBodyDynamicZone>>>;
  channel?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<LandingPageRelationResponseCollection>;
  metaData?: Maybe<ComponentCommonMetaData>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  slug: Scalars['String']['output'];
  title: Scalars['String']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface LandingPageLocalizationsArgs {
  filters?: InputMaybe<LandingPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export type LandingPageBodyDynamicZone = ComponentBrochureAccordion | ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export interface LandingPageEntity {
  __typename: 'LandingPageEntity';
  attributes?: Maybe<LandingPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface LandingPageEntityResponse {
  __typename: 'LandingPageEntityResponse';
  data?: Maybe<LandingPageEntity>;
}

export interface LandingPageEntityResponseCollection {
  __typename: 'LandingPageEntityResponseCollection';
  data: Array<LandingPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface LandingPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<LandingPageFiltersInput>>>;
  channel?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<LandingPageFiltersInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  not?: InputMaybe<LandingPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<LandingPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  slug?: InputMaybe<StringFilterInput>;
  title?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface LandingPageInput {
  body?: InputMaybe<Array<Scalars['LandingPageBodyDynamicZoneInput']['input']>>;
  channel?: InputMaybe<Scalars['String']['input']>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface LandingPageRelationResponseCollection {
  __typename: 'LandingPageRelationResponseCollection';
  data: Array<LandingPageEntity>;
}

export enum LayoutContainer {
  Contain = 'contain',
  Fluid = 'fluid'
}

export enum LayoutTextAlignment {
  Center = 'center',
  Left = 'left',
  Right = 'right'
}

export enum LayoutTextPosition {
  Center = 'center',
  End = 'end',
  Start = 'start'
}

export interface MembershipPage {
  __typename: 'MembershipPage';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<MembershipPageRelationResponseCollection>;
  membershipFooter?: Maybe<Array<Maybe<MembershipPageMembershipFooterDynamicZone>>>;
  membershipHeader?: Maybe<Array<Maybe<MembershipPageMembershipHeaderDynamicZone>>>;
  metaData?: Maybe<ComponentCommonMetaData>;
  name: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface MembershipPageLocalizationsArgs {
  filters?: InputMaybe<MembershipPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface MembershipPageEntity {
  __typename: 'MembershipPageEntity';
  attributes?: Maybe<MembershipPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface MembershipPageEntityResponse {
  __typename: 'MembershipPageEntityResponse';
  data?: Maybe<MembershipPageEntity>;
}

export interface MembershipPageEntityResponseCollection {
  __typename: 'MembershipPageEntityResponseCollection';
  data: Array<MembershipPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface MembershipPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<MembershipPageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<MembershipPageFiltersInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<MembershipPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<MembershipPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface MembershipPageInput {
  membershipFooter?: InputMaybe<Array<Scalars['MembershipPageMembershipFooterDynamicZoneInput']['input']>>;
  membershipHeader?: InputMaybe<Array<Scalars['MembershipPageMembershipHeaderDynamicZoneInput']['input']>>;
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export type MembershipPageMembershipFooterDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export type MembershipPageMembershipHeaderDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export interface MembershipPageRelationResponseCollection {
  __typename: 'MembershipPageRelationResponseCollection';
  data: Array<MembershipPageEntity>;
}

export interface Minute {
  __typename: 'Minute';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  fallback?: Maybe<UploadFileEntityResponse>;
  media: UploadFileEntityResponse;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface MinuteEntity {
  __typename: 'MinuteEntity';
  attributes?: Maybe<Minute>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface MinuteEntityResponse {
  __typename: 'MinuteEntityResponse';
  data?: Maybe<MinuteEntity>;
}

export interface MinuteInput {
  fallback?: InputMaybe<Scalars['ID']['input']>;
  media?: InputMaybe<Scalars['ID']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
}

export interface Mutation {
  __typename: 'Mutation';
  /** Change user password. Confirm with the current password. */
  changePassword?: Maybe<UsersPermissionsLoginPayload>;
  createBlogIndexLocalization?: Maybe<BlogIndexEntityResponse>;
  createBlogPage?: Maybe<BlogPageEntityResponse>;
  createBlogPageLocalization?: Maybe<BlogPageEntityResponse>;
  createBrochureHomeLocalization?: Maybe<BrochureHomeEntityResponse>;
  createBrochureNavCategory?: Maybe<BrochureNavCategoryEntityResponse>;
  createBrochureNavItem?: Maybe<BrochureNavItemEntityResponse>;
  createBrochurePage?: Maybe<BrochurePageEntityResponse>;
  createBrochurePageLocalization?: Maybe<BrochurePageEntityResponse>;
  createChannelPage?: Maybe<ChannelPageEntityResponse>;
  createChannelPageLocalization?: Maybe<ChannelPageEntityResponse>;
  createEventIndexLocalization?: Maybe<EventIndexEntityResponse>;
  createEventPage?: Maybe<EventPageEntityResponse>;
  createEventPageLocalization?: Maybe<EventPageEntityResponse>;
  createLandingPage?: Maybe<LandingPageEntityResponse>;
  createLandingPageLocalization?: Maybe<LandingPageEntityResponse>;
  createMembershipPage?: Maybe<MembershipPageEntityResponse>;
  createMembershipPageLocalization?: Maybe<MembershipPageEntityResponse>;
  createProductPage?: Maybe<ProductPageEntityResponse>;
  createProductPageLocalization?: Maybe<ProductPageEntityResponse>;
  createSubscriptionPage?: Maybe<SubscriptionPageEntityResponse>;
  createSubscriptionPageLocalization?: Maybe<SubscriptionPageEntityResponse>;
  createTaxonPage?: Maybe<TaxonPageEntityResponse>;
  createTaxonPageLocalization?: Maybe<TaxonPageEntityResponse>;
  createUploadFile?: Maybe<UploadFileEntityResponse>;
  createUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Create a new role */
  createUsersPermissionsRole?: Maybe<UsersPermissionsCreateRolePayload>;
  /** Create a new user */
  createUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  deleteBlogIndex?: Maybe<BlogIndexEntityResponse>;
  deleteBlogPage?: Maybe<BlogPageEntityResponse>;
  deleteBrochureHome?: Maybe<BrochureHomeEntityResponse>;
  deleteBrochureNavCategory?: Maybe<BrochureNavCategoryEntityResponse>;
  deleteBrochureNavItem?: Maybe<BrochureNavItemEntityResponse>;
  deleteBrochurePage?: Maybe<BrochurePageEntityResponse>;
  deleteChannelPage?: Maybe<ChannelPageEntityResponse>;
  deleteEventIndex?: Maybe<EventIndexEntityResponse>;
  deleteEventPage?: Maybe<EventPageEntityResponse>;
  deleteLandingPage?: Maybe<LandingPageEntityResponse>;
  deleteMembershipPage?: Maybe<MembershipPageEntityResponse>;
  deleteMinute?: Maybe<MinuteEntityResponse>;
  deleteProductPage?: Maybe<ProductPageEntityResponse>;
  deleteSubscriptionPage?: Maybe<SubscriptionPageEntityResponse>;
  deleteTaxonPage?: Maybe<TaxonPageEntityResponse>;
  deleteUploadFile?: Maybe<UploadFileEntityResponse>;
  deleteUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Delete an existing role */
  deleteUsersPermissionsRole?: Maybe<UsersPermissionsDeleteRolePayload>;
  /** Delete an existing user */
  deleteUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  /** Confirm an email users email address */
  emailConfirmation?: Maybe<UsersPermissionsLoginPayload>;
  /** Request a reset password token */
  forgotPassword?: Maybe<UsersPermissionsPasswordPayload>;
  login: UsersPermissionsLoginPayload;
  multipleUpload: Array<Maybe<UploadFileEntityResponse>>;
  /** Register a user */
  register: UsersPermissionsLoginPayload;
  removeFile?: Maybe<UploadFileEntityResponse>;
  /** Reset user password. Confirm with a code (resetToken from forgotPassword) */
  resetPassword?: Maybe<UsersPermissionsLoginPayload>;
  updateBlogIndex?: Maybe<BlogIndexEntityResponse>;
  updateBlogPage?: Maybe<BlogPageEntityResponse>;
  updateBrochureHome?: Maybe<BrochureHomeEntityResponse>;
  updateBrochureNavCategory?: Maybe<BrochureNavCategoryEntityResponse>;
  updateBrochureNavItem?: Maybe<BrochureNavItemEntityResponse>;
  updateBrochurePage?: Maybe<BrochurePageEntityResponse>;
  updateChannelPage?: Maybe<ChannelPageEntityResponse>;
  updateEventIndex?: Maybe<EventIndexEntityResponse>;
  updateEventPage?: Maybe<EventPageEntityResponse>;
  updateFileInfo: UploadFileEntityResponse;
  updateLandingPage?: Maybe<LandingPageEntityResponse>;
  updateMembershipPage?: Maybe<MembershipPageEntityResponse>;
  updateMinute?: Maybe<MinuteEntityResponse>;
  updateProductPage?: Maybe<ProductPageEntityResponse>;
  updateSubscriptionPage?: Maybe<SubscriptionPageEntityResponse>;
  updateTaxonPage?: Maybe<TaxonPageEntityResponse>;
  updateUploadFile?: Maybe<UploadFileEntityResponse>;
  updateUploadFolder?: Maybe<UploadFolderEntityResponse>;
  /** Update an existing role */
  updateUsersPermissionsRole?: Maybe<UsersPermissionsUpdateRolePayload>;
  /** Update an existing user */
  updateUsersPermissionsUser: UsersPermissionsUserEntityResponse;
  upload: UploadFileEntityResponse;
}


export interface MutationChangePasswordArgs {
  currentPassword: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
}


export interface MutationCreateBlogIndexLocalizationArgs {
  data?: InputMaybe<BlogIndexInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateBlogPageArgs {
  data: BlogPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateBlogPageLocalizationArgs {
  data?: InputMaybe<BlogPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateBrochureHomeLocalizationArgs {
  data?: InputMaybe<BrochureHomeInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateBrochureNavCategoryArgs {
  data: BrochureNavCategoryInput;
}


export interface MutationCreateBrochureNavItemArgs {
  data: BrochureNavItemInput;
}


export interface MutationCreateBrochurePageArgs {
  data: BrochurePageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateBrochurePageLocalizationArgs {
  data?: InputMaybe<BrochurePageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateChannelPageArgs {
  data: ChannelPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateChannelPageLocalizationArgs {
  data?: InputMaybe<ChannelPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateEventIndexLocalizationArgs {
  data?: InputMaybe<EventIndexInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateEventPageArgs {
  data: EventPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateEventPageLocalizationArgs {
  data?: InputMaybe<EventPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateLandingPageArgs {
  data: LandingPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateLandingPageLocalizationArgs {
  data?: InputMaybe<LandingPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateMembershipPageArgs {
  data: MembershipPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateMembershipPageLocalizationArgs {
  data?: InputMaybe<MembershipPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateProductPageArgs {
  data: ProductPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateProductPageLocalizationArgs {
  data?: InputMaybe<ProductPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateSubscriptionPageArgs {
  data: SubscriptionPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateSubscriptionPageLocalizationArgs {
  data?: InputMaybe<SubscriptionPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateTaxonPageArgs {
  data: TaxonPageInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateTaxonPageLocalizationArgs {
  data?: InputMaybe<TaxonPageInput>;
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationCreateUploadFileArgs {
  data: UploadFileInput;
}


export interface MutationCreateUploadFolderArgs {
  data: UploadFolderInput;
}


export interface MutationCreateUsersPermissionsRoleArgs {
  data: UsersPermissionsRoleInput;
}


export interface MutationCreateUsersPermissionsUserArgs {
  data: UsersPermissionsUserInput;
}


export interface MutationDeleteBlogIndexArgs {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteBlogPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteBrochureHomeArgs {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteBrochureNavCategoryArgs {
  id: Scalars['ID']['input'];
}


export interface MutationDeleteBrochureNavItemArgs {
  id: Scalars['ID']['input'];
}


export interface MutationDeleteBrochurePageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteChannelPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteEventIndexArgs {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteEventPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteLandingPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteMembershipPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteProductPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteSubscriptionPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteTaxonPageArgs {
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationDeleteUploadFileArgs {
  id: Scalars['ID']['input'];
}


export interface MutationDeleteUploadFolderArgs {
  id: Scalars['ID']['input'];
}


export interface MutationDeleteUsersPermissionsRoleArgs {
  id: Scalars['ID']['input'];
}


export interface MutationDeleteUsersPermissionsUserArgs {
  id: Scalars['ID']['input'];
}


export interface MutationEmailConfirmationArgs {
  confirmation: Scalars['String']['input'];
}


export interface MutationForgotPasswordArgs {
  email: Scalars['String']['input'];
}


export interface MutationLoginArgs {
  input: UsersPermissionsLoginInput;
}


export interface MutationMultipleUploadArgs {
  field?: InputMaybe<Scalars['String']['input']>;
  files: Array<InputMaybe<Scalars['Upload']['input']>>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
}


export interface MutationRegisterArgs {
  input: UsersPermissionsRegisterInput;
}


export interface MutationRemoveFileArgs {
  id: Scalars['ID']['input'];
}


export interface MutationResetPasswordArgs {
  code: Scalars['String']['input'];
  password: Scalars['String']['input'];
  passwordConfirmation: Scalars['String']['input'];
}


export interface MutationUpdateBlogIndexArgs {
  data: BlogIndexInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateBlogPageArgs {
  data: BlogPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateBrochureHomeArgs {
  data: BrochureHomeInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateBrochureNavCategoryArgs {
  data: BrochureNavCategoryInput;
  id: Scalars['ID']['input'];
}


export interface MutationUpdateBrochureNavItemArgs {
  data: BrochureNavItemInput;
  id: Scalars['ID']['input'];
}


export interface MutationUpdateBrochurePageArgs {
  data: BrochurePageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateChannelPageArgs {
  data: ChannelPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateEventIndexArgs {
  data: EventIndexInput;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateEventPageArgs {
  data: EventPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateFileInfoArgs {
  id: Scalars['ID']['input'];
  info?: InputMaybe<FileInfoInput>;
}


export interface MutationUpdateLandingPageArgs {
  data: LandingPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateMembershipPageArgs {
  data: MembershipPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateMinuteArgs {
  data: MinuteInput;
}


export interface MutationUpdateProductPageArgs {
  data: ProductPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateSubscriptionPageArgs {
  data: SubscriptionPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateTaxonPageArgs {
  data: TaxonPageInput;
  id: Scalars['ID']['input'];
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface MutationUpdateUploadFileArgs {
  data: UploadFileInput;
  id: Scalars['ID']['input'];
}


export interface MutationUpdateUploadFolderArgs {
  data: UploadFolderInput;
  id: Scalars['ID']['input'];
}


export interface MutationUpdateUsersPermissionsRoleArgs {
  data: UsersPermissionsRoleInput;
  id: Scalars['ID']['input'];
}


export interface MutationUpdateUsersPermissionsUserArgs {
  data: UsersPermissionsUserInput;
  id: Scalars['ID']['input'];
}


export interface MutationUploadArgs {
  field?: InputMaybe<Scalars['String']['input']>;
  file: Scalars['Upload']['input'];
  info?: InputMaybe<FileInfoInput>;
  ref?: InputMaybe<Scalars['String']['input']>;
  refId?: InputMaybe<Scalars['ID']['input']>;
}

export interface Pagination {
  __typename: 'Pagination';
  page: Scalars['Int']['output'];
  pageCount: Scalars['Int']['output'];
  pageSize: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
}

export interface PaginationArg {
  limit?: InputMaybe<Scalars['Int']['input']>;
  page?: InputMaybe<Scalars['Int']['input']>;
  pageSize?: InputMaybe<Scalars['Int']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
}

export enum ProductCollectionSortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export interface ProductPage {
  __typename: 'ProductPage';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<ProductPageRelationResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  productFooter?: Maybe<Array<Maybe<ProductPageProductFooterDynamicZone>>>;
  productHeader?: Maybe<Array<Maybe<ProductPageProductHeaderDynamicZone>>>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface ProductPageLocalizationsArgs {
  filters?: InputMaybe<ProductPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ProductPageEntity {
  __typename: 'ProductPageEntity';
  attributes?: Maybe<ProductPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface ProductPageEntityResponse {
  __typename: 'ProductPageEntityResponse';
  data?: Maybe<ProductPageEntity>;
}

export interface ProductPageEntityResponseCollection {
  __typename: 'ProductPageEntityResponseCollection';
  data: Array<ProductPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface ProductPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<ProductPageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<ProductPageFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<ProductPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<ProductPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface ProductPageInput {
  name?: InputMaybe<Scalars['String']['input']>;
  productFooter?: InputMaybe<Array<Scalars['ProductPageProductFooterDynamicZoneInput']['input']>>;
  productHeader?: InputMaybe<Array<Scalars['ProductPageProductHeaderDynamicZoneInput']['input']>>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
}

export type ProductPageProductFooterDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export type ProductPageProductHeaderDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export interface ProductPageRelationResponseCollection {
  __typename: 'ProductPageRelationResponseCollection';
  data: Array<ProductPageEntity>;
}

export enum PublicationState {
  Live = 'LIVE',
  Preview = 'PREVIEW'
}

export interface Query {
  __typename: 'Query';
  blogIndex?: Maybe<BlogIndexEntityResponse>;
  blogPage?: Maybe<BlogPageEntityResponse>;
  blogPages?: Maybe<BlogPageEntityResponseCollection>;
  brochureHome?: Maybe<BrochureHomeEntityResponse>;
  brochureNavCategories?: Maybe<BrochureNavCategoryEntityResponseCollection>;
  brochureNavCategory?: Maybe<BrochureNavCategoryEntityResponse>;
  brochureNavItem?: Maybe<BrochureNavItemEntityResponse>;
  brochureNavItems?: Maybe<BrochureNavItemEntityResponseCollection>;
  brochurePage?: Maybe<BrochurePageEntityResponse>;
  brochurePages?: Maybe<BrochurePageEntityResponseCollection>;
  channelPage?: Maybe<ChannelPageEntityResponse>;
  channelPages?: Maybe<ChannelPageEntityResponseCollection>;
  eventIndex?: Maybe<EventIndexEntityResponse>;
  eventPage?: Maybe<EventPageEntityResponse>;
  eventPages?: Maybe<EventPageEntityResponseCollection>;
  i18NLocale?: Maybe<I18NLocaleEntityResponse>;
  i18NLocales?: Maybe<I18NLocaleEntityResponseCollection>;
  landingPage?: Maybe<LandingPageEntityResponse>;
  landingPages?: Maybe<LandingPageEntityResponseCollection>;
  me?: Maybe<UsersPermissionsMe>;
  membershipPage?: Maybe<MembershipPageEntityResponse>;
  membershipPages?: Maybe<MembershipPageEntityResponseCollection>;
  minute?: Maybe<MinuteEntityResponse>;
  productPage?: Maybe<ProductPageEntityResponse>;
  productPages?: Maybe<ProductPageEntityResponseCollection>;
  subscriptionPage?: Maybe<SubscriptionPageEntityResponse>;
  subscriptionPages?: Maybe<SubscriptionPageEntityResponseCollection>;
  taxonPage?: Maybe<TaxonPageEntityResponse>;
  taxonPages?: Maybe<TaxonPageEntityResponseCollection>;
  uploadFile?: Maybe<UploadFileEntityResponse>;
  uploadFiles?: Maybe<UploadFileEntityResponseCollection>;
  uploadFolder?: Maybe<UploadFolderEntityResponse>;
  uploadFolders?: Maybe<UploadFolderEntityResponseCollection>;
  usersPermissionsRole?: Maybe<UsersPermissionsRoleEntityResponse>;
  usersPermissionsRoles?: Maybe<UsersPermissionsRoleEntityResponseCollection>;
  usersPermissionsUser?: Maybe<UsersPermissionsUserEntityResponse>;
  usersPermissionsUsers?: Maybe<UsersPermissionsUserEntityResponseCollection>;
}


export interface QueryBlogIndexArgs {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryBlogPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryBlogPagesArgs {
  filters?: InputMaybe<BlogPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryBrochureHomeArgs {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryBrochureNavCategoriesArgs {
  filters?: InputMaybe<BrochureNavCategoryFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryBrochureNavCategoryArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryBrochureNavItemArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryBrochureNavItemsArgs {
  filters?: InputMaybe<BrochureNavItemFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryBrochurePageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryBrochurePagesArgs {
  filters?: InputMaybe<BrochurePageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryChannelPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryChannelPagesArgs {
  filters?: InputMaybe<ChannelPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryEventIndexArgs {
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryEventPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryEventPagesArgs {
  filters?: InputMaybe<EventPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryI18NLocaleArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryI18NLocalesArgs {
  filters?: InputMaybe<I18NLocaleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryLandingPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryLandingPagesArgs {
  filters?: InputMaybe<LandingPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryMembershipPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryMembershipPagesArgs {
  filters?: InputMaybe<MembershipPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryMinuteArgs {
  publicationState?: InputMaybe<PublicationState>;
}


export interface QueryProductPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryProductPagesArgs {
  filters?: InputMaybe<ProductPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QuerySubscriptionPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QuerySubscriptionPagesArgs {
  filters?: InputMaybe<SubscriptionPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryTaxonPageArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
}


export interface QueryTaxonPagesArgs {
  filters?: InputMaybe<TaxonPageFiltersInput>;
  locale?: InputMaybe<Scalars['I18NLocaleCode']['input']>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryUploadFileArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryUploadFilesArgs {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryUploadFolderArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryUploadFoldersArgs {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryUsersPermissionsRoleArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryUsersPermissionsRolesArgs {
  filters?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface QueryUsersPermissionsUserArgs {
  id?: InputMaybe<Scalars['ID']['input']>;
}


export interface QueryUsersPermissionsUsersArgs {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface ResponseCollectionMeta {
  __typename: 'ResponseCollectionMeta';
  pagination: Pagination;
}

export interface StringFilterInput {
  and?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  contains?: InputMaybe<Scalars['String']['input']>;
  containsi?: InputMaybe<Scalars['String']['input']>;
  endsWith?: InputMaybe<Scalars['String']['input']>;
  eq?: InputMaybe<Scalars['String']['input']>;
  eqi?: InputMaybe<Scalars['String']['input']>;
  gt?: InputMaybe<Scalars['String']['input']>;
  gte?: InputMaybe<Scalars['String']['input']>;
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  lt?: InputMaybe<Scalars['String']['input']>;
  lte?: InputMaybe<Scalars['String']['input']>;
  ne?: InputMaybe<Scalars['String']['input']>;
  not?: InputMaybe<StringFilterInput>;
  notContains?: InputMaybe<Scalars['String']['input']>;
  notContainsi?: InputMaybe<Scalars['String']['input']>;
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  notNull?: InputMaybe<Scalars['Boolean']['input']>;
  null?: InputMaybe<Scalars['Boolean']['input']>;
  or?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  startsWith?: InputMaybe<Scalars['String']['input']>;
}

export interface SubscriptionPage {
  __typename: 'SubscriptionPage';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<SubscriptionPageRelationResponseCollection>;
  metaData?: Maybe<ComponentCommonMetaData>;
  name: Scalars['String']['output'];
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  subscriptionFooter?: Maybe<Array<Maybe<SubscriptionPageSubscriptionFooterDynamicZone>>>;
  subscriptionHeader?: Maybe<Array<Maybe<SubscriptionPageSubscriptionHeaderDynamicZone>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface SubscriptionPageLocalizationsArgs {
  filters?: InputMaybe<SubscriptionPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface SubscriptionPageEntity {
  __typename: 'SubscriptionPageEntity';
  attributes?: Maybe<SubscriptionPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface SubscriptionPageEntityResponse {
  __typename: 'SubscriptionPageEntityResponse';
  data?: Maybe<SubscriptionPageEntity>;
}

export interface SubscriptionPageEntityResponseCollection {
  __typename: 'SubscriptionPageEntityResponseCollection';
  data: Array<SubscriptionPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface SubscriptionPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<SubscriptionPageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<SubscriptionPageFiltersInput>;
  metaData?: InputMaybe<ComponentCommonMetaDataFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<SubscriptionPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<SubscriptionPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface SubscriptionPageInput {
  metaData?: InputMaybe<ComponentCommonMetaDataInput>;
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  subscriptionFooter?: InputMaybe<Array<Scalars['SubscriptionPageSubscriptionFooterDynamicZoneInput']['input']>>;
  subscriptionHeader?: InputMaybe<Array<Scalars['SubscriptionPageSubscriptionHeaderDynamicZoneInput']['input']>>;
}

export interface SubscriptionPageRelationResponseCollection {
  __typename: 'SubscriptionPageRelationResponseCollection';
  data: Array<SubscriptionPageEntity>;
}

export type SubscriptionPageSubscriptionFooterDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export type SubscriptionPageSubscriptionHeaderDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export interface TaxonPage {
  __typename: 'TaxonPage';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  localizations?: Maybe<TaxonPageRelationResponseCollection>;
  name?: Maybe<Scalars['String']['output']>;
  publishedAt?: Maybe<Scalars['DateTime']['output']>;
  taxonFooter?: Maybe<Array<Maybe<TaxonPageTaxonFooterDynamicZone>>>;
  taxonHeader?: Maybe<Array<Maybe<TaxonPageTaxonHeaderDynamicZone>>>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface TaxonPageLocalizationsArgs {
  filters?: InputMaybe<TaxonPageFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  publicationState?: InputMaybe<PublicationState>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface TaxonPageEntity {
  __typename: 'TaxonPageEntity';
  attributes?: Maybe<TaxonPage>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface TaxonPageEntityResponse {
  __typename: 'TaxonPageEntityResponse';
  data?: Maybe<TaxonPageEntity>;
}

export interface TaxonPageEntityResponseCollection {
  __typename: 'TaxonPageEntityResponseCollection';
  data: Array<TaxonPageEntity>;
  meta: ResponseCollectionMeta;
}

export interface TaxonPageFiltersInput {
  and?: InputMaybe<Array<InputMaybe<TaxonPageFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  locale?: InputMaybe<StringFilterInput>;
  localizations?: InputMaybe<TaxonPageFiltersInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<TaxonPageFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<TaxonPageFiltersInput>>>;
  publishedAt?: InputMaybe<DateTimeFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface TaxonPageInput {
  name?: InputMaybe<Scalars['String']['input']>;
  publishedAt?: InputMaybe<Scalars['DateTime']['input']>;
  taxonFooter?: InputMaybe<Array<Scalars['TaxonPageTaxonFooterDynamicZoneInput']['input']>>;
  taxonHeader?: InputMaybe<Array<Scalars['TaxonPageTaxonHeaderDynamicZoneInput']['input']>>;
}

export interface TaxonPageRelationResponseCollection {
  __typename: 'TaxonPageRelationResponseCollection';
  data: Array<TaxonPageEntity>;
}

export type TaxonPageTaxonFooterDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export type TaxonPageTaxonHeaderDynamicZone = ComponentBrochureActionBanner | ComponentBrochureActionGrid | ComponentBrochureActionList | ComponentBrochureAnchor | ComponentBrochureBanner | ComponentBrochureBlogListing | ComponentBrochureCardGrid | ComponentBrochureCarousel | ComponentBrochureEventListing | ComponentBrochureFaq | ComponentBrochureHtmlContainer | ComponentBrochureSectionOne | ComponentBrochureSectionThree | ComponentBrochureSectionTwo | ComponentBrochureSectionWithImage | ComponentBrochureSpacer | ComponentShopCatalogReel | ComponentShopProductListing | Error;

export interface UploadFile {
  __typename: 'UploadFile';
  alternativeText?: Maybe<Scalars['String']['output']>;
  caption?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  ext?: Maybe<Scalars['String']['output']>;
  formats?: Maybe<Scalars['JSON']['output']>;
  hash: Scalars['String']['output'];
  height?: Maybe<Scalars['Int']['output']>;
  mime: Scalars['String']['output'];
  name: Scalars['String']['output'];
  previewUrl?: Maybe<Scalars['String']['output']>;
  provider: Scalars['String']['output'];
  provider_metadata?: Maybe<Scalars['JSON']['output']>;
  related?: Maybe<Array<Maybe<GenericMorph>>>;
  size: Scalars['Float']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  url: Scalars['String']['output'];
  width?: Maybe<Scalars['Int']['output']>;
}

export interface UploadFileEntity {
  __typename: 'UploadFileEntity';
  attributes?: Maybe<UploadFile>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface UploadFileEntityResponse {
  __typename: 'UploadFileEntityResponse';
  data?: Maybe<UploadFileEntity>;
}

export interface UploadFileEntityResponseCollection {
  __typename: 'UploadFileEntityResponseCollection';
  data: Array<UploadFileEntity>;
  meta: ResponseCollectionMeta;
}

export interface UploadFileFiltersInput {
  alternativeText?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  caption?: InputMaybe<StringFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  ext?: InputMaybe<StringFilterInput>;
  folder?: InputMaybe<UploadFolderFiltersInput>;
  folderPath?: InputMaybe<StringFilterInput>;
  formats?: InputMaybe<JsonFilterInput>;
  hash?: InputMaybe<StringFilterInput>;
  height?: InputMaybe<IntFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  mime?: InputMaybe<StringFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFileFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFileFiltersInput>>>;
  previewUrl?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  provider_metadata?: InputMaybe<JsonFilterInput>;
  size?: InputMaybe<FloatFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  url?: InputMaybe<StringFilterInput>;
  width?: InputMaybe<IntFilterInput>;
}

export interface UploadFileInput {
  alternativeText?: InputMaybe<Scalars['String']['input']>;
  caption?: InputMaybe<Scalars['String']['input']>;
  ext?: InputMaybe<Scalars['String']['input']>;
  folder?: InputMaybe<Scalars['ID']['input']>;
  folderPath?: InputMaybe<Scalars['String']['input']>;
  formats?: InputMaybe<Scalars['JSON']['input']>;
  hash?: InputMaybe<Scalars['String']['input']>;
  height?: InputMaybe<Scalars['Int']['input']>;
  mime?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  previewUrl?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  provider_metadata?: InputMaybe<Scalars['JSON']['input']>;
  size?: InputMaybe<Scalars['Float']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
  width?: InputMaybe<Scalars['Int']['input']>;
}

export interface UploadFileRelationResponseCollection {
  __typename: 'UploadFileRelationResponseCollection';
  data: Array<UploadFileEntity>;
}

export interface UploadFolder {
  __typename: 'UploadFolder';
  children?: Maybe<UploadFolderRelationResponseCollection>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  files?: Maybe<UploadFileRelationResponseCollection>;
  name: Scalars['String']['output'];
  parent?: Maybe<UploadFolderEntityResponse>;
  path: Scalars['String']['output'];
  pathId: Scalars['Int']['output'];
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}


export interface UploadFolderChildrenArgs {
  filters?: InputMaybe<UploadFolderFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface UploadFolderFilesArgs {
  filters?: InputMaybe<UploadFileFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface UploadFolderEntity {
  __typename: 'UploadFolderEntity';
  attributes?: Maybe<UploadFolder>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface UploadFolderEntityResponse {
  __typename: 'UploadFolderEntityResponse';
  data?: Maybe<UploadFolderEntity>;
}

export interface UploadFolderEntityResponseCollection {
  __typename: 'UploadFolderEntityResponseCollection';
  data: Array<UploadFolderEntity>;
  meta: ResponseCollectionMeta;
}

export interface UploadFolderFiltersInput {
  and?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  children?: InputMaybe<UploadFolderFiltersInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  files?: InputMaybe<UploadFileFiltersInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UploadFolderFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UploadFolderFiltersInput>>>;
  parent?: InputMaybe<UploadFolderFiltersInput>;
  path?: InputMaybe<StringFilterInput>;
  pathId?: InputMaybe<IntFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface UploadFolderInput {
  children?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  files?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  name?: InputMaybe<Scalars['String']['input']>;
  parent?: InputMaybe<Scalars['ID']['input']>;
  path?: InputMaybe<Scalars['String']['input']>;
  pathId?: InputMaybe<Scalars['Int']['input']>;
}

export interface UploadFolderRelationResponseCollection {
  __typename: 'UploadFolderRelationResponseCollection';
  data: Array<UploadFolderEntity>;
}

export interface UsersPermissionsCreateRolePayload {
  __typename: 'UsersPermissionsCreateRolePayload';
  ok: Scalars['Boolean']['output'];
}

export interface UsersPermissionsDeleteRolePayload {
  __typename: 'UsersPermissionsDeleteRolePayload';
  ok: Scalars['Boolean']['output'];
}

export interface UsersPermissionsLoginInput {
  identifier: Scalars['String']['input'];
  password: Scalars['String']['input'];
  provider?: Scalars['String']['input'];
}

export interface UsersPermissionsLoginPayload {
  __typename: 'UsersPermissionsLoginPayload';
  jwt?: Maybe<Scalars['String']['output']>;
  user: UsersPermissionsMe;
}

export interface UsersPermissionsMe {
  __typename: 'UsersPermissionsMe';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  role?: Maybe<UsersPermissionsMeRole>;
  username: Scalars['String']['output'];
}

export interface UsersPermissionsMeRole {
  __typename: 'UsersPermissionsMeRole';
  description?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  type?: Maybe<Scalars['String']['output']>;
}

export interface UsersPermissionsPasswordPayload {
  __typename: 'UsersPermissionsPasswordPayload';
  ok: Scalars['Boolean']['output'];
}

export interface UsersPermissionsPermission {
  __typename: 'UsersPermissionsPermission';
  action: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
}

export interface UsersPermissionsPermissionEntity {
  __typename: 'UsersPermissionsPermissionEntity';
  attributes?: Maybe<UsersPermissionsPermission>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface UsersPermissionsPermissionFiltersInput {
  action?: InputMaybe<StringFilterInput>;
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsPermissionFiltersInput>>>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
}

export interface UsersPermissionsPermissionRelationResponseCollection {
  __typename: 'UsersPermissionsPermissionRelationResponseCollection';
  data: Array<UsersPermissionsPermissionEntity>;
}

export interface UsersPermissionsRegisterInput {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
  username: Scalars['String']['input'];
}

export interface UsersPermissionsRole {
  __typename: 'UsersPermissionsRole';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  permissions?: Maybe<UsersPermissionsPermissionRelationResponseCollection>;
  type?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  users?: Maybe<UsersPermissionsUserRelationResponseCollection>;
}


export interface UsersPermissionsRolePermissionsArgs {
  filters?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}


export interface UsersPermissionsRoleUsersArgs {
  filters?: InputMaybe<UsersPermissionsUserFiltersInput>;
  pagination?: InputMaybe<PaginationArg>;
  sort?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
}

export interface UsersPermissionsRoleEntity {
  __typename: 'UsersPermissionsRoleEntity';
  attributes?: Maybe<UsersPermissionsRole>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface UsersPermissionsRoleEntityResponse {
  __typename: 'UsersPermissionsRoleEntityResponse';
  data?: Maybe<UsersPermissionsRoleEntity>;
}

export interface UsersPermissionsRoleEntityResponseCollection {
  __typename: 'UsersPermissionsRoleEntityResponseCollection';
  data: Array<UsersPermissionsRoleEntity>;
  meta: ResponseCollectionMeta;
}

export interface UsersPermissionsRoleFiltersInput {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  description?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  name?: InputMaybe<StringFilterInput>;
  not?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsRoleFiltersInput>>>;
  permissions?: InputMaybe<UsersPermissionsPermissionFiltersInput>;
  type?: InputMaybe<StringFilterInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  users?: InputMaybe<UsersPermissionsUserFiltersInput>;
}

export interface UsersPermissionsRoleInput {
  description?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permissions?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
  type?: InputMaybe<Scalars['String']['input']>;
  users?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
}

export interface UsersPermissionsUpdateRolePayload {
  __typename: 'UsersPermissionsUpdateRolePayload';
  ok: Scalars['Boolean']['output'];
}

export interface UsersPermissionsUser {
  __typename: 'UsersPermissionsUser';
  blocked?: Maybe<Scalars['Boolean']['output']>;
  confirmed?: Maybe<Scalars['Boolean']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  provider?: Maybe<Scalars['String']['output']>;
  role?: Maybe<UsersPermissionsRoleEntityResponse>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  username: Scalars['String']['output'];
}

export interface UsersPermissionsUserEntity {
  __typename: 'UsersPermissionsUserEntity';
  attributes?: Maybe<UsersPermissionsUser>;
  id?: Maybe<Scalars['ID']['output']>;
}

export interface UsersPermissionsUserEntityResponse {
  __typename: 'UsersPermissionsUserEntityResponse';
  data?: Maybe<UsersPermissionsUserEntity>;
}

export interface UsersPermissionsUserEntityResponseCollection {
  __typename: 'UsersPermissionsUserEntityResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
  meta: ResponseCollectionMeta;
}

export interface UsersPermissionsUserFiltersInput {
  and?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  blocked?: InputMaybe<BooleanFilterInput>;
  confirmationToken?: InputMaybe<StringFilterInput>;
  confirmed?: InputMaybe<BooleanFilterInput>;
  createdAt?: InputMaybe<DateTimeFilterInput>;
  email?: InputMaybe<StringFilterInput>;
  id?: InputMaybe<IdFilterInput>;
  not?: InputMaybe<UsersPermissionsUserFiltersInput>;
  or?: InputMaybe<Array<InputMaybe<UsersPermissionsUserFiltersInput>>>;
  password?: InputMaybe<StringFilterInput>;
  provider?: InputMaybe<StringFilterInput>;
  resetPasswordToken?: InputMaybe<StringFilterInput>;
  role?: InputMaybe<UsersPermissionsRoleFiltersInput>;
  updatedAt?: InputMaybe<DateTimeFilterInput>;
  username?: InputMaybe<StringFilterInput>;
}

export interface UsersPermissionsUserInput {
  blocked?: InputMaybe<Scalars['Boolean']['input']>;
  confirmationToken?: InputMaybe<Scalars['String']['input']>;
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  provider?: InputMaybe<Scalars['String']['input']>;
  resetPasswordToken?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Scalars['ID']['input']>;
  username?: InputMaybe<Scalars['String']['input']>;
}

export interface UsersPermissionsUserRelationResponseCollection {
  __typename: 'UsersPermissionsUserRelationResponseCollection';
  data: Array<UsersPermissionsUserEntity>;
}
