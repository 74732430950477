import { Injectable } from '@angular/core';
import {
  IDataCollection,
  IDataCollectionProvider,
  IDataProvider,
  IDataQuery,
} from '@x/common/data';
import {
  IShippingMethodItemObject,
  ShippingMethodDetailObject,
  ShippingMethodRowObject,
  ShippingMethodService,
} from '@x/ecommerce/domain-client';
import { ShippingMethodFilterInput } from '@x/schemas/ecommerce';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ShippingMethodItemCollectionProvider
  implements IDataCollectionProvider<IShippingMethodItemObject, ShippingMethodFilterInput>
{
  constructor(private shippingMethodService: ShippingMethodService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<IShippingMethodItemObject>> {
    return this.shippingMethodService.fetchItems({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  fetchSingle(
    id: string | number,
  ): IShippingMethodItemObject | Observable<IShippingMethodItemObject> {
    return this.shippingMethodService.fetchItem(Number(id));
  }

  toId(model: IShippingMethodItemObject): number {
    return model.id;
  }

  toString(model: IShippingMethodItemObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ShippingMethodCollectionProvider
  implements
    IDataCollectionProvider<ShippingMethodRowObject, ShippingMethodFilterInput, any, number>
{
  constructor(private shippingMethodService: ShippingMethodService) {}

  fetchCollection({
    searchText,
    page,
    sort,
    filter,
  }: Readonly<IDataQuery<any, any>>): Observable<IDataCollection<ShippingMethodRowObject>> {
    return this.shippingMethodService.fetchRows({
      filter: { search: searchText, ...filter },
      page,
      sort,
    });
  }

  toId(model: ShippingMethodRowObject): number {
    return model.id;
  }

  toString(model: ShippingMethodRowObject): string {
    return model.name;
  }
}

@Injectable({ providedIn: 'root' })
export class ShippingMethodDetailProvider
  implements IDataProvider<ShippingMethodDetailObject, number>
{
  constructor(private shippingMethodService: ShippingMethodService) {}

  fetchSingle(id: number): Observable<ShippingMethodDetailObject> {
    return this.shippingMethodService.fetchDetail(id);
  }

  toId(model: ShippingMethodDetailObject): number {
    return model.id;
  }

  toString(model: ShippingMethodDetailObject): string {
    return model.name;
  }
}
