import { Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { DataCollectionView } from '@x/common/data';
import { Subject, Subscription } from 'rxjs';

export class DataCollectionViewContext<T = any> {
  constructor(
    public view: DataCollectionView<T>,
    public $implicit: T,
  ) {}
}

@Directive({
  selector: '[xDataCollectionView]',
})
export class DataCollectionViewDirective<T = any> implements OnInit, OnDestroy {
  @Input('xDataCollectionView')
  set view(view: DataCollectionView<T>) {
    this.setView(view);
  }

  private _view: DataCollectionView<T> | null;
  private _destroy$ = new Subject<void>();
  private _viewSub: Subscription | undefined;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.disconnectView();
    this._destroy$.next();
    this._destroy$.complete();
  }

  private setView(view: DataCollectionView<T>) {
    this.disconnectView();

    this._view = view;

    this._viewSub = view.connect().subscribe((data) => {
      this.updateViewContainer();
    });
  }

  private disconnectView() {
    if (this._view) {
      if (this._viewSub) {
        this._viewSub.unsubscribe();
        this._viewSub = undefined;
      }
      this._view.disconnect();
    }
  }

  private updateViewContainer() {
    this.viewContainer.clear();

    if (!this._view) {
      return;
    }

    const items = this._view.items;

    if (!items) {
      return;
    }

    for (let item of items) {
      const context = new DataCollectionViewContext(this._view, item);

      this.viewContainer.createEmbeddedView(this.templateRef, {
        $implicit: item,
        view: this._view,
      } satisfies DataCollectionViewContext<T>);
    }
  }

  static ngTemplateContextGuard<T = any>(
    directive: DataCollectionViewDirective<T>,
    context: unknown,
  ): context is DataCollectionViewContext<T> {
    return true;
  }
}
