import { Injectable } from '@angular/core';
import { IBrowserScript } from '@x/common/browser/browser.config';
import { WindowRef } from '@x/common/browser/index';

@Injectable()
export class ScriptInjectorService {
  constructor(private windowRef: WindowRef) {}

  loadScripts(scripts?: IBrowserScript[]): Promise<void> {
    if (!this.windowRef.isBrowser || !scripts) {
      return Promise.resolve(); // Skip script loading on the server
    }

    const loadScript = (src: string, defer: boolean, async: boolean): Promise<void> =>
      new Promise((resolve, reject) => {
        const script = this.windowRef.document.createElement('script');
        script.src = src;
        script.defer = defer;
        script.async = async;

        if (defer && async) {
          this.windowRef.documentHead.appendChild(script);
          return resolve();
        }

        script.onload = () => resolve();
        script.onerror = () => reject(new Error(`Failed to load script: ${src}`));
        this.windowRef.documentHead.appendChild(script);
      });

    // Load all scripts and resolve when they are all complete
    return Promise.all(
      scripts.flatMap((scriptConfig) =>
        (Array.isArray(scriptConfig.src) ? scriptConfig.src : [scriptConfig.src]).map((src) =>
          loadScript(src, scriptConfig.defer || false, scriptConfig.async || false),
        ),
      ),
    ).then(() => undefined); // Ensure the promise resolves with void
  }
}
