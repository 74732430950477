import {
  ChangeDetectorRef,
  Directive,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { DataView } from '../views/data-view';

interface DataViewDirectiveContext<T = any, I = any> {
  $implicit: DataView<T, I>;
  data: T | null;
  id: I | null;
}

@Directive({
  selector: '[xDataView]',
})
export class DataViewDirective<T = any, I = any> implements OnInit, OnDestroy {
  @Input('xDataView')
  set view(view: DataView<T, I>) {
    this.setView(view);
  }

  private _view: DataView<T, I> | null;
  private _destroy$ = new Subject<void>();
  private _viewSub: Subscription | undefined;

  private _context: DataViewDirectiveContext;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private changeRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this.disconnectView();
    this._destroy$.next();
    this._destroy$.complete();
  }

  private setView(view: DataView<T, I>) {
    this._view = view;

    view.connect();

    this.viewContainer.clear();

    this._context = { $implicit: view, data: view.data, id: view.id };

    const viewRef = this.viewContainer.createEmbeddedView(this.templateRef, this._context);
    this._viewSub = this._view.stateChanges().subscribe((s) => {
      this._context.data = view.data;
      this._context.id = view.id;
      viewRef.markForCheck();
    });

    viewRef.markForCheck();
  }

  private disconnectView() {
    if (this._view) {
      if (this._viewSub) {
        this._viewSub.unsubscribe();
        this._viewSub = undefined;
      }
      this._view.disconnect();
    }
  }

  static ngTemplateContextGuard<T = any, I = any>(
    directive: DataViewDirective<T, I>,
    context: unknown,
  ): context is DataViewDirectiveContext<T, I> {
    return true;
  }
}
