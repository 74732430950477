import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PromptDialogService } from '@x/dashboard/dialog';
import { ShipmentService } from '@x/ecommerce/domain-client';
import { UpdateShipmentParcelInput } from '@x/schemas/ecommerce';
import { Subject, firstValueFrom } from 'rxjs';

export interface IOrderParcelFormDialogData {
  parcelId: number;
}

export interface IOrderParcelFormDialogResult {
  parcelId: number;
}

@Component({
  selector: 'x-order-parcel-form-dialog',
  templateUrl: './order-parcel-form-dialog.component.html',
  styleUrls: ['./order-parcel-form-dialog.component.scss'],
})
export class OrderParcelFormDialogComponent implements OnInit {
  private _destroy$ = new Subject<void>();

  parcelForm = new UntypedFormGroup({
    name: new UntypedFormControl(null, [Validators.required]),
    weight: new UntypedFormControl(null, [Validators.required]),
    length: new UntypedFormControl(null, [Validators.required]),
    width: new UntypedFormControl(null, [Validators.required]),
    height: new UntypedFormControl(null, [Validators.required]),
  });

  constructor(
    private shipmentService: ShipmentService,
    private promptDialogService: PromptDialogService,
    @Inject(MAT_DIALOG_DATA)
    public data: IOrderParcelFormDialogData,
    public dialog: MatDialogRef<OrderParcelFormDialogComponent, IOrderParcelFormDialogResult>,
  ) {}

  async ngOnInit(): Promise<void> {
    const parcel = await firstValueFrom(
      this.shipmentService.shipmentParcelById(this.data.parcelId),
    );
    this.parcelForm.patchValue({
      name: parcel.name,
      weight: parcel.weight,
      length: parcel.dimensions?.length,
      width: parcel.dimensions?.width,
      height: parcel.dimensions?.height,
    });
  }

  closeDialog() {
    this.dialog.close();
  }

  async submit() {
    const { valid, value } = this.parcelForm;

    if (!valid) return;

    const input: UpdateShipmentParcelInput = {
      id: this.data.parcelId,
      name: value.name,
      weight: value.weight,
      dimensions: {
        width: value.width,
        height: value.height,
        length: value.length,
      },
    };

    const id = await firstValueFrom(this.shipmentService.updateParcel(input));

    this.dialog.close({
      parcelId: id,
    });
  }

  async remove() {
    const confirm = await this.promptDialogService
      .confirm({
        title: `Remove Parcel`,
        message: `Are you sure you want to remove this parcel?`,
      })
      .toPromise();

    if (confirm) {
      await this.shipmentService.removeParcel(this.data.parcelId).toPromise();
      this.closeDialog();
    }
  }
}
