import { LanguageDeclaration } from '@x/common/locale';

export const PARCEL_OPTION_LANGUAGE: LanguageDeclaration = {
  parcelOption: {
    index: {
      title: 'Parcel Options',
      subtitle: '',
      column: {
        name: 'Name',
        dimensions: 'Dimensions',
        weight: 'Weight',
        timestamps: 'Timestamps',
      },
      sort: {
        name: 'Parcel Option Name',
        weight: 'Parcel Option Weight',
        createdAt: 'Parcel Option Created Date',
        updatedAt: 'Parcel Option Updated Date',
      },
      action: {
        edit: 'Edit Parcel Option',
      },
    },
    detail: {},
  },
};
