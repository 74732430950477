<h2 mat-dialog-title>{{ verb }} Shipping Schedule</h2>
<form
  [formGroup]="formGroup"
  mat-dialog-content
  novalidate
  autocomplete="off"
  (ngSubmit)="submit()"
  id="shipping_schedule_form"
>
  <x-dashboard-form-layout>
    <x-form-panel xFormErrorBoundary>
      <mat-form-field class="col-12" appearance="outline">
        <mat-label>Name</mat-label>
        <input matInput type="text" formControlName="name" />
        <mat-hint>Schedule Name, eg. Morning</mat-hint>
      </mat-form-field>
      <mat-form-field>
        <mat-label>Shipping Method</mat-label>
        <x-data-autocomplete
          formControlName="methodId"
          [provider]="Providers.ShippingMethodItemCollectionProvider"
          [filter]="{ enabled: true, slotRequired: true }"
          [multiple]="false"
        ></x-data-autocomplete>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Days Of Week</mat-label>
        <mat-select formControlName="daysOfWeek" [multiple]="true">
          <mat-option [value]="1">Mon</mat-option>
          <mat-option [value]="2">Tue</mat-option>
          <mat-option [value]="3">Wed</mat-option>
          <mat-option [value]="4">Thu</mat-option>
          <mat-option [value]="5">Fri</mat-option>
          <mat-option [value]="6">Sat</mat-option>
          <mat-option [value]="7">Sun</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field class="col-6">
        <mat-label>Slot Start Time</mat-label>
        <input matInput type="time" formControlName="startTime" />
        <mat-error xFormError="startTime"></mat-error>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Slot Duration</mat-label>
        <x-duration-input-control
          formControlName="duration"
          [format]="['hr', 'min']"
        ></x-duration-input-control>
        <mat-error xFormError="duration"></mat-error>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Slot Lead Time</mat-label>
        <x-duration-input-control
          formControlName="leadTime"
          [format]="['d', 'hr', 'min']"
        ></x-duration-input-control>
        <mat-error xFormError="leadTime"></mat-error>
        <mat-hint>Lead time required</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Slot Lag Time</mat-label>
        <x-duration-input-control
          formControlName="lagTime"
          [format]="['d', 'hr', 'min']"
        ></x-duration-input-control>
        <mat-error xFormError="leadTime"></mat-error>
        <mat-hint>Lag time required</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Capacity</mat-label>
        <input matInput type="number" formControlName="capacity" />
        <mat-hint>Total delivery capacity</mat-hint>
      </mat-form-field>
      <mat-form-field class="col-6">
        <mat-label>Priority</mat-label>
        <input matInput type="number" formControlName="priority" min="0" />
      </mat-form-field>
      <mat-form-field class="col-9">
        <mat-label>Schedule Date Range</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="Start date" formControlName="startAt" />
          <input matEndDate placeholder="End date" formControlName="endAt" />
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker></mat-date-range-picker>
        <mat-hint>Schedule date range</mat-hint>
      </mat-form-field>
      <div class="col-3 py-4">
        <mat-slide-toggle formControlName="enabled">Enabled</mat-slide-toggle>
      </div>
      <mat-form-field class="col-12">
        <mat-label>Regions</mat-label>
        <x-data-select
          formControlName="regionIds"
          [provider]="Providers.GeoRegionItemCollectionProvider"
          [multiple]="true"
        ></x-data-select>
        <mat-hint>Limit slots to specified regions</mat-hint>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <x-pill color="warn" text="Form contains errors" *ngIf="formGroup.invalid && formGroup.touched">
    <fa-icon icon="exclamation-circle"></fa-icon>
  </x-pill>
  <button mat-button type="button" (click)="dialog.close()">Cancel</button>
  <button
    mat-button
    type="submit"
    [disabled]="formGroup.invalid"
    color="primary"
    form="shipping_schedule_form"
  >
    {{ verb }}
  </button>
</div>
