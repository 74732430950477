import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { ICollectionPointRowObject } from '@x/ecommerce/domain-client';
import { CollectionPointFilterInput } from '@x/schemas/ecommerce';
import { CollectionPointDialogService } from '../../services/collection-point-dialog.service';

@Component({
  selector: 'x-collection-point-table',
  templateUrl: './collection-point-table.component.html',
  styleUrls: ['./collection-point-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionPointTableComponent {
  @Input()
  view: DataTableView<ICollectionPointRowObject, CollectionPointFilterInput, any, number>;

  @Input()
  timezone = 'UTC';

  @Output()
  valueUpdated = new EventEmitter<boolean>();

  constructor(private collectionPointDialogService: CollectionPointDialogService) {}

  editCollectionPoint(id: number, methodId: number) {
    this.collectionPointDialogService
      .openCollectionPointCreateDialog({
        collectionPointId: id,
        methodId,
      })
      .afterClosed()
      .subscribe((result) => {
        if (!result) return;
        this.view.refresh();
      });
  }
}
