import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { CronModule } from '@x/common/cron';
import { LocaleModule } from '@x/common/locale';
import { ResponsiveModule } from '@x/common/responsive';
import { ShippingSlotQuickViewComponent } from '@x/ecommerce-admin/app/logistics/components/shipping-slot-quick-view/shipping-slot-quick-view.component';
import { NgChartsModule } from 'ng2-charts';
import { EcommerceAdminCoreModule } from '../core/ecommerce-admin-core.module';
import { CollectionPointDialogComponent } from './components/collection-point-dialog/collection-point-dialog.component';
import { CollectionPointTableComponent } from './components/collection-point-table/collection-point-table.component';
import { ParcelOptionCreateDialogComponent } from './components/parcel-option-create-dialog/parcel-option-create-dialog.component';
import { ParcelOptionUpdateDialogComponent } from './components/parcel-option-update-dialog/parcel-option-update-dialog.component';
import { ShipmentFormDialogComponent } from './components/shipment-form-dialog/shipment-form-dialog.component';
import { ShipmentQuickViewComponent } from './components/shipment-quick-view/shipment-quick-view.component';
import { ShipmentTableComponent } from './components/shipment-table/shipment-table.component';
import { ShippingCategoryFormDialogComponent } from './components/shipping-category-form-dialog/shipping-category-form-dialog.component';
import { ShippingMethodDialogComponent } from './components/shipping-method-form-dialog/shipping-method-form-dialog.component';
import { ShippingMethodTableComponent } from './components/shipping-method-table/shipping-method-table.component';
import { ShippingScheduleDialogComponent } from './components/shipping-schedule-dialog/shipping-schedule-dialog.component';
import { ShippingScheduleTableComponent } from './components/shipping-schedule-table/shipping-schedule-table.component';
import { ShippingSlotCalendarComponent } from './components/shipping-slot-calendar/shipping-slot-calendar.component';
import { ShippingSlotDialogComponent } from './components/shipping-slot-dialog/shipping-slot-dialog.component';
import { ShippingSlotTableComponent } from './components/shipping-slot-table/shipping-slot-table.component';
import { PARCEL_OPTION_LANGUAGE } from './languages/parcel-option-index.language';
import { SHIPPING_METHOD_LANGUAGE } from './languages/shipping-method-index.language';
import { LOGISTICS_LANGUAGE } from './logistics.language';
import { CollectionPointDialogService } from './services/collection-point-dialog.service';
import { ParcelOptionDialogService } from './services/parcel-option-dialog.service';
import { ShipmentDialogService } from './services/shipment-dialog.service';
import { ShippingCategoryDialogService } from './services/shipping-category-dialog.service';
import { ShippingMethodDialogService } from './services/shipping-method-dialog.service';
import { ShippingScheduleDialogService } from './services/shipping-schedule-dialog.service';
import { ShippingSlotDialogService } from './services/shipping-slot-dialog.service';

const DIALOG_COMPONENTS = [
  ShippingMethodDialogComponent,
  ParcelOptionUpdateDialogComponent,
  ParcelOptionCreateDialogComponent,
  ShipmentFormDialogComponent,
  ShippingCategoryFormDialogComponent,
  ShippingScheduleDialogComponent,
  ShippingSlotDialogComponent,
  CollectionPointDialogComponent,
];
const SHARED_COMPONENTS = [
  CollectionPointTableComponent,
  ShippingMethodTableComponent,
  ShipmentTableComponent,
  ShippingScheduleTableComponent,
  ShippingSlotTableComponent,
  ShippingSlotCalendarComponent,
  ShippingSlotQuickViewComponent,
  ShipmentQuickViewComponent,
];

@NgModule({
  declarations: [...DIALOG_COMPONENTS, ...SHARED_COMPONENTS],
  imports: [
    EcommerceAdminCoreModule,
    CommonModule,
    CronModule,
    ResponsiveModule,
    FullCalendarModule,
    NgChartsModule,
    LocaleModule.forChild([LOGISTICS_LANGUAGE, SHIPPING_METHOD_LANGUAGE, PARCEL_OPTION_LANGUAGE]),
  ],
  providers: [
    ShippingMethodDialogService,
    ParcelOptionDialogService,
    ShipmentDialogService,
    ShippingCategoryDialogService,
    ShippingScheduleDialogService,
    ShippingSlotDialogService,
    CollectionPointDialogService,
  ],
  exports: [...SHARED_COMPONENTS],
})
export class LogisticsSharedModule {}
