<h2 mat-dialog-title>{{ data.actionText }} Parcel</h2>
<form
  mat-dialog-content
  [formGroup]="parcelForm"
  (ngSubmit)="submit()"
  novalidate
  autocomplete="off"
  id="create_order_form"
>
  <x-dashboard-form-layout>
    <x-form-panel>
      <mat-form-field appearance="outline">
        <mat-label>Parcel Option</mat-label>

        <mat-select [formControl]="parcelOptionControl">
          <mat-option>Custom</mat-option>
          <mat-option *ngFor="let option of parcelOptions$ | async" [value]="option">
            <b>
              {{ option.name }}
            </b>
            <small *ngIf="option.dimensions">
              | L {{ option.dimensions.length }}mm x W {{ option.dimensions.width }}mm x H
              {{ option.dimensions.height }}mm
            </small>
            <small *ngIf="option.weight">| {{ option.weight }}g</small>
          </mat-option>
        </mat-select>
      </mat-form-field>
      <mat-form-field appearance="fill">
        <mat-label>Name</mat-label>
        <input type="text" matInput formControlName="name" />
      </mat-form-field>

      <mat-form-field class="col-4">
        <mat-label>Width</mat-label>
        <input matInput type="number" formControlName="width" />
        <span matSuffix class="m-2">mm</span>
      </mat-form-field>

      <mat-form-field class="col-4">
        <mat-label>Height</mat-label>
        <input matInput type="number" formControlName="height" />
        <span matSuffix class="m-2">mm</span>
      </mat-form-field>

      <mat-form-field class="col-4">
        <mat-label>Length</mat-label>
        <input matInput type="number" formControlName="length" />
        <span matSuffix class="m-2">mm</span>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Weight</mat-label>
        <input matInput type="number" formControlName="weight" />
        <span matSuffix class="m-2">g</span>
      </mat-form-field>
    </x-form-panel>
  </x-dashboard-form-layout>
</form>
<div mat-dialog-actions align="end">
  <button mat-flat-button type="button" color="accent" (click)="closeDialog()">Cancel</button>
  <button
    mat-flat-button
    type="submit"
    color="primary"
    form="create_order_form"
    [disabled]="parcelForm.invalid"
  >
    {{ data.actionText }}
  </button>
</div>
