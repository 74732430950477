import { FormControl } from '@angular/forms';
import { Observable, tap } from 'rxjs';
import { DataCollectionView } from '../views/data-collection-view';

export interface DataSearchControlOptions {
  view?: DataCollectionView;
}

export class DataSearchControl extends FormControl<string | null> {
  private _lastValue: any = null;

  changes$: Observable<any>;

  constructor(private options?: DataSearchControlOptions) {
    super(null);

    this.changes$ = this.valueChanges.pipe(
      tap((value) => {
        this._lastValue = value;
      }),
    );
  }

  resetValueFromCurrentValue(view: DataCollectionView<any, any, any, any>, emitEvent = false) {
    if (view.searchText === this._lastValue) return;

    this.reset(view.searchText, { emitEvent });
  }
}
