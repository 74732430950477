import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ParcelOptionDetailObject, ParcelOptionService } from '@x/ecommerce/domain-client';
import { DimensionsInput } from '@x/schemas/ecommerce';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

export interface IOrderCreateParcelDialogData {
  dimensions?: DimensionsInput | null;
  name?: string | null;
  parcelOptionId?: number | null;
  weight?: number | null;
  actionText?: string;
}

export interface IOrderCreateParcelDialogResult {
  dimensions?: DimensionsInput | null;
  name?: string;
  parcelOptionId?: number;
  weight?: number;
}

@Component({
  selector: 'x-order-create-parcel-dialog',
  templateUrl: './order-create-parcel-dialog.component.html',
  styleUrls: ['./order-create-parcel-dialog.component.scss'],
})
export class OrderCreateParcelDialogComponent implements OnInit {
  private _destroy$ = new Subject<void>();
  parcelOptions$ = this.parcelOptionsService.fetchAll();

  parcelOptionControl = new UntypedFormControl(this.data.parcelOptionId ?? null);

  parcelForm = new UntypedFormGroup({
    name: new UntypedFormControl(this.data.name, [Validators.required]),
    weight: new UntypedFormControl(this.data.weight ?? null, [Validators.required]),
    length: new UntypedFormControl(this.data.dimensions?.length ?? null, [Validators.required]),
    width: new UntypedFormControl(this.data.dimensions?.width ?? null, [Validators.required]),
    height: new UntypedFormControl(this.data.dimensions?.height ?? null, [Validators.required]),
    parcelOptionId: new UntypedFormControl(this.data.parcelOptionId ?? null),
  });

  constructor(
    private parcelOptionsService: ParcelOptionService,
    @Inject(MAT_DIALOG_DATA)
    public data: IOrderCreateParcelDialogData,
    public dialog: MatDialogRef<OrderCreateParcelDialogComponent, IOrderCreateParcelDialogResult>,
  ) {}

  async ngOnInit(): Promise<void> {
    this.parcelOptionControl.valueChanges
      .pipe(takeUntil(this._destroy$))
      .subscribe((option: ParcelOptionDetailObject) => {
        if (option) {
          this.parcelForm.patchValue({
            name: option?.name,
            width: option?.dimensions?.width,
            length: option?.dimensions?.length,
            height: option?.dimensions?.height,
            weight: option?.weight,
            parcelOptionId: option?.id,
          });
        } else {
          this.parcelForm.patchValue({ parcelOptionId: null });
        }

        // if (option) {
        //   this.parcelForm.get('width')?.disable();
        //   this.parcelForm.get('length')?.disable();
        //   this.parcelForm.get('height')?.disable();
        //   this.parcelForm.get('weight')?.disable();
        // } else {
        //   this.parcelForm.get('width')?.enable();
        //   this.parcelForm.get('length')?.enable();
        //   this.parcelForm.get('height')?.enable();
        //   this.parcelForm.get('weight')?.enable();
        // }
      });
  }

  closeDialog() {
    this.dialog.close();
  }

  async submit() {
    const { valid, value } = this.parcelForm;

    if (!valid) return;

    const result: IOrderCreateParcelDialogResult = {
      name: value.name,
      weight: value.weight,
      parcelOptionId: value.parcelOptionId,
    };

    if (!result.parcelOptionId) {
      result.dimensions = {
        width: value.width,
        height: value.height,
        length: value.length,
      };
    }

    this.dialog.close(result);
  }
}
