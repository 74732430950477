<ng-container *ngIf="view.data as shipment">
  <mat-toolbar>
    <x-shipment-pill
      matTooltip="Shipment"
      [shipment]="{ state: shipment.state, method: shipment.method }"
    ></x-shipment-pill>
    <span class="quickview-head-spacer"></span>
    <a [routerLink]="['/admin/orders', shipment.order.id, 'detail']" target="_blank">
      <!-- <x-order-pill matTooltip="" [order]="shipment.order"></x-order-pill> -->
    </a>
  </mat-toolbar>

  <!-- SHIPMENT INFO -->
  <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="boxes-stacked"></fa-icon>
        &nbsp;Shipment Info
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <ng-container matListItemLine>
        <table class="mat-typography info-table">
          <tbody>
            <tr *ngIf="shipment.reference">
              <th>Reference:</th>
              <td>{{ shipment.reference }}</td>
            </tr>
            <tr *ngIf="shipment.description">
              <th>Description:</th>
              <td>{{ shipment.description }}</td>
            </tr>
            <tr *ngIf="shipment.requestedSlotStartAt">
              <th>Requested Slot:</th>
              <td>
                {{ shipment.requestedSlotStartAt | datetime: "EEEE, d MMM, HH'h'mm" }} -
                {{
                  shipment.requestedSlotEndAt
                    ? (shipment.requestedSlotEndAt | datetime: "HH'h'mm")
                    : '-'
                }}
              </td>
            </tr>
            <tr *ngIf="shipment.slot">
              <th>Assigned Slot:</th>
              <td>
                @if (shipment.slot) {
                  {{ shipment.slot.startAt | datetime: "EEEE, d MMM, HH'h'mm" }} -
                  {{ shipment.slot.endAt | datetime: "HH'h'mm" }}
                } @else {
                  -
                }
              </td>
            </tr>

            <tr>
              <th>Driver:</th>
              <td>{{ shipment.driver }}</td>
            </tr>
            <tr *ngIf="shipment.routeSequence">
              <th>Route Sequence:</th>
              <td>{{ shipment.routeSequence }}</td>
            </tr>
            <tr *ngIf="shipment.totalWeight">
              <th>Total Weight:</th>
              <td>{{ shipment.totalWeight }}</td>
            </tr>
            <tr *ngIf="shipment.fixedCost">
              <th>Fixed Cost:</th>
              <td>{{ shipment.fixedCost }}</td>
            </tr>
            <tr *ngIf="shipment.serviceOption">
              <th>Service Option:</th>
              <td>{{ shipment.serviceOption }}</td>
            </tr>
            <tr *ngIf="shipment.trackingUrl">
              <th>Tracking URL:</th>
              <td>
                <a
                  *ngIf="shipment.trackingUrl; else noTrackingUrl"
                  [href]="shipment.trackingUrl"
                  target="_blank"
                >
                  <x-pill text="Track Shipment" color="yellow"></x-pill>
                </a>
                <ng-template #noTrackingUrl><span>-</span></ng-template>
              </td>
            </tr>
          </tbody>
        </table>
        <!-- <br />
        <div class="d-flex">
          <div>
            <span class="mat-body-2">Reference:</span>
            <br />
            <span *ngIf="shipment.reference; else noReference" class="mat-caption">
              {{ shipment.reference }}
            </span>
            <ng-template #noReference><span class="mat-caption">-</span></ng-template>
          </div>
        </div> -->
      </ng-container>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <!-- Products -->
  <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="stroopwafel"></fa-icon>
        &nbsp;Products
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <table class="mat-typography product-summary-table">
        <tbody>
          <tr *ngFor="let item of shipment.order.items">
            <td style="text-align: left">
              <!-- {{ item.variant.stockItem?.sku }} -->
            </td>
            <td style="text-align: left">
              {{ item.productName }}
            </td>
            <td>{{ item.quantity }}</td>
          </tr>
        </tbody>
      </table>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <!-- PARCEL DETAILS -->
  <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="box-open"></fa-icon>
        &nbsp;Parcel Details
      </mat-panel-title>
    </mat-expansion-panel-header>

    <!-- <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let parcel">
        <button mat-icon-button (click)="editParcel(parcel)">
          <fa-icon icon="pen"></fa-icon>
        </button>
        <button mat-icon-button (click)="removeParcel(parcel.id)">
          <fa-icon icon="trash"></fa-icon>
        </button>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container> -->
    <ng-template matExpansionPanelContent>
      @if (shipment.parcels && shipment.parcels.length !== 0) {
        @for (parcel of shipment.parcels; track $index) {
          <xd-list-item>
            <span xdHeadline>{{ parcel.name }}</span>
            <span xdLine *ngIf="parcel.reference">{{ parcel.reference }}</span>
            <span xdLine>
              {{
                (parcel.dimensions?.length ?? '-') +
                  ' x ' +
                  (parcel.dimensions?.height ?? '-') +
                  ' x ' +
                  (parcel.dimensions?.width ?? '-')
              }}
            </span>
            <span xdLine>{{ parcel.weight }}g</span>
            <button mat-icon-button (click)="editParcel(parcel)" matTooltip="Edit">
              <fa-icon icon="pen"></fa-icon>
            </button>
            <button mat-icon-button (click)="removeParcel(parcel)" matTooltip="Remove" color="warn">
              <fa-icon icon="trash"></fa-icon>
            </button>
          </xd-list-item>
        }
      } @else {
        <div class="mat-label">No parcels found</div>
      }
      <x-action-row>
        <button
          mat-flat-button
          color="primary"
          (click)="openParcelDialog(shipment.id)"
          [disabled]="shipment.state === 'DELIVERED' || shipment.state === 'CANCELLED'"
        >
          Add Parcel
        </button>
      </x-action-row>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <!-- More Info -->
  <mat-expansion-panel expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <fa-icon size="lg" icon="clock"></fa-icon>
        &nbsp;More Info
      </mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <ng-container matListItemLine>
        <table class="mat-typography info-table">
          <tbody>
            <tr *ngIf="shipment.createdAt">
              <th>Created At:</th>
              <td>{{ shipment.createdAt | datetime: 'short' }}</td>
            </tr>
            <tr *ngIf="shipment.updatedAt">
              <th>Updated At:</th>
              <td>{{ shipment.updatedAt | datetime: 'short' }}</td>
            </tr>
            <tr *ngIf="shipment.labeledAt">
              <th>Labeled At:</th>
              <td>{{ shipment.labeledAt | datetime: 'short' }}</td>
            </tr>
            <tr *ngIf="shipment.reservedAt">
              <th>Reserved At:</th>
              <td>{{ shipment.reservedAt | datetime: 'short' }}</td>
            </tr>
            <tr *ngIf="shipment.readyAt">
              <th>Ready At:</th>
              <td>{{ shipment.readyAt | datetime: 'short' }}</td>
            </tr>
            <tr *ngIf="shipment.shippedAt">
              <th>Shipped At:</th>
              <td>{{ shipment.shippedAt | datetime: 'short' }}</td>
            </tr>
            <tr *ngIf="shipment.completedAt">
              <th>Completed At:</th>
              <td>{{ shipment.completedAt | datetime: 'short' }}</td>
            </tr>
          </tbody>
        </table>
      </ng-container>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel
    *ngIf="shipment.order.shippingAddress?.instructions"
    expanded="true"
    class="mat-elevation-z0"
  >
    <mat-expansion-panel-header>
      <mat-panel-title>&nbsp;Address</mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div>
        <b>Address Instructions:</b>
        {{ shipment.order.shippingAddress?.instructions }}
      </div>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel *ngIf="shipment.order.instructions" expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>&nbsp;Packing Instructions</mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      {{ shipment.order.instructions }}
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>

  <mat-expansion-panel *ngIf="shipment.order.isGift" expanded="true" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>&nbsp;Gift Options</mat-panel-title>
    </mat-expansion-panel-header>

    <ng-template matExpansionPanelContent>
      <div>
        <b>Gift Message:</b>
        {{ shipment.order.giftMessage }}
      </div>
    </ng-template>
  </mat-expansion-panel>
  <mat-divider></mat-divider>
</ng-container>
