<x-data-table [view]="view" [selectable]="false">
  <ng-template xDataColumnDef="id" let-row>
    <div class="mat-body-2">{{ row.id }}</div>
  </ng-template>

  <ng-template xDataColumnDef="name" let-row>
    <div class="mat-body-2">
      {{ row.name }}
    </div>
  </ng-template>

  <ng-template xDataColumnDef="address" let-row>
    <x-address-pill [address]="row.address"></x-address-pill>
  </ng-template>

  <ng-template xDataColumnDef="enabled" let-row>
    <x-pill
      [text]="row.enabled ? 'Enabled' : 'Disabled'"
      [color]="row.enabled ? 'primary' : 'accent'"
    ></x-pill>
  </ng-template>

  <ng-template xDataColumnDef="updatedAt" let-row>
    {{ row.updatedAt | datetime }}
  </ng-template>
  <ng-template xDataColumnDef="createdAt" let-row>
    {{ row.createdAt | datetime }}
  </ng-template>

  <button
    x-data-button
    *xDataAction="'edit'; let row; primary: true"
    label="Edit"
    icon="edit"
    (click)="editCollectionPoint(row.id, row.method.id)"
  ></button>
</x-data-table>
