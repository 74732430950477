import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DataTableView } from '@x/common/data';
import { ShippingMethodRowObject } from '@x/ecommerce/domain-client';
import { ShippingMethodFilterInput } from '@x/schemas/ecommerce';
import { ShippingMethodDialogService } from '../../services/shipping-method-dialog.service';

@Component({
  selector: 'x-shipping-method-table',
  templateUrl: './shipping-method-table.component.html',
  styleUrls: ['./shipping-method-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShippingMethodTableComponent {
  @Input()
  view: DataTableView<ShippingMethodRowObject, ShippingMethodFilterInput, any, number>;

  rowDisabledPredicate = (row: ShippingMethodRowObject) => !row.enabled;

  constructor(private shippingMethodDialogService: ShippingMethodDialogService) {}

  async openShippingMethodUpdateDialog(shippingMethodId: number) {
    this.shippingMethodDialogService
      .openShippingMethodDialog(shippingMethodId)
      .afterClosed()
      .subscribe((response) => {
        if (response) {
          this.view.refresh();
        }
      });
  }
}
